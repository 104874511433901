import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const FormInputRangeStyled = styled.div`

    display: flex;
    flex-direction: column;

    margin-bottom: 0.5rem;

    .question-tag {
        font-weight: ${fontWeights.fontWeightBold};
        margin-bottom: 0.5rem;
        margin-left: 1rem;
    }

    .align-inputs { 
        display: flex;
        align-items: center;
    }
`