import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const ChooseCandidateQuestionsComponentStyled = styled.div`

    display: flex;
    flex-direction: column;

    margin-left: 1rem;

    .question-tag {
        font-weight: ${fontWeights.fontWeightBold}
    }

    .page-counter-questions {
        width: 100%;
        font-weight: ${fontWeights.fontWeightBold};
        text-align: left;
        font-size: ${fontSizes.fontSize3};
        line-height: 1.2rem;
        color: ${fontColors.colorMain};
        margin-bottom: 1rem;
    }
    
    .questions-wrapper {
        width: 100%;
        margin-bottom: 1.5rem;
    
        .question {
            width: 100%;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 2px solid #f8f7fc;
    
            .question-icon {
                width: 3rem;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1rem;
                background: #f8f7fc;
                border-radius: 15px;
                font-weight: ${fontWeights.fontWeightBold};
                font-size: ${fontSizes.fontSize4};
            }
        
            .question-text {
                flex: 1;
                line-height: 1.1rem;
            }
        
            .remove-button {
                color: #fc8b78; 
                display: flex; 
                align-items: center; 
                justify-content: center; 
                margin-left: 1rem;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                    background-color: #f7b8ad
                }
            }
        }
    }
    
    .add-question-button {
        color: ${fontColors.colorPrimary};
        font-size: ${fontSizes.fontSize2};
        display: flex;
        align-items: center;
    
        span {
            margin-left: 0.25rem;
        }

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    
`