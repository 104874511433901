import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const UserDetailsPanelStyled = styled.div`

    .profile-summary-container {
        margin: 0;
        padding: 1rem;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: #fff;
        position: relative;

        .inner-content {
            width: 100%;
            margin: 0;
            padding: 0;
            overflow-y: auto;
        
            &.animate-inner-content-rtl {
                animation-name: inner-content-rtl-animation;
                animation-duration: 200ms;
                animation-timing-function: ease-in;
                visibility: visible;
            }
        
            &.animate-inner-content-ltr {
                animation-name: inner-content-ltr-animation;
                animation-duration: 200ms;
                animation-timing-function: ease-in;
                visibility: visible;
            }
        
            &.visible-content {
                visibility: visible;
            }
        
            @keyframes inner-content-rtl-animation {
                0% {
                    opacity: 0;
                    transform: translateX(100%);
                }
                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        
            @keyframes inner-content-ltr-animation {
                0% {
                    opacity: 0;
                    transform: translateX(-100%);
                }
                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        
            .page-title {
                width: 100%;
                margin-top: 1.5rem;
                font-weight: ${fontWeights.fontWeightBold};
                text-align: left;
                font-size: ${fontSizes.fontSize5};
                line-height: 1.2rem;
                color: ${fontColors.colorMain};
            }
        
            .page-description {
                width: 100%;
                font-size: ${fontSizes.fontSize3};
                color: #808080;
                line-height: 1.2rem;
                text-align: left;
                margin-bottom: 1.5rem;
            }
            
            .page-subtitle{
                width: 100%;
                margin-top: 3rem;
                margin-bottom: 2rem;
                font-weight: ${fontWeights.fontWeightBold};
                text-align: left;
                font-size: ${fontSizes.fontSize4};
                line-height: 1.2%;
                color: ${fontColors.colorMain};
            }
        }
    }

    .page-title {
        font-weight: ${fontWeights.fontWeightBold};
        color: ${fontColors.colorMain};
        display: flex;
        justify-content: center;
    }

    .video-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
    
        .video-thumbnail {
            border-radius: 10px;
            width: 10rem;
            height: 10rem;
            position: relative;
            border: 2px solid ${fontColors.colorPrimary};
        
    
            .video-thumbnail-inner {
                overflow: hidden;
                width: 100%;
                height: 100%;
                border-radius: 10px;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
        }
    }

    .check-value-id {
        font-weight: ${fontWeights.fontWeightBold};
        margin-left: 1rem;
        width: 100%;
        font-size: ${fontSizes.fontSize2};
    }
    
`;
