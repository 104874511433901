import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";
import { deleteAccount, getCounties, getCountries, getRecruiters, getReviewers, suspendAccount } from "../../../../utils/requests";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { LinearProgress } from "@mui/material";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import ReviewersPageStyled from "./reviewers.page.styled";
import { PanelType } from "../../../../enums/panel-type.enum";
import UserDetailsPanelComponent from "../../../../components/user-details-panel/user-details-panel.component";
import { UserTypeEnum } from "../../../../enums/user-type.enum";
import { useStore } from "../../../../hooks/store.hook";
import { formatDate } from "../../../../utils/utils";
import { Delete, EditOutlined } from "@mui/icons-material";
import EditUserInfoDetailsPanelComponent from "../../../../components/edit-user-info-details/edit-user-info-details-panel.component";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import toast from "react-hot-toast";
import LockResetIcon from '@mui/icons-material/LockReset';
import ResetPasswordPanel from "../../../../components/reset-password/reset-password-panel.component";
import { useDownloadExcel } from 'react-export-table-to-excel';
import BlockIcon from '@mui/icons-material/Block';
import { UserInfoType } from "../recruiters/recruiters.page";
import BeenhereIcon from '@mui/icons-material/Beenhere';

export type UsersType = {
    numberOfPages: number;
    numberOfUsers: number;
    users: UserInfoType[]
}

const ReviewersPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [users, setUsers] = useState<UsersType>();
    const [usersLoading, setUsersLoading] = useState<boolean>(true);
    const [initialCountry, setInitialCountry] = useState<any>();
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const [countries, setCountries] = useState<any>();
    const [counties, setCounties] = useState<any>();
    const uiStore = useStore('uiStore');
    const globalStore = useStore("globalStore");
    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Reviewers table',
        sheet: 'Reviewers'
    });
    const [actionItemsClick, setActionItemsClick] = useState<UserInfoType>();

    useEffect(
        () => {
            async function getData() {
                setUsersLoading(() => true);
                try {
                    
                    let countries = await getCountries();
                    setCountries(() => countries);

                    // for raports
                    let initialCountry = countries.find(f => f.tag === "api.general.country3")
                    setInitialCountry(() => initialCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    let counties = await getCounties(initialCountry?.id);
                    setCounties(() => counties);

                    setUsersLoading(() => false);
                } catch (error) {
                    setUsersLoading(() => false)
                }
            }

            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
        },
        [refreshData]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setUsersLoading(() => true)
                    let fetchJobs = await getReviewers({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        country:filtersTable.selectFilterValue?.value,
                        county:filtersTable.secondSelectFilterValue?.value,
                        search:filtersTable.searchText,
                        tabFilter: filtersTable.tabFilterValue
                    });
                    setUsers(() => fetchJobs)
                    setUsersLoading(() => false)
                } catch (error) {
                    setUsersLoading(() => false)
                }
            }

            if(!filtersTable) return;
            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) return;
            if(!filtersTable?.selectFilterValue?.value){
                setUsersLoading(() => false);
                return;
            }
            
            getData();
            
        },
        [filtersTable, initialCountry]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setUsersLoading(() => true)
                    let newCountry = countries.find((f: { tag: string; }) => f.tag === filtersTable.selectFilterValue?.value)
                    setInitialCountry(() => newCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCounties(() => counties);
                    setUsersLoading(() => false)
                } catch (error) {
                    setUsersLoading(() => false)
                }
            }

            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) 
                getData()
        },
        [filtersTable, initialCountry]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!users) return {
                data: [],
                headers: []
            };

            return {
                data: users.users.sort((a, b) => (new Date(b.memberSince).getTime()) - new Date(a.memberSince).getTime()),
                headers: [
                   {
                       id: 'userInfoId',
                       label: 'Reviewer Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'userName',
                        label: 'Reviewer Name',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'memberSince',
                        label: 'Member since',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: true
                    },
                ],
                totalElements: users.numberOfUsers
            }
        },
        [users]
    )

    const customTableCellAction = {
        userInfoId: (row: UserInfoType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.userInfoId}
                    userType={UserTypeEnum.Reviewer}
                />,
                panelWidth: '700px',
                title: `${row.userName} Details`,
                onDismiss: () => {
                }
            });
        },
        userName: (row: UserInfoType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.userInfoId}
                    userType={UserTypeEnum.Reviewer}
                />,
                panelWidth: '700px',
                title: `${row.userName} Details`,
                onDismiss: () => {
                }
            });
        }
    }

    const customRenderer: CustomRendererPropsType = {
        memberSince: (row: UserInfoType) => {
            return (
                <>
                    {formatDate(row.memberSince)}
                </>
            )
        }
    }

    const actionItems: any[] = useMemo(
        () => {
            return [
                !actionItemsClick?.isReviewerVerified ?
                {
                    text: "Verify this account",
                    icon: <BeenhereIcon />,
                    color: "purple",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.VerifyReviewer,
                            component: <UserDetailsPanelComponent 
                                userInfoId={row.userInfoId}
                                userType={UserTypeEnum.Reviewer}
                                verifyReviewer
                            />,
                            panelWidth: '700px',
                            title: `Verify reviewer: ${row.userName}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                } : undefined,
                {
                    text: "Edit profile",
                    icon: <EditOutlined/>,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditUserDetails,
                            component: <EditUserInfoDetailsPanelComponent 
                                userInfoId={row.userInfoId}
                                userType={UserTypeEnum.Reviewer}
                            />,
                            panelWidth: '700px',
                            title: `Edit ${row.userName} Details`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Reset Password",
                    icon: <LockResetIcon/>,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.ResetUserPassword,
                            component: <ResetPasswordPanel 
                                userInfoId={row.userInfoId} 
                                email={row.email} 
                            />,
                            panelWidth: '700px',
                            title: `Reset password - ${row.userName}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: `${actionItemsClick?.isSuspended ? "Make account active" : "Suspend account"}`,
                    icon: <BlockIcon />,
                    color: `${actionItemsClick?.isSuspended ? "green" : "orange"}`,
                    async fOnClick (row: any) {
                        const response = await uiStore.openDialog({
                            title: `${globalStore.translationsText[`${row?.isSuspended ? "general.modal.makeActiveAccount.label.title" : "general.modal.suspendAccount.label.title"}`]} - ${row.userName}`,
                            message: `${globalStore.translationsText[`${row?.isSuspended ? "general.modal.makeActiveAccount.label.description" : "general.modal.suspendAccount.label.description"}`]}`,
                        })    
                        if(response.value === DialogResponseTypeEnum.Confirm) {
                            
                            try {
                                let body = {
                                    accountId: row.userInfoId
                                }
        
                                if(row.userInfoId)
                                    await suspendAccount(body)
                                toast.success(`${globalStore.translationsText[`${row?.isSuspended ? "general.modal.makeActiveAccount.label.success" : "general.modal.suspendAccount.label.success"}`]}`);
                                setRefreshData(() => true);
                            }
                            catch (error: any) {
                                toast.error(globalStore.translationsText[error.message])
                            }
                        }
                    }
                },
                {
                    text: "Delete account",
                    icon: <Delete />,
                    color: "red",
                    async fOnClick (row: any) {
                        const response = await uiStore.openDialog({
                            title: `${globalStore.translationsText["deleteAccount.label.title"]} - ${row.userName}`,
                            message: `${globalStore.translationsText["deleteAccount.label.description"]}`,
                        })    
                        if(response.value === DialogResponseTypeEnum.Confirm) {
                            
                            try {
                                if(row.userInfoId)
                                    await deleteAccount(row.userInfoId)
                                toast.success(`${globalStore.translationsText["deleteAccount.label.success"]}`);
                                setRefreshData(() => true);
                            }
                            catch (error: any) {
                                toast.error(globalStore.translationsText[error.message])
                            }
                        }
                    }
                },
            ].filter(f => f);
        },
        [actionItemsClick]
    )

    var tabsOptions = useMemo(
        () => {
            return [
                {
                    label:"All",
                    value: 1,
                },
                {
                    label:"Active",
                    value: 2,
                },
                {
                    label:"Suspended",
                    value: 3,
                },
                {
                    label: "Under Verification",
                    value: 4
                }
            ]
        },
        []
    )

    // useEffect(
    //     () => {
    //         if(!actionItemsClick?.isReviewerVerified){
    //             actionItems.unshift()
    //         }
    //     },
    //     [actionItemsClick, actionItems]
    // )

    return (
        <ReviewersPageStyled>
            <PageTemplateComponent 
                title="Reviewers"
                buttons={[
                    {
                        fOnClick: () => {
                            onDownload()
                        },
                        text: "Export"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.Reviewers} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Search by name"
                    dropdownPlaceholder="Country"
                    secondDropdownPlaceholder="County" 
                    withoutSelect
                    dropdownOptions={countries?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    secondDropdownOptions={counties?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    isLoading={usersLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    customTableCellAction={customTableCellAction}
                    customRenderer={customRenderer}
                    actionItems={actionItems}
                    tableRef={tableRef}
                    actionItemsClick={setActionItemsClick}
                    tabs={tabsOptions}
                />  
            </PageTemplateComponent>
        </ReviewersPageStyled>
    )
})

export default ReviewersPage;