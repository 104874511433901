import { observer } from "mobx-react-lite";
import { EditReviewerPaymentsPanelStyled } from "./edit-reviewer-payments-panel.component.styled";
import { ReviewerPaymentDto } from "../../modules/main/pages/reviewer-payment/reviewer-payment.page";
import { useStore } from "../../hooks/store.hook";
import { useState } from "react";
import { isEmpty } from "lodash";
import FormTextInputComponent from "../form-text-input/form-text-input.component";
import ButtonComponent from "../button/button.component";
import toast from "react-hot-toast";
import { updateReviewerPyment } from "../../utils/requests";

type EditReviewerPaymentsPanelPropsType = {
    row: ReviewerPaymentDto
}

const EditReviewerPaymentsPanel = observer(({
    row
}: EditReviewerPaymentsPanelPropsType) => {

    const globalStore = useStore("globalStore");
    const uiStore = useStore('uiStore');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<any>({
        fields: {
            value: {
                value: row.value,
                isValid: true,
                noValidation: false
            }
        },
        shouldDisplayError: false
    })

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const editReviewerPaymentAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            toast.error("The details are invalid!")
            return;
        };

        
        let body = {
            Id: row.id,
            value: parseFloat(formGroup.fields.value?.value)
        }

        try {
            await updateReviewerPyment(body);
            setIsSaving(() => false);
            toast.success("The details were saved!")
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message])
        }
    }

    return (
        <EditReviewerPaymentsPanelStyled>
            <FormTextInputComponent
                questionTag="general.edit-reviewer-payment.value"    
                onChangeValue={(value: any) => updateState('value', value)}
                value={formGroup.fields.value?.value} 
                isRequired={formGroup.fields.value?.noValidation === false}     
                questionPlaceholder="general.edit-reviewer-payment.value"     
                error={formGroup?.shouldDisplayError && !formGroup.fields.value?.isValid} 
                type="number"
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <ButtonComponent 
                    onClick={editReviewerPaymentAction} 
                    isLoading={isSaving}
                    sx={{
                        width: "5rem"
                    }}
                >
                    Save
                </ButtonComponent>
            </div>
        </EditReviewerPaymentsPanelStyled>
    )
})

export default EditReviewerPaymentsPanel;