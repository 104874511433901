import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import envVars from '../../../../../../config/env.config';
import toast from 'react-hot-toast';
import { observer } from 'mobx-react-lite';
import { UpgradeOffersDto, upgradeSubscription } from '../../../../../../utils/requests';
import { useStore } from '../../../../../../hooks/store.hook';
import { PlanTypeCurrencyNumber } from '../../../../../../enums/plan-type-currency.enum';
import { TransactionPlatform } from '../../../../../../enums/transaction-platform.enum';
import ButtonComponent from '../../../../../../components/button/button.component';

type CheckoutFormTypeProps = {
  setIsLoading: (value: any) => void; 
  selectedOffer: UpgradeOffersDto;
  resetPaymentSecret: (value: any) => void;
}

const CheckoutForm = observer(({
  setIsLoading,
  selectedOffer,
  resetPaymentSecret
}: CheckoutFormTypeProps) => {

  // const stripe = useStripe();
  // const elements = useElements();

  const globalStore = useStore("globalStore");


  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    try {

      {/* comment when stripe should be active */}

      setIsLoading(true);

      let resp = await upgradeSubscription({
        planType: selectedOffer.planTypeId,
        transactionInformation: null
      });
      if(resp) {
        let toastSuccess = globalStore.translationsText["recruiter.general.job-upgrade.success-upgrade"];
        toast.success(toastSuccess);
        
      } else {
        let toastError = globalStore.translationsText["recruiter.general.job-upgrade.error-upgrade"];
        toast.error(toastError);
      }

      setIsLoading(false);
      resetPaymentSecret(undefined);
    } catch (error: any) {
      toast.error(error);
    }

    {/* uncomment when stripe should be active */}

    // if (!stripe || !elements) {
    //   return;
    // }

    // const result = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     return_url: `${window.location.origin}/app/upgrade-account`
    //   },
    //   redirect: "if_required"
    // });

    // if (result.error) {
    //   console.log(result.error.message);
    // } else if(result.paymentIntent) {

    //   setIsLoading(true);
    //   let resp = await upgradeSubscription({
    //     planType: selectedOffer.planTypeId,
    //     transactionInformation: {
    //       transactionId: result.paymentIntent.id,
    //       platform: TransactionPlatform.Web,
    //       price: result.paymentIntent.amount,
    //       currency:  PlanTypeCurrencyNumber[result.paymentIntent.currency.toUpperCase()] 
    //     }
    //   });
    //   if(resp) {
    //     let toastSuccess = globalStore.translationsText["recruiter.general.job-upgrade.success-upgrade"];
    //     toast.success(toastSuccess);

    //   } else {
    //     let toastError = globalStore.translationsText["recruiter.general.job-upgrade.error-upgrade"];
    //     toast.error(toastError);
    //   }
    //   setIsLoading(false);
    //   resetPaymentSecret(undefined);
    // }
  };

  return (
    <form>
      {/* <PaymentElement /> */}
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "flex-end"
        }}
      >
        <ButtonComponent 
            onClick={handleSubmit}
        > 
            Submit
        </ButtonComponent>
      </div>
    </form>
  )
})

export default CheckoutForm;