import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetJobType, GetJobsType } from "../dashboard/dashboard.page";
import { getCounties, getCountries, getRequestedReviewsJobs } from "../../../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { LinearProgress } from "@mui/material";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import JobsPageStyled from "../jobs/jobs.page.styled";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import JobDetailsPanel from "../../../../components/job-details-panel/job-details-panel.component";
import { useStore } from "../../../../hooks/store.hook";
import UserDetailsPanelComponent from "../../../../components/user-details-panel/user-details-panel.component";
import { UserTypeEnum } from "../../../../enums/user-type.enum";
import { useDownloadExcel } from 'react-export-table-to-excel';

const JobsRequestedReviews = observer(() => {

    // Jobs
    const [jobs, setJobs] = useState<GetJobType>();
    const [jobsLoading, setJobsLoading] = useState<boolean>(true);
    const [initialCountry, setInitialCountry] = useState<any>();
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const [countries, setCountries] = useState<any>();
    const [counties, setCounties] = useState<any>();
    const uiStore = useStore('uiStore');
    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Requested Reviews Jobs table',
        sheet: 'Requested Reviews Jobs'
    })

    useEffect(
        () => {
            async function getData() {
                setJobsLoading(() => true);
                try {
                    
                    let countries = await getCountries();
                    setCountries(() => countries);

                    // for raports
                    let initialCountry = countries.find(f => f.tag === "api.general.country3")
                    setInitialCountry(() => initialCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    let counties = await getCounties(initialCountry?.id);
                    setCounties(() => counties);

                    setJobsLoading(() => false);
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            getData();
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsLoading(() => true)
                    let fetchJobs = await getRequestedReviewsJobs({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        country:filtersTable.selectFilterValue?.value,
                        county:filtersTable.secondSelectFilterValue?.value,
                        search:filtersTable.searchText
                    });
                    setJobs(() => fetchJobs)
                    setJobsLoading(() => false)
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            if(!filtersTable) return;
            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) return;
            if(!filtersTable?.selectFilterValue?.value){
                setJobsLoading(() => false);
                return;
            }
            
            getData();
            
        },
        [filtersTable, initialCountry]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsLoading(() => true)
                    let newCountry = countries.find((f: { tag: string; }) => f.tag === filtersTable.selectFilterValue?.value)
                    setInitialCountry(() => newCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCounties(() => counties);
                    setJobsLoading(() => false)
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) 
                getData()
        },
        [filtersTable, initialCountry]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!jobs) return {
                data: [],
                headers: []
            }

            let jobsFiltered = jobs.jobs.map(m => {

                let candidates = m.jobCandidates.map(n => {
                    return {
                        jobCandidateId: m.jobCandidateId,
                        jobId: m.jobId,
                        jobTitle: m.jobTitle,
                        recruiter: m.recruiter,
                        candidate: {
                            name: n.candidateName,                            
                            id: n.candidateId
                        },
                        status: n.status,
                        reviewerOne: {
                            exists: m.jobReviewers[0] != undefined ? true : false,
                            hasReviewed: m.jobReviewers[0] != undefined ? !!n.reviewedBy.find(f => f.jobReviewerId ===  m.jobReviewers[0]?.jobReviewerId) : undefined
                        },
                        reviewerTwo: {
                            exists: m.jobReviewers[1] != undefined ? true : false,
                            hasReviewed: m.jobReviewers[1] != undefined ? !!n.reviewedBy.find(f => f.jobReviewerId ===  m.jobReviewers[1]?.jobReviewerId) : undefined
                        }
                    }
                })

                return candidates;
            })

            let jobCandidates = (jobsFiltered as any[][]).reduce(
                (prev: any, current) => [...prev, ...current],
                []
            )

            return {
                data: jobCandidates.sort((a, b) => b.jobCandidateId - a.jobCandidateId),
                headers: [
                   {
                       id: 'jobId',
                       label: 'Job Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'jobTitle',
                        label: 'Job Title',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'candidates',
                        label: 'Candidate',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'reviewerOne',
                        label: 'Reviewer 1',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'reviewerTwo',
                        label: 'Reviewer 2',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: jobs.numberOfJobs
            }
        },
        [jobs]
    )

    const customRenderer: CustomRendererPropsType = {
        recruiter: (row: any) => {
            // console.log(row)
            return (
                <>
                    <div>{row.recruiter.recruiterName}</div>
                </>
            )
        },
        candidates: (row: any) => {
            return (
                <>
                    <div>{row.candidate.name}</div>
                </>
            )
        },
        reviewerOne: (row: any) => {
            return (
                <>
                    {
                        row.reviewerOne.exists ?
                            row.reviewerOne.hasReviewed ?
                             "Review is done"
                             :
                             "Review in pending"
                            :
                            "No reviewer assigned"
                    }
                </>
            )
        },
        reviewerTwo: (row: any) => {
            return (
                <>
                {
                        row.reviewerTwo.exists ?
                            row.reviewerTwo.hasReviewed ?
                             "Review is done"
                             :
                             "Review in pending"
                            :
                            "No reviewer assigned"
                    }
                </>
            )
        }
    }

    //open job details panel
    const openJobDetails = useCallback(
        (jobId: number, jobTitle: string) => {
            uiStore.openPanel({
                key: PanelType.JobDetails,
                component: <JobDetailsPanel 
                    jobId={jobId}
                />,
                panelWidth: '700px',
                title: `Job Details - ${jobTitle}`,
                onDismiss: () => {
                }
            });
        },
        []
    )

    const customTableCellAction = {
        jobId: (row: GetJobsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        jobTitle: (row: GetJobsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        recruiter: (row: GetJobsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.recruiter.recruiterId}
                    userType={UserTypeEnum.Recruiter}
                />,
                panelWidth: '700px',
                title: `${row.recruiter.recruiterName} Details`,
                onDismiss: () => {
                }
            });
        },
        candidates: (row: any) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.candidate.id}
                    userType={UserTypeEnum.Candidate}
                />,
                panelWidth: '700px',
                title: `${row.candidate.name} Details`,
                onDismiss: () => {
                }
            });
        },
    }

    return (
        <JobsPageStyled>
            <PageTemplateComponent 
                title="Requested Reviews Jobs"
                buttons={[
                    {
                        fOnClick: () => {
                            onDownload()
                        },
                        text: "Export"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.RequestedReview} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Search by job title"
                    dropdownPlaceholder="Country"
                    secondDropdownPlaceholder="County" 
                    withoutSelect
                    dropdownOptions={countries?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    secondDropdownOptions={counties?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    customRenderer={customRenderer}
                    isLoading={jobsLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    customTableCellAction={customTableCellAction}
                    tableRef={tableRef}
                />  
            </PageTemplateComponent>
        </JobsPageStyled>
    )
})

export default JobsRequestedReviews;