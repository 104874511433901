import { observer } from "mobx-react-lite";
import { AnswerDto } from "../../types/question";
import { RadioSelectComponentStyled } from "./radio-select.component.styled";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import { useStore } from "../../hooks/store.hook";

export type RadioSelectComponentPropsType = {
    answers: any[],
    questionTag: string,
    selectedValue: AnswerDto,
    onChangeSelectedValue: (value: any) => void,
    isRequired?: boolean
}

const RadioSelectComponent = observer(({
    answers,
    questionTag,
    onChangeSelectedValue,
    selectedValue,
    isRequired
}: RadioSelectComponentPropsType) => {

    const globalStore = useStore("globalStore");
    
    return (
        <RadioSelectComponentStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]} {isRequired ? "*" : ""}
            </div>
            {
                answers.map(answer => (
                    <FormControlLabel
                        control={<Radio
                            checked={answer.tag === selectedValue?.tag}
                            onChange={e => onChangeSelectedValue(answer)}
                        />}
                        label={globalStore.translationsText[answer.tag]}
                    />
                ))
            }
        </RadioSelectComponentStyled>
    )
})

export default RadioSelectComponent;