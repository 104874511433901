import { observer } from "mobx-react-lite";
import { CreateJobPanelStyled } from "./create-job-panel.component.styled";
import { useEffect, useMemo, useState } from "react";
import { DictionaryDto, createJob, getCounties, getCountries, getGenders, getHobbies, getLanguages, getPets, getStepQuestions } from "../../utils/requests";
import toast from "react-hot-toast";
import { AnswerDto, StepDto } from "../../types/question";
import { QuestionTypeEnum } from "../../enums/question-type-enum";
import { StateFieldType } from "../../types/form.types";
import { isEmpty } from "lodash";
import FormSectionComponent from "../form-section/form-section.component";
import { useStore } from "../../hooks/store.hook";
import RadioSelectComponent from "../radio-select/radio-select.component";
import { LinearProgress } from "@mui/material";
import FormTextInputComponent from "../form-text-input/form-text-input.component";
import FromDatePickerComponent from "../form-date-picker/form-date-picker.component";
import FromMultiSelectComponent from "../form-multi-select/form-multi-select.component";
import CheckboxMultiSelectComponent from "../checkbox-multiselect/checkbox-multiselect.component";
import FormSingleSelectComponent from "../form-single-select/form-single-select.component";
import ChooseCandidateQuestionsComponent from "../choose-candidate-questions/choose-candidate-questions.component";
import FormInputRangeComponent from "../form-input-range/form-input-range.component";
import DropzoneUploadComponent from "../dropzone-upload/dropzone-upload.component";
import ButtonComponent from "../button/button.component";
import { CreateJobDto } from "../../types/job";
import { constructJobAnswers } from "../../utils/questions.provider";
import moment from "moment";

type CreatePanelComponentPropsType = {
    recruiterInfo: {
        id: number,
        name: string
    },
    appLanguageId: number,
}

const CreatePanelComponent = observer(({
    recruiterInfo,
    appLanguageId
}: CreatePanelComponentPropsType) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [countries, setCountries] = useState<any>();
    const [counties, setCounties] = useState<any>();
    const [languages, setLanguages] = useState<any>();
    const [genders, setGenders] = useState<any>();
    const [pets, setPets] = useState<any>();
    const [hobbies, setHobbies] = useState<any>();
    const [stepQuestions, setStepQuestions] = useState<StepDto[]>([]);
    const careForOptions: DictionaryDto[] = useMemo(
        () => {
            return [
                { id: 1, name: "somebody else", tag: "recruiter.app.question.needcare.option1" }, 
                { id: 2, name: "myself", tag: "recruiter.app.question.needcare.option2" }
            ]
        },
        []
    );
    const globalStore = useStore("globalStore");
    const [loadingCounties, setLoadingCounties] = useState<boolean>(false);
    const [flattenedFormValues, setFlattenedFormValues] = useState<any>();
    const uiStore = useStore('uiStore');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formGroup, setFormGroup] = useState<any>({
        fields: {
            step1: {
                careFor: {
                    value: null,
                    isValid: false,
                    noValidation: false
                },
                noValidation: false
            },
            step2: {
                helpWith: {
                    value: null,
                    isValid: false
                }
            },
            step3: {
                firstName: {
                    value: '',
                    isValid: false,
                    noValidation: false
                },
                lastName: {
                    value: '',
                    isValid: false,
                    noValidation: false
                },
                gender: {
                    value: null,
                    isValid: false,
                    noValidation: false
                },
                birthDate: {
                    value: null,
                    isValid: false,
                    noValidation: false
                },
                motherLanguage: {
                    value: null,
                    isValid: false,
                    noValidation: false
                },
                foreignLanguages: {
                    value: [],
                    isValid: false
                },
                noValidation: false
            },
            step4: {
                beneficiaryPicture: {
                    value: [],
                    isValid: false
                }
            },
            step5: {
                personalityDescription: {
                    value: '',
                    isValid: false
                },
                pets: {
                    value: [],
                    isValid: false
                },
                hobbies: {
                    value: [],
                    isValid: false
                }
            },
            step12: {
                country: {
                    value: null,
                    isValid: false,
                    noValidation: false
                },
                county: {
                    value: null,
                    isValid: false,
                    noValidation: false
                },
                address: {
                    value: "",
                    isValid: false
                },
                postalCode: {
                    value: "",
                    isValid: false
                },
                noValidation: false
            },
            step16: {
                jobQuestions: {
                    value: [],
                    isValid: false,
                    noValidation: false
                },
                noValidation: false
            },
            step17: {
                jobTitle: {
                    value: "",
                    isValid: false,
                    noValidation: false
                },
                briefDescription: {
                    value: "",
                    isValid: false,
                    noValidation: false
                },
                noValidation: false
            }
        },
        shouldDisplayError: false
    });

    const updateState =  <T extends keyof any>(stepField: T, field: any, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [stepField]: {
                    ...state.fields[stepField],
                    [field]: {
                        ...state.fields[stepField][field],
                        isValid: isEmpty(newValue) ? false : true,
                        value: newValue
                    }
                }
            }
        }))
    }

    useEffect(() => {
        getDropdownData();
    }, []);

    const getDropdownData = async () => {
        try {
            setIsLoading(true)
            let countries = await getCountries();
            setCountries(() => countries);

            let genders = await getGenders();
            setGenders(() => genders);

            let pets = await getPets();
            setPets(() => pets);

            let hobbies = await getHobbies();
            setHobbies(() => hobbies);

            let languages = await getLanguages();
            setLanguages(() => languages);

            let stepQuestions = await getStepQuestions(5);
            setStepQuestions(() => stepQuestions);
            await constructFormGroupFromQuestionSteps(stepQuestions);

            setIsLoading(false)
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message]);
            setIsLoading(false)
        }
    }

    useEffect(
        () => {
            if(!formGroup.fields.step12?.country?.value){
                updateState('step12', 'county', null);
                setCounties(() => []);
                return;
            }

            fetchCountries();

        },
        [formGroup.fields.step12?.country?.value]
    )

    const fetchCountries = async () => {
        setLoadingCounties(() => true);
        let counties = await getCounties(formGroup.fields.step12?.country?.value?.id);
        setCounties(() => counties);
        setLoadingCounties(() => false);
    }

    useEffect(() => {
        let fields = Object.values(formGroup.fields).reduce(
            (prev: any, curr: any) => {
              return {
                ...prev,
                ...curr
              }
            },
            {}
          ) as any;

          setFlattenedFormValues(() => fields);
    },[formGroup])

    const constructFormGroupFromQuestionSteps = async (stepQuestions: StepDto[]) => {
        stepQuestions.forEach(questionStep => {
            var group: any = {};

            if(!questionStep.questions.length) return;

            questionStep.questions.forEach(question => {
                if (question.isRequired && !question.referenceAnswerId) {
                    (group[question.id.toString()] as StateFieldType<any>) = {
                        value: decideDefaultValueByQuestionType(question.type, question.answers),
                        isValid: false,
                        noValidation: false
                    }
                } else {
                    (group[question.id.toString()] as StateFieldType<any>) = {
                        value: decideDefaultValueByQuestionType(question.type, question.answers),
                        isValid: false
                    }
                }
            });

            setFormGroup((prevState: any) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [`step${(questionStep.order as number)}`]: {
                        ...group
                    }
                }
            }))
    
        })
    }

    const decideDefaultValueByQuestionType = (questionType: number, answers?: AnswerDto[]) => {
        let value: any;
    
        switch (questionType) {
          case QuestionTypeEnum.Checkbox:
            value = [];
            break;
          case QuestionTypeEnum.Radio:
            value = null;
            break;
          case QuestionTypeEnum.Text:
            value = '';
            break;
          case QuestionTypeEnum.Date:
            value = null;
            break;
          case QuestionTypeEnum.Range:
            if (!answers) value = { lower: 0, upper: 100 };
            else value = { lower: answers[0].min ?? 0, upper: answers[0].max ?? 100 }
            break;
          case QuestionTypeEnum.InputRange:
            if (!answers) value = { lower: null, upper: null };
            else value = { lower: null, upper: null }
            break;
          default:
            value = null;
        }
    
        return value;
    }

    const createJobAction = async () => {
        setIsSaving(() => true);

        let requiredSteps = Object.keys(formGroup.fields)
            .filter(f => formGroup.fields[f].noValidation === false);

        if(formGroup.fields.step1?.careFor?.value.id === 2)
            requiredSteps = requiredSteps.filter(f => f != "step3");    

        let requiredFields = requiredSteps.map(step => {
            return formGroup.fields[step]
        })

        let isNotValid = Object.values(requiredFields)
            .map(field => Object.values(field).map((f: any) => {
                if(f.noValidation === false)
                    return {
                        ...f
                    }
                })
                .filter(f => f))
                .reduce(
                (prev, current) => [...prev, ...current],
                []
            )
            .some(field => !field.isValid);

        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            toast.error(globalStore.translationsText["recruiter.app.components.create-job.error.fill-fields"])
            return;
        };

        
        try {
            var createJboDto: CreateJobDto = {
                recruiterInfoId: recruiterInfo.id,
                careFor: flattenedFormValues.careFor.value,
                birthDate: moment(flattenedFormValues.birthDate.value).add(1, 'days').toDate(),
                jobQuestions: flattenedFormValues.jobQuestions.value,
                address: flattenedFormValues.address.value,
                beneficiaryPicture: flattenedFormValues.beneficiaryPicture.value,
                briefDescription: flattenedFormValues.briefDescription.value,
                country: flattenedFormValues.country.value,
                county: flattenedFormValues.county.value,
                firstName: flattenedFormValues.firstName.value,
                foreignLanguages: flattenedFormValues.foreignLanguages.value,
                gender: flattenedFormValues.gender.value,
                helpWith: flattenedFormValues.helpWith.value,
                hobbies: flattenedFormValues.hobbies.value,
                jobTitle: flattenedFormValues.jobTitle.value,
                lastName: flattenedFormValues.lastName.value,
                motherLanguage: flattenedFormValues.motherLanguage.value,
                personalityDescription: flattenedFormValues.personalityDescription.value,
                pets: flattenedFormValues.pets.value,
                postalCode: flattenedFormValues.postalCode.value,
                jobAnswers: constructJobAnswers(flattenedFormValues, stepQuestions)
            }
            await createJob(createJboDto);
            setIsSaving(() => false);
            uiStore.dismissPanel();
            toast.success("The job was successfully created!");
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message]);
            setIsSaving(() => false);
        }
    }

    return (
        <CreateJobPanelStyled>
            {
                isLoading ? (
                    <LinearProgress style={{ borderRadius: "10px" }} />
                )
                :
                <div>
                    {/* step1 */}
                    <FormSectionComponent
                        variant="panel"
                        title={`${globalStore.translationsText["recruiter.app.components.create-job.care-for.label.title"]}`}
                    >
                        <RadioSelectComponent 
                            answers={careForOptions}
                            questionTag="recruiter.app.components.create-job.caregiver-details.label.careFor"    
                            onChangeSelectedValue={(value) => updateState('step1', 'careFor', value)}
                            selectedValue={formGroup.fields.step1?.careFor?.value} 
                            isRequired={formGroup.fields.step1?.careFor?.noValidation === false}           
                        />
                    </FormSectionComponent>

                    {
                        !!formGroup.fields.step1?.careFor?.value ?
                        <>
                            {
                                formGroup.fields.step1?.careFor?.value.id === 1 ?
                                <>
                                    {/* step 3 */}
                                    <FormSectionComponent
                                        variant="panel"
                                        title={`${globalStore.translationsText["recruiter.app.components.create-job.step3.label.title"]}`}
                                        closedByDefault
                                    >
                                        <FormTextInputComponent
                                            questionTag="recruiter.app.components.create-job.label.firstName"    
                                            onChangeValue={(value: any) => updateState('step3', 'firstName', value)}
                                            value={formGroup.fields.step3?.firstName?.value} 
                                            isRequired={formGroup.fields.step3?.firstName?.noValidation === false}     
                                            questionPlaceholder="recruiter.app.components.create-job.input.firstName"     
                                            error={formGroup?.shouldDisplayError && !formGroup.fields.step3?.firstName?.isValid} 
                                        />
                                        <FormTextInputComponent
                                            questionTag="recruiter.app.components.create-job.label.lastName"    
                                            onChangeValue={(value: any) => updateState('step3', 'lastName', value)}
                                            value={formGroup.fields.step3?.lastName?.value} 
                                            isRequired={formGroup.fields.step3?.lastName?.noValidation === false}     
                                            questionPlaceholder="recruiter.app.components.create-job.input.lastName"     
                                            error={formGroup?.shouldDisplayError && !formGroup.fields.step3?.lastName?.isValid} 
                                        />
                                        <FormSingleSelectComponent
                                            options={genders?.map((status: any) => ({ 
                                                label: globalStore.translationsText[status.tag], 
                                                value: status.tag ,
                                                name: status.name,
                                                tag: status.tag,
                                                id: status.id  
                                            }))}
                                            questionTag="recruiter.app.components.create-job.label.gender"    
                                            onChangeValue={(value: any) => updateState('step3', 'gender', value)}
                                            value={
                                                formGroup.fields.step3?.gender?.value ?
                                                {
                                                    label: globalStore.translationsText[formGroup.fields.step3?.gender?.value.tag ?? ""],
                                                    value: formGroup.fields.step3?.gender?.value.tag,
                                                    name: formGroup.fields.step3?.gender?.value.name,
                                                    tag: formGroup.fields.step3?.gender?.value.tag,
                                                    id:  formGroup.fields.step3?.gender?.value.id
                                                } : null
                                            } 
                                            isRequired={formGroup.fields.step3?.gender?.noValidation === false}     
                                            questionPlaceholder="recruiter.app.components.create-job.select.search"     
                                            error={formGroup?.shouldDisplayError && !formGroup.fields.step3?.gender?.isValid} 
                                        />
                                        <FromDatePickerComponent
                                            questionTag="recruiter.app.components.create-job.label.birthday"    
                                            onChangeValue={(value: any) => updateState('step3', 'birthDate', value)}
                                            value={formGroup.fields.step3?.birthDate?.value} 
                                            isRequired={formGroup.fields.step3?.birthDate?.noValidation === false}     
                                            questionPlaceholder="recruiter.app.components.create-job.input.birthday"     
                                            error={formGroup?.shouldDisplayError && !formGroup.fields.step3?.birthDate?.isValid} 
                                        />
                                        <FormSingleSelectComponent
                                            options={languages?.map((status: any) => ({ 
                                                label: status.name, 
                                                value: status.tag ,
                                                name: status.name,
                                                tag: status.tag,
                                                id: status.id 
                                            }))}
                                            questionTag="recruiter.app.components.create-job.language.label.motherLanguage"    
                                            onChangeValue={(value: any) => updateState('step3', 'motherLanguage', value)}
                                            value={
                                                formGroup.fields.step3?.motherLanguage?.value ?
                                                {
                                                    label: formGroup.fields.step3?.motherLanguage.value.name,
                                                    value: formGroup.fields.step3?.motherLanguage.value.tag,
                                                    name: formGroup.fields.step3?.motherLanguage.value.name,
                                                    tag: formGroup.fields.step3?.motherLanguage.value.tag,
                                                    id:  formGroup.fields.step3?.motherLanguage.value.id
                                                } : null
                                            } 
                                            isRequired={formGroup.fields.step3?.motherLanguage?.noValidation === false}     
                                            questionPlaceholder="recruiter.app.components.create-job.modal.language.input.motherLanguage"     
                                            error={formGroup?.shouldDisplayError && !formGroup.fields.step3?.motherLanguage?.isValid} 
                                        />
                                        <FromMultiSelectComponent
                                            options={languages?.map((status: any) => ({ 
                                                label: status.name, 
                                                value: status.tag ,
                                                name: status.name,
                                                tag: status.tag,
                                                id: status.id 
                                            }))}
                                            questionTag="recruiter.app.components.create-job.language.label.additional"    
                                            onChangeValue={(value: any) => updateState('step3', 'foreignLanguages', value)}
                                            value={
                                                formGroup.fields.step3?.foreignLanguages?.value ?
                                                    formGroup.fields.step3?.foreignLanguages?.value.map((m: any) => ({
                                                            label: m.name,
                                                            value: m.tag,
                                                            name: m.name,
                                                            tag: m.tag,
                                                            id:  m.id
                                                        }))
                                                    : []
                                            } 
                                            isRequired={formGroup.fields.step3?.foreignLanguages?.noValidation === false}     
                                            questionPlaceholder="recruiter.app.components.create-job.modal.language.input.foreignLanguage"
                                        />
                                    </FormSectionComponent>
                
                                    {/* step4 */}
                                    <FormSectionComponent
                                        variant="panel"
                                        title={`${globalStore.translationsText["recruiter.app.components.create-job.photo.label.title"]}`}
                                        closedByDefault
                                    >
                                        <DropzoneUploadComponent 
                                            onFileChange={(value: any) => updateState('step4', 'beneficiaryPicture', value)}
                                            value={formGroup.fields.step4?.beneficiaryPicture?.value}
                                            tag="recruiter.app.components.create-job.identity.button.upload-profile"
                                            deleteFile="/File/Delete"
                
                                        />
                                    </FormSectionComponent>
                                </>
                                :
                                <></>
                            }
        
                            {/* step 5 */}
                            <FormSectionComponent
                                variant="panel"
                                title={`${globalStore.translationsText["recruiter.app.components.create-job.about-person.title"]}`}
                                closedByDefault
                            >
                                <FormTextInputComponent
                                    questionTag="recruiter.app.components.create-job.about-person.personality.label"    
                                    onChangeValue={(value: any) => updateState('step5', 'personalityDescription', value)}
                                    value={formGroup.fields.step3?.personalityDescription?.value} 
                                    isRequired={formGroup.fields.step3?.personalityDescription?.noValidation === false}     
                                    questionPlaceholder="recruiter.app.components.create-job.about-person.personality.placeholder"     
                                    error={formGroup?.shouldDisplayError && !formGroup.fields.step3?.personalityDescription?.isValid}
                                    minRows={7}
                                />
                                <FromMultiSelectComponent
                                    options={pets?.map((status: any) => ({ 
                                        label: status.name, 
                                        value: status.tag ,
                                        name: status.name,
                                        tag: status.tag,
                                        id: status.id 
                                    }))}
                                    questionTag="recruiter.app.components.create-job.about-person.title"    
                                    onChangeValue={(value: any) => updateState('step5', 'pets', value)}
                                    value={
                                        formGroup.fields.step5?.pets?.value ?
                                            formGroup.fields.step5?.pets?.value.map((m: any) => ({
                                                    label: m.name,
                                                    value: m.tag,
                                                    name: m.name,
                                                    tag: m.tag,
                                                    id:  m.id
                                                }))
                                            : []
                                    } 
                                    isRequired={formGroup.fields.step5?.pets?.noValidation === false}     
                                    questionPlaceholder="recruiter.app.components.create-job.about-person.pets.modal.placeholder"
                                />
                                <FromMultiSelectComponent
                                    options={hobbies?.map((status: any) => ({ 
                                        label: globalStore.translationsText[status.tag ?? ""], 
                                        value: status.tag ,
                                        name: status.name,
                                        tag: status.tag,
                                        id: status.id 
                                    }))}
                                    questionTag="recruiter.app.components.create-job.about-person.hobbies.label"    
                                    onChangeValue={(value: any) => updateState('step5', 'hobbies', value)}
                                    value={
                                        formGroup.fields.step5?.hobbies?.value ?
                                            formGroup.fields.step5?.hobbies?.value.map((m: any) => ({
                                                    label: globalStore.translationsText[m.tag ?? ""],
                                                    value: m.tag,
                                                    name: m.name,
                                                    tag: m.tag,
                                                    id:  m.id
                                                }))
                                            : []
                                    } 
                                    isRequired={formGroup.fields.step5?.hobbies?.noValidation === false}     
                                    questionPlaceholder="recruiter.app.components.create-job.about-person.hobbies.placeholder"
                                />
                            </FormSectionComponent>
        
                            {/* dynamic questions */}
                            {
                                stepQuestions.map(questionStep => {
                                    if(questionStep.order != 18)
                                        return (
                                            <FormSectionComponent
                                                variant="panel"
                                                title={`${globalStore.translationsText[questionStep.tag ?? ""]}`}
                                                closedByDefault
                                            >
                                                {
                                                    questionStep.questions.map(question => {
                                                        if(question.type === 1){
                                                            return (
                                                                <RadioSelectComponent 
                                                                    answers={question.answers}
                                                                    questionTag={`${question.questionTag}`}    
                                                                    onChangeSelectedValue={(value) => updateState(`step${questionStep.order}`, `${question.id}`, value)}
                                                                    selectedValue={formGroup.fields[`step${questionStep.order}`][`${question.id}`].value} 
                                                                    isRequired={formGroup.fields[`step${questionStep.order}`][`${question.id}`].noValidation === false}           
                                                                />
                                                            )
                                                        } else if(question.type === 2) {
                                                            return (
                                                                <CheckboxMultiSelectComponent 
                                                                    answers={question.answers}
                                                                    questionTag={`${question.questionTag}`}    
                                                                    onChangeSelectedValues={(value: any) => updateState(`step${questionStep.order}`, `${question.id}`, value)}
                                                                    selectedValues={formGroup.fields[`step${questionStep.order}`][`${question.id}`].value} 
                                                                    isRequired={formGroup.fields[`step${questionStep.order}`][`${question.id}`].noValidation === false}           
                                                                />
                                                            )
        
                                                        } else if(question.type === 3){
                                                            return (
                                                                <FormTextInputComponent
                                                                    questionTag={`${question.questionTag}`}    
                                                                    onChangeValue={(value: any) => updateState(`step${questionStep.order}`, `${question.id}`, value)}
                                                                    value={formGroup.fields[`step${questionStep.order}`][`${question.id}`].value} 
                                                                    isRequired={formGroup.fields[`step${questionStep.order}`][`${question.id}`].noValidation === false}     
                                                                    questionPlaceholder={question.answers[0].placeholderTag ?? ""}
                                                                />
                                                            )
                                                        } else if(question.type === 5) {
                                                            return (
                                                                <FromDatePickerComponent
                                                                    questionTag={`${question.questionTag}`}     
                                                                    onChangeValue={(value: any) => updateState(`step${questionStep.order}`, `${question.id}`, value)}
                                                                    value={formGroup.fields[`step${questionStep.order}`][`${question.id}`].value}
                                                                    isRequired={formGroup.fields[`step${questionStep.order}`][`${question.id}`].noValidation === false}    
                                                                    questionPlaceholder={question.answers[0].placeholderTag ?? ""}
                                                                />
                                                            )
                                                        } else if(question.type === 6) {
                                                            return (
                                                                <FormInputRangeComponent 
                                                                    questionTag={`${question.questionTag}`}  
                                                                    onChangeValue={(value: any) => updateState(`step${questionStep.order}`, `${question.id}`, value)}
                                                                    value={formGroup.fields[`step${questionStep.order}`][`${question.id}`].value}
                                                                />
                                                            )
                                                        }
                                                    })
                                                }
        
                                                {
                                                    questionStep.order == 12 ?
                                                        <div>
                                                            <FormSingleSelectComponent
                                                                options={countries?.map((status: any) => ({ 
                                                                    label: status?.name, 
                                                                    value: status?.tag ,
                                                                    name: status?.name,
                                                                    tag: status?.tag,
                                                                    id: status?.id 
                                                                }))}
                                                                questionTag="recruiter.app.components.create-job.work-location.label.country"    
                                                                onChangeValue={(value: any) => updateState('step12', 'country', value)}
                                                                value={
                                                                    formGroup.fields.step12?.country?.value?.id ?
                                                                    {
                                                                        label: formGroup.fields.step12?.country?.value.name,
                                                                        value: formGroup.fields.step12?.country?.value.tag,
                                                                        name: formGroup.fields.step12?.country?.value.name,
                                                                        tag: formGroup.fields.step12?.country?.value.tag,
                                                                        id:  formGroup.fields.step12?.country?.value.id
                                                                    } : null
                                                                    
                                                                } 
                                                                isRequired={formGroup.fields.step12?.country?.noValidation === false}     
                                                                questionPlaceholder="recruiter.app.components.create-job.modal.work-location.input.country"     
                                                                error={formGroup?.shouldDisplayError && !formGroup.fields.step12?.country?.isValid} 
                                                            />
                                                            <FormSingleSelectComponent
                                                                options={counties?.map((status: any) => ({ 
                                                                    label: status?.name, 
                                                                    value: status?.tag ,
                                                                    name: status?.name,
                                                                    tag: status?.tag,
                                                                    id: status?.id 
                                                                }))}
                                                                questionTag="recruiter.app.components.create-job.work-location.label.county"    
                                                                onChangeValue={(value: any) => updateState('step12', 'county', value)}
                                                                value={
                                                                    formGroup.fields.step12?.county?.value?.id ?
                                                                    {
                                                                        label: formGroup.fields.step12?.county?.value.name,
                                                                        value: formGroup.fields.step12?.county?.value.tag,
                                                                        name: formGroup.fields.step12?.county?.value.name,
                                                                        tag: formGroup.fields.step12?.county?.value.tag,
                                                                        id:  formGroup.fields.step12?.county?.value.id
                                                                    } : null
                                                                    
                                                                } 
                                                                isRequired={formGroup.fields.step12?.county?.noValidation === false}     
                                                                questionPlaceholder="recruiter.app.components.create-job.modal.work-location.input.county"     
                                                                error={formGroup?.shouldDisplayError && !formGroup.fields.step12?.county?.isValid}
                                                                loading={loadingCounties}
                                                                disabled={loadingCounties} 
                                                            />
                                                            <FormTextInputComponent
                                                                questionTag="recruiter.app.components.create-job.label.address"    
                                                                onChangeValue={(value: any) => updateState('step12', 'address', value)}
                                                                value={formGroup.fields.step12?.address?.value} 
                                                                isRequired={formGroup.fields.step12?.address?.noValidation === false}     
                                                                questionPlaceholder="recruiter.app.components.create-job.input.address"     
                                                            />
                                                            <FormTextInputComponent
                                                                questionTag="recruiter.app.components.create-job.label.postal-code"    
                                                                onChangeValue={(value: any) => updateState('step12', 'postalCode', value)}
                                                                value={formGroup.fields.step12?.postalCode?.value} 
                                                                isRequired={formGroup.fields.step12?.postalCode?.noValidation === false}     
                                                                questionPlaceholder="recruiter.app.components.create-job.input.postal-code"     
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
        
                                                {
                                                    questionStep.order == 16 ?
                                                        <ChooseCandidateQuestionsComponent
                                                            questions={formGroup.fields.step16?.jobQuestions?.value}
                                                            setQuestions={(value: any) => updateState('step16', 'jobQuestions', value)}
                                                            recruiterId={recruiterInfo.id}
                                                            appLanguageId={appLanguageId}
                                                        />
                                                        :
                                                        <></>
                                                }
        
                                                {
                                                    questionStep.order == 17 ?
                                                        <div>
                                                            <FormTextInputComponent
                                                                questionTag="recruiter.app.components.create-job.summary.job-title.label"    
                                                                onChangeValue={(value: any) => updateState('step17', 'jobTitle', value)}
                                                                value={formGroup.fields.step17?.jobTitle?.value} 
                                                                isRequired={formGroup.fields.step17?.jobTitle?.noValidation === false}     
                                                                questionPlaceholder="recruiter.app.components.create-job.summary.job-title.placeholder"     
                                                                error={formGroup?.shouldDisplayError && !formGroup.fields.step17?.jobTitle?.isValid} 
                                                            />
                                                            <FormTextInputComponent
                                                                questionTag="recruiter.app.components.create-job.summary.brief-description.label"    
                                                                onChangeValue={(value: any) => updateState('step17', 'briefDescription', value)}
                                                                value={formGroup.fields.step17?.briefDescription?.value} 
                                                                isRequired={formGroup.fields.step17?.briefDescription?.noValidation === false}     
                                                                questionPlaceholder="recruiter.app.components.create-job.summary.brief-description.placeholder"     
                                                                error={formGroup?.shouldDisplayError && !formGroup.fields.step17?.briefDescription?.isValid} 
                                                                minRows={7}
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </FormSectionComponent>
                                        )
                                })
                            }
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "2rem",
                                    marginBottom: "2rem"
                                }}      
                            >
                                <ButtonComponent 
                                    onClick={createJobAction} 
                                    isLoading={isSaving}
                                    sx={{
                                        width: "5rem"
                                    }}
                                >
                                    Save
                                </ButtonComponent>
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
            }
        </CreateJobPanelStyled>
    )
})

export default CreatePanelComponent;