import { observer } from "mobx-react-lite";
import TransactionsPageStyled from "./transactions.page.styled";
import { useStore } from "../../../../hooks/store.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getTransactions } from "../../../../utils/requests";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import moment from "moment";

export type TransactionDto = {
    recruiter: UserInfoDto;
    transactionId: string;
    transactionDate: Date;
    value: number;
    currency: string;
    transactionPlatform: string;
    payPlan: PayPlanDto;
}

type UserInfoDto = {
    userInfoId: number;
    userName: string;
}

type PayPlanDto = {
    planType: string;
    payType: string;
}

type GetTransactionsType = {
    numberOfPages: number;
    numberOfTransactions: number;
    transactions: TransactionDto[]
}

const TransactionsPage = observer(() => {

    const globalStore = useStore('globalStore');
    const [transactions, setTransactions] = useState<GetTransactionsType>();
    const [transactionsLoading, setTransactionsLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    
    useEffect(
        () => {
            async function getData() {
                try {
                    setTransactionsLoading(() => true)
                    let fetchJobs = await getTransactions({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        country:filtersTable.selectFilterValue?.value,
                        county:filtersTable.secondSelectFilterValue?.value,
                        search:filtersTable.searchText
                    });
                    setTransactions(() => fetchJobs)
                    setTransactionsLoading(() => false)
                } catch (error) {
                    setTransactionsLoading(() => false)
                }
            }

            if(!filtersTable) return;
            
            getData();
            
        },
        [filtersTable]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!transactions) return {
                data: [],
                headers: []
            }

            return {
                data: transactions.transactions,
                headers: [
                   {
                       id: 'transactionId',
                       label: 'Transaction Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'transactionDate',
                        label: 'Transaction Date',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'value',
                        label: 'Value',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'currency',
                        label: 'Currency',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'transactionPlatform',
                        label: 'Transaction Platform',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'payType',
                        label: 'Pay Type',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'planType',
                        label: 'Plan Type',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: transactions.numberOfTransactions
            }
        },
        [transactions]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                recruiter: (row: TransactionDto) => {
                    return (
                        <>
                            <div>{row.recruiter.userName}</div>
                        </>
                    )
                },
                transactionPlatform: (row: TransactionDto) => {
                    return (
                        <>
                            <div>{globalStore.translationsText[row.transactionPlatform]}</div>
                        </>
                    )
                },
                payType: (row: TransactionDto) => {
                    return (
                        <>
                            {globalStore.translationsText[row.payPlan.payType]}
                        </>
                    )
                },
                planType: (row: TransactionDto) => {
                    return (
                        <>
                            {globalStore.translationsText[row.payPlan.planType]}
                        </>
                    )
                },
                currency: (row: TransactionDto) => {
                    return (
                        <>
                            {globalStore.translationsText[row.currency]}
                        </>
                    )
                },
                transactionDate: (row: TransactionDto) => {
                    return (
                        <>
                            {new Date(row.transactionDate).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                        </>
                    )
                }
            }
        },
        [globalStore.translationsText]
    )

    const customTableRowAction = useCallback(
        (row: any) => {
            console.log(row)
        },
        []
    )

    return (
        <TransactionsPageStyled>
            <PageTemplateComponent title="Transactions">
                <TableComponent
                    tableKey={TableTypeEnum.Transactions} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Search by recruiter's name"
                    withoutSelect
                    isLoading={transactionsLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    customRenderer={customRenderer}
                    // customTableRowAction={customTableRowAction}
                />  
            </PageTemplateComponent>
        </TransactionsPageStyled>
    )
})

export default TransactionsPage;