import {DashboardPageStyled} from "./dashboard.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getCounties, getCountries, getNoCandidatesJobs, getReadyToExpireJobs, getReports, getReviewersWaitingJobs } from "../../../../utils/requests";
import { LinearProgress } from "@mui/material";
import CardNumericRaport from "../../../../components/card-numeric-raport/card-numeric-raport.component";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import moment from "moment";
import { observer } from "mobx-react-lite";
import BasicSelect from "../../../../components/dropdown-table-component/dropdown-table-component.component";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import JobDetailsPanel from "../../../../components/job-details-panel/job-details-panel.component";
import UserDetailsPanelComponent from "../../../../components/user-details-panel/user-details-panel.component";
import { UserTypeEnum } from "../../../../enums/user-type.enum";
import { useNavigate } from "react-router-dom";
import JobCandidatesPanelComponent from "../../../../components/job-candidates-panel/job-candidates-panel.component";

type GetJobJobCandidatesType = {
    status: string;
    candidateName: string;
    candidateId: number;
    reviewedBy: any[];
}

type GetJobJobReviewersType = {
    reviewerName: string;
    jobReviewerId: number;
}

export type GetJobsType = {
    jobCandidateId: number;
    jobId: number;
    jobTitle: string;
    recruiter: any;
    jobCandidates: GetJobJobCandidatesType[];
    jobReviewers: GetJobJobReviewersType[];
    jobQuestions: any[];
    candidateDeadline: Date;
    pricePlan: string
}

export type GetJobType = {
    jobs: GetJobsType[];
    numberOfJobs: number;
    numberOfPages: number;
}

const DashboardPage = observer(() => {

    const navigate = useNavigate();

    const [cardReportsData, setCardReportsData] = useState<any>();
    const [countriesRaports, setCountriesRaports] = useState<any>()
    const [countiesRaports, setCountiesRaports] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [initialCountryRaports, setInitialCountryRaports] = useState<any>()
    const [filtersRaports, setFilterRaports] = useState<any>({
        country: null,
        county: null
  })

    // Jobs ready to expire in 5 days states
    const [jobsReadyToExpire, setJobsReadyToExpire] = useState<GetJobType>()
    const [jobsReadyToExpireLoading, setJobsReadyToExpireLoading] = useState<boolean>(true);
    const [initialCountryRTE, setInitialCountryRTE] = useState<any>()
    const [filtersTableReadyToExpire, setFiltersReadyToExpire] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    })
    const [countriesRTE, setCountriesRTE] = useState<any>()
    const [countiesRTE, setCountiesRTE] = useState<any>()

    // Jobs with reviwers in waiting states
    const [jobsReviwersWaiting, setJobsReviwersWaiting] = useState<GetJobType>()
    const [jobsReviwersWaitingLoading, setJobsReviwersWaitingLoading] = useState<boolean>(true);
    const [initialCountryRW, setInitialCountryRW] = useState<any>()
    const [filtersTableReviwersWaiting, setFiltersReviwersWaiting] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const [countriesRW, setCountriesRW] = useState<any>()
    const [countiesRW, setCountiesRW] = useState<any>()

    // Jobs with no candidates
    const [jobsNoCandidates, setJobsNoCandidates] = useState<GetJobType>()
    const [jobsNoCandidatesLoading, setJobsNoCandidatesLoading] = useState<boolean>(true);
    const [initialCountryNC, setInitialCountryNC] = useState<any>()
    const [filtersTableNoCandidates, setFiltersNoCandidates] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const [countriesNC, setCountriesNC] = useState<any>()
    const [countiesNC, setCountiesNC] = useState<any>();

    const uiStore = useStore('uiStore');

    const onSelectValueChange = React.useCallback(
        async (newValue: any) => {
          if (filtersRaports?.country !== newValue)
            setFilterRaports((tableState: any) => ({
              ...tableState,
              country: newValue,
              county: null
            }))

            let initialCountry = countriesRaports.find((f: { tag: string; }) => f.tag === newValue.value)
            let counties = await getCounties(initialCountry?.id);
            setCountiesRaports(() => counties);
        },
        [filtersRaports]
    )
    
      const onSecondSelectValueChange = React.useCallback(
        async (newValue: any) => {
            if (filtersRaports?.county !== newValue)
            setFilterRaports((tableState: any) => ({
              ...tableState,
              county: newValue,
            }))
        },
        [filtersRaports]
    )

    useEffect(
        () => {
            async function getData() {
                setIsLoading(() => true);
                try {
                    
                    let countries = await getCountries();
                    setCountriesRTE(() => countries);
                    setCountriesRW(() => countries);
                    setCountriesNC(() => countries);
                    setCountriesRaports(() => countries);

                    // for raports
                    let initialCountry = countries.find(f => f.tag === "api.general.country3")
                    setInitialCountryRaports(() => initialCountry)
                    setFilterRaports((prevState: any) => ({
                        ...prevState,
                        country: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    setInitialCountryRTE(() => initialCountry)
                    setFiltersReadyToExpire((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    // for jobs which have reviewers waiting
                    setInitialCountryRW(() => initialCountry)
                    setFiltersReviwersWaiting((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    // for jobs which have no candidates
                    setInitialCountryNC(() => initialCountry)
                    setFiltersNoCandidates((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    let counties = await getCounties(initialCountry?.id);
                    setCountiesRTE(() => counties);
                    setCountiesRW(() => counties);
                    setCountiesNC(() => counties);
                    setCountiesRaports(() => counties);

                    setIsLoading(() => false);
                } catch (error) {
                    setIsLoading(() => false)
                }
            }

            getData();
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true)
                    let reports = await getReports({
                        country: filtersRaports?.country?.value,
                        county: filtersRaports?.county?.value
                    });
                    setCardReportsData(() => ({
                        activeJobsNumber: {
                            label: "Active jobs",
                            value: reports.activeJobsNumber
                        },
                        recruitersNumber: {
                            label: "Number of recruiters",
                            value: reports.recruitersNumber
                        },
                        candidatesNumber: {
                            label: "Number of candidates",
                            value: reports.candidatesNumber
                        }
                    }))
                    setIsLoading(() => false)
                } catch (error) {
                    setIsLoading(() => false)
                }
            }
            
            if(!filtersRaports?.country?.value) return
            getData();
            
        },
        [filtersRaports]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsReadyToExpireLoading(() => true)
                    let fetchJobs = await getReadyToExpireJobs({
                        pageIndex:filtersTableReadyToExpire.pageIndex + 1,
                        pageSize:filtersTableReadyToExpire.pageSize,
                        country:filtersTableReadyToExpire.selectFilterValue?.value,
                        county:filtersTableReadyToExpire.secondSelectFilterValue?.value,
                        search:filtersTableReadyToExpire.searchText
                    });
                    setJobsReadyToExpire(() => fetchJobs)
                    setJobsReadyToExpireLoading(() => false)
                } catch (error) {
                    setJobsReadyToExpireLoading(() => false)
                }
            }

            if(!filtersTableReadyToExpire) return;
            if(filtersTableReadyToExpire.selectFilterValue?.value !== initialCountryRTE?.tag) return;
            if(!filtersTableReadyToExpire?.selectFilterValue?.value) {
                setJobsReadyToExpireLoading(() => false)
                return
            }
            
            getData();
            
        },
        [filtersTableReadyToExpire, initialCountryRTE]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsReadyToExpireLoading(() => true)
                    let newCountry = countriesRTE.find((f: { tag: string; }) => f.tag === filtersTableReadyToExpire.selectFilterValue?.value)
                    setInitialCountryRTE(() => newCountry)
                    setFiltersReadyToExpire((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCountiesRTE(() => counties);
                    setJobsReadyToExpireLoading(() => false)
                } catch (error) {
                    setJobsReadyToExpireLoading(() => false)
                }
            }

            if(filtersTableReadyToExpire.selectFilterValue?.value !== initialCountryRTE?.tag) 
                getData()
        },
        [filtersTableReadyToExpire, initialCountryRTE]
    )

    const tableDataJobsReadyToExpire = useMemo(
        (): TableDataPropsType => {
            if(!jobsReadyToExpire) return {
                data: [],
                headers: []
            }

            return {
                data: jobsReadyToExpire.jobs.sort((a, b) => (new Date(a.candidateDeadline).getTime()) - new Date(b.candidateDeadline).getTime()),
                headers: [
                   {
                       id: 'jobId',
                       label: 'Job Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'jobTitle',
                        label: 'Job Title',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },  
                    {
                        id: 'pricePlan',
                        label: 'Price Plan',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },  
                    {
                        id: 'candidates',
                        label: 'Candidates',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },  
                   {
                        id: 'deadline',
                        label: 'Deadline',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: jobsReadyToExpire.numberOfJobs
            }
        },
        [jobsReadyToExpire]
    )

    const customRenderer: CustomRendererPropsType = {
        recruiter: (row: any) => {
            return (
                <>
                    <div>{row.recruiter.recruiterName}</div>
                </>
            )
        },
        candidates: (row: any) => {
            return (
                <>
                    <div>{row.jobCandidates.length}</div>
                </>
            )
        },
        deadline: (row: any) => {
            let remaining = moment.duration(moment(new Date(row.candidateDeadline)).diff(moment(new Date)));

            if(remaining.asMinutes() <= 0){
                return (
                  <div>The pending session has closed.</div>
                )
            }

            if (remaining.asDays() < 1) {
                if (remaining.asHours() < 1) {
                  return (
                    <div>{remaining.minutes().toString()} minutes</div>
                  )
                } else {
                return (
                    <div>{remaining.hours().toString()} hours and {remaining.minutes().toString()} minutes</div>
                  )
                }
              } else {
                return (
                    <div>{Math.floor(remaining.asDays()).toString()} days and {remaining.hours().toString()} hours</div>
                  )
              }
        },
        pricePlan: (row: any) => {
            return (
                <>
                    <div>{row.pricePlan}</div>
                </>
            )
        }
    }

     // for jobs which have reviewers waiting
    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsReviwersWaitingLoading(() => true)
                    let fetchJobs = await getReviewersWaitingJobs({
                        pageIndex:filtersTableReviwersWaiting.pageIndex + 1,
                        pageSize:filtersTableReviwersWaiting.pageSize,
                        country:filtersTableReviwersWaiting.selectFilterValue?.value,
                        county:filtersTableReviwersWaiting.secondSelectFilterValue?.value,
                        search:filtersTableReviwersWaiting.searchText
                    });
                    setJobsReviwersWaiting(() => fetchJobs)
                    setJobsReviwersWaitingLoading(() => false)
                } catch (error) {
                    setJobsReviwersWaitingLoading(() => false)
                }
            }

            if(!filtersTableReviwersWaiting) return;
            if(filtersTableReviwersWaiting.selectFilterValue?.value !== initialCountryRW?.tag) return;
            if(!filtersTableReviwersWaiting?.selectFilterValue?.value) {
                setJobsReviwersWaitingLoading(() => false)
                return
            }
            
            getData();
            
        },
        [filtersTableReviwersWaiting, initialCountryRW]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsReviwersWaitingLoading(() => true)
                    let newCountry = countriesRW.find((f: { tag: string; }) => f.tag === filtersTableReviwersWaiting.selectFilterValue?.value)
                    setInitialCountryRW(() => newCountry)
                    setFiltersReviwersWaiting((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCountiesRW(() => counties);
                    setJobsReviwersWaitingLoading(() => false)
                } catch (error) {
                    setJobsReviwersWaitingLoading(() => false)
                }
            }

            if(filtersTableReviwersWaiting.selectFilterValue?.value !== initialCountryRW?.tag) 
                getData()
        },
        [filtersTableReviwersWaiting, initialCountryRW]
    )

    const tableDataJobsReviewersWaiting = useMemo(
        (): TableDataPropsType => {
            if(!jobsReviwersWaiting) return {
                data: [],
                headers: []
            }

            let jobs = jobsReviwersWaiting.jobs.map(m => {

                let candidates = m.jobCandidates.map(n => {
                    return {
                        jobId: m.jobId,
                        jobTitle: m.jobTitle,
                        recruiter: m.recruiter,
                        candidate: {
                            name: n.candidateName,                            
                            id: n.candidateId
                        },
                        status: n.status,
                        reviewerOne: {
                            exists: m.jobReviewers[0] != undefined ? true : false,
                            hasReviewed: m.jobReviewers[0] != undefined ? !!n.reviewedBy.find(f => f.jobReviewerId ===  m.jobReviewers[0]?.jobReviewerId) : undefined
                        },
                        reviewerTwo: {
                            exists: m.jobReviewers[1] != undefined ? true : false,
                            hasReviewed: m.jobReviewers[1] != undefined ? !!n.reviewedBy.find(f => f.jobReviewerId ===  m.jobReviewers[1]?.jobReviewerId) : undefined
                        },
                        candidateDeadline: m.candidateDeadline
                    }
                })

                return candidates;
            })

            let jobCandidates = (jobs as any[][]).reduce(
                (prev: any, current) => [...prev, ...current],
                []
            )

            return {
                data: jobCandidates.sort((a, b) => (new Date(a.candidateDeadline).getTime()) - new Date(b.candidateDeadline).getTime()),
                headers: [
                   {
                       id: 'jobId',
                       label: 'Job Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'jobTitle',
                        label: 'Job Title',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'candidates',
                        label: 'Candidate',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'reviewerOne',
                        label: 'Reviewer 1',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'reviewerTwo',
                        label: 'Reviewer 2',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: jobsReviwersWaiting.numberOfJobs
            }
        },
        [jobsReviwersWaiting]
    )

    const customRendereraJobsReviewersWaiting: CustomRendererPropsType = {
        recruiter: (row: any) => {
            // console.log(row)
            return (
                <>
                    <div>{row.recruiter.recruiterName}</div>
                </>
            )
        },
        candidates: (row: any) => {
            return (
                <>
                    <div>{row.candidate.name}</div>
                </>
            )
        },
        reviewerOne: (row: any) => {
            return (
                <>
                    {
                        row.reviewerOne.exists ?
                            row.reviewerOne.hasReviewed ?
                             "Review is done"
                             :
                             "Review in pending"
                            :
                            "No reviewer assigned"
                    }
                </>
            )
        },
        reviewerTwo: (row: any) => {
            return (
                <>
                {
                        row.reviewerTwo.exists ?
                            row.reviewerTwo.hasReviewed ?
                             "Review is done"
                             :
                             "Review in pending"
                            :
                            "No reviewer assigned"
                    }
                </>
            )
        }
    }

    const customTableCellActionJobsReviewersWaiting = useMemo(
        () => {
            return {
                jobId: (row: GetJobsType) => {
                    openJobDetails(row.jobId, row.jobTitle)
                },
                jobTitle: (row: GetJobsType) => {
                    openJobDetails(row.jobId, row.jobTitle)
                },
                recruiter: (row: GetJobsType) => {
                    uiStore.openPanel({
                        key: PanelType.UserDetails,
                        component: <UserDetailsPanelComponent 
                            userInfoId={row.recruiter.recruiterId}
                            userType={UserTypeEnum.Recruiter}
                        />,
                        panelWidth: '700px',
                        title: `${row.recruiter.recruiterName} Details`,
                        onDismiss: () => {
                        }
                    });
                },
                candidates: (row: any) => {
                    uiStore.openPanel({
                        key: PanelType.UserDetails,
                        component: <UserDetailsPanelComponent 
                            userInfoId={row.candidate.id}
                            userType={UserTypeEnum.Candidate}
                        />,
                        panelWidth: '700px',
                        title: `${row.candidate.name} Details`,
                        onDismiss: () => {
                        }
                    });
                }
            }
        },
        []
    )

     // for jobs with no candidates
    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsNoCandidatesLoading(() => true)
                    let fetchJobs = await getNoCandidatesJobs({
                        pageIndex:filtersTableNoCandidates.pageIndex + 1,
                        pageSize:filtersTableNoCandidates.pageSize,
                        country:filtersTableNoCandidates.selectFilterValue?.value,
                        county:filtersTableNoCandidates.secondSelectFilterValue?.value,
                        search:filtersTableNoCandidates.searchText
                    });
                    setJobsNoCandidates(() => fetchJobs)
                    setJobsNoCandidatesLoading(() => false)
                } catch (error) {
                    setJobsNoCandidatesLoading(() => false)
                }
            }

            
            if(!filtersTableNoCandidates) return;
            if(filtersTableNoCandidates.selectFilterValue?.value !== initialCountryNC?.tag) return;
            if(!filtersTableNoCandidates?.selectFilterValue?.value) {
                setJobsNoCandidatesLoading(() => false)
                return
            }
            
            getData();
            
        },
        [filtersTableNoCandidates, initialCountryNC]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsNoCandidatesLoading(() => true)
                    let newCountry = countriesNC.find((f: { tag: string; }) => f.tag === filtersTableNoCandidates.selectFilterValue?.value)
                    setInitialCountryNC(() => newCountry)
                    setFiltersNoCandidates((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCountiesNC(() => counties);
                    setJobsNoCandidatesLoading(() => false)
                } catch (error) {
                    setJobsNoCandidatesLoading(() => false)
                }
            }

            if(filtersTableNoCandidates.selectFilterValue?.value !== initialCountryNC?.tag) 
                getData()
        },
        [filtersTableNoCandidates, initialCountryNC]
    )

    const tableDataJobsNoCandidates = useMemo(
        (): TableDataPropsType => {
            if(!jobsNoCandidates) return {
                data: [],
                headers: []
            }

            return {
                data: jobsNoCandidates.jobs.sort((a, b) => (new Date(a.candidateDeadline).getTime()) - new Date(b.candidateDeadline).getTime()),
                headers: [
                   {
                       id: 'jobId',
                       label: 'Job Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'jobTitle',
                        label: 'Job Title',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },  
                    {
                        id: 'pricePlan',
                        label: 'Price Plan',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },  
                    {
                        id: 'candidates',
                        label: 'Candidates',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },  
                   {
                        id: 'deadline',
                        label: 'Deadline',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: jobsNoCandidates.numberOfJobs
            }
        },
        [jobsNoCandidates]
    )

    //open job details panel
    const openJobDetails = useCallback(
        (jobId: number, jobTitle: string) => {
            uiStore.openPanel({
                key: PanelType.JobDetails,
                component: <JobDetailsPanel 
                    jobId={jobId}
                />,
                panelWidth: '700px',
                title: `Job Details - ${jobTitle}`,
                onDismiss: () => {
                }
            });
        },
        []
    )

    const customTableCellAction = {
        jobId: (row: GetJobsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        jobTitle: (row: GetJobsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        recruiter: (row: GetJobsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.recruiter.recruiterId}
                    userType={UserTypeEnum.Recruiter}
                />,
                panelWidth: '700px',
                title: `${row.recruiter.recruiterName} Details`,
                onDismiss: () => {
                }
            });
        },
        candidates: (row: GetJobsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <JobCandidatesPanelComponent 
                    jobId={row.jobId}
                />,
                panelWidth: '700px',
                title: `${row.jobTitle} Candidates`,
                onDismiss: () => {
                }
            });
        }
    }

    const goToJobs = useCallback(
        () => {
            navigate('/app/jobs');
        },
        []
    )

    const goToRecruiters = useCallback(
        () => {
            navigate('/app/recruiters');
        },
        []
    )

    const goToCandidates = useCallback(
        () => {
            navigate('/app/candidates');
        },
        []
    )

    /** define the return statement bellow */
    return (
        <DashboardPageStyled>
            <PageTemplateComponent title="Dashboard">
                <div>
                    <div>
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <BasicSelect
                                placeholder={"Country"}
                                options={countriesRaports?.map((status: any) => ({ label: status.name, value: status.tag }))}
                                selectFilterValue={filtersRaports?.country ?? null}
                                onValueChange={onSelectValueChange}
                            /> 
                            <BasicSelect
                                placeholder={"County"}
                                options={countiesRaports?.map((status: any) => ({ label: status.name, value: status.tag }))}
                                selectFilterValue={filtersRaports?.county ?? null}
                                onValueChange={onSecondSelectValueChange}
                            /> 
                        </div>
                        {
                            isLoading ? 
                                <LinearProgress style={{borderRadius: '10px'}} />
                            :
                            <div style={{display: "flex", flexWrap: 'wrap'}}>
                                <div style={{
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    flexWrap: 'wrap', 
                                    width: "100%"
                                }}>
                                    <CardNumericRaport 
                                        details={cardReportsData?.activeJobsNumber} 
                                        onClick={goToJobs}
                                    />
                                    <CardNumericRaport 
                                        details={cardReportsData?.recruitersNumber} 
                                        onClick={goToRecruiters}
                                    />
                                    <CardNumericRaport 
                                        details={cardReportsData?.candidatesNumber} 
                                        onClick={goToCandidates}
                                    />
                                </div>
                            </div> 
                        }
                    </div>
                    <PageTemplateComponent title="Jobs ready to expire in 5 days">
                        <TableComponent
                            tableKey={TableTypeEnum.HomeJobsReadyToExpire} 
                            viewType="normal"
                            tableData={tableDataJobsReadyToExpire}
                            searchBarPlaceholder="Search by job title"
                            dropdownPlaceholder="Country"
                            secondDropdownPlaceholder="County" 
                            withoutSelect
                            dropdownOptions={countriesRTE?.map((status: any) => ({ label: status.name, value: status.tag }))}
                            secondDropdownOptions={countiesRTE?.map((status: any) => ({ label: status.name, value: status.tag }))}
                            customRenderer={customRenderer}
                            isLoading={jobsReadyToExpireLoading}
                            tableState={filtersTableReadyToExpire}
                            setTableState={setFiltersReadyToExpire}
                            customTableCellAction={customTableCellAction}
                        />  
                    </PageTemplateComponent>  
                    <PageTemplateComponent title="Jobs with reviewers in pending">
                        <TableComponent
                            tableKey={TableTypeEnum.HomeReviewersWaiting} 
                            viewType="normal"
                            tableData={tableDataJobsReviewersWaiting}
                            searchBarPlaceholder="Search by job title"
                            dropdownPlaceholder="Country"
                            secondDropdownPlaceholder="County" 
                            withoutSelect
                            dropdownOptions={countriesRW?.map((status: any) => ({ label: status.name, value: status.tag }))}
                            secondDropdownOptions={countiesRW?.map((status: any) => ({ label: status.name, value: status.tag }))}
                            customRenderer={customRendereraJobsReviewersWaiting}
                            isLoading={jobsReviwersWaitingLoading}
                            tableState={filtersTableReviwersWaiting}
                            setTableState={setFiltersReviwersWaiting}
                            customTableCellAction={customTableCellActionJobsReviewersWaiting}
                        />  
                    </PageTemplateComponent>  
                    <PageTemplateComponent title="Jobs with no candidates">
                        <TableComponent
                            tableKey={TableTypeEnum.HomeNoCandidates} 
                            viewType="normal"
                            tableData={tableDataJobsNoCandidates}
                            searchBarPlaceholder="Search by job title"
                            dropdownPlaceholder="Country"
                            secondDropdownPlaceholder="County" 
                            withoutSelect
                            dropdownOptions={countriesNC?.map((status: any) => ({ label: status.name, value: status.tag }))}
                            secondDropdownOptions={countiesNC?.map((status: any) => ({ label: status.name, value: status.tag }))}
                            customRenderer={customRenderer}
                            isLoading={jobsNoCandidatesLoading}
                            tableState={filtersTableNoCandidates}
                            setTableState={setFiltersNoCandidates}
                            customTableCellAction={customTableCellAction}
                        />  
                    </PageTemplateComponent>  
                </div>
            </PageTemplateComponent>    
        </DashboardPageStyled>
    )

})

export default DashboardPage;