import { observer } from "mobx-react-lite";
import { FormTextInputStyled, StyledTextarea } from "./form-text-input.component.styled";
import { useStore } from "../../hooks/store.hook";
import TextFieldComponent from "../text-field/text-field.component";
import { TextareaAutosize } from "@mui/material";
import { useState } from "react";

export type FormTextInputComponentPropsType = {
    questionTag: string,
    questionPlaceholder: string;
    value: any,
    onChangeValue: (value: any) => void,
    isRequired?: boolean,
    error?: boolean,
    minRows?: string | number,
    type?: any,
    disabled?: boolean
}

const FormTextInputComponent = observer(({
    onChangeValue,
    questionPlaceholder,
    questionTag,
    value,
    isRequired,
    error,
    minRows,
    type,
    disabled
}: FormTextInputComponentPropsType) => {

    const globalStore = useStore("globalStore");

    return (
        <FormTextInputStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]} {isRequired ? "*" : ""}
            </div>
            {
                !minRows ?
                <div
                    style={{
                        position: "relative"
                    }}
                >
                    <TextFieldComponent
                        label={globalStore.translationsText[questionPlaceholder]}
                        variant="outlined"
                        fullWidth={true}
                        value={value}
                        error={error}
                        onTextChange={e => onChangeValue(e)}
                        type={type}
                        disabled={disabled}
                    />
                </div>
                    :
                    <StyledTextarea
                        minRows={minRows}
                        placeholder={globalStore.translationsText[questionPlaceholder]}
                        value={value}
                        onChange={e => onChangeValue(e.target.value)}
                        disabled={disabled}
                    />
            }
        </FormTextInputStyled>
    )
})

export default FormTextInputComponent;