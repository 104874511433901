import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { RecruiterPerksDto, UpgradeOffersDto, createPaymentIntent, getRecruiterPerks, getStripePublishKey, getUpgradeOffers } from "../../../../../../utils/requests";
import { PlanTypeEnum } from "../../../../../../enums/plan-type.enum";
import { ProductsEnum } from "../../../../../../enums/products.enum";
import { useStore } from "../../../../../../hooks/store.hook";
import checkmarkSvg from "../../../../../../assets/checkmark.svg";
import closeSvg from "../../../../../../assets/Close.svg";
import UpgradeOffersComponentStyled from "./uprage-offers.component.styled";
import ButtonComponent from "../../../../../../components/button/button.component";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../checkout-form/checkout-form.component";
import { isEmpty } from "lodash";
import { LinearProgress } from "@mui/material";
import toast from "react-hot-toast";

const UprageOffersComponent = observer(() => {

    const [loadingOffers, setIsLoadingOffers] = useState<boolean>(true)
    const [upgradeOffers, setUpgradeOffers] = useState<UpgradeOffersDto[]>([])
    const [selectedOffer, setSelectedOffer] = useState<UpgradeOffersDto>()
    const [selectProduct, setSelectProduct] = useState<ProductsEnum>();
    const [recruiterPerks, setRecruiterPerks] = useState<RecruiterPerksDto>();
    const globalStore = useStore("globalStore");
    const [paymentIsIntenting, setPaymentIsIntenting] = useState<boolean>(true);
    const [paymentClientSecret, setPaymentClientSecret] = useState()
    const [stripePromise, setStripePromise] = useState<any>()

    const [loadingTransaction, setLoadingTransaction] = useState<boolean>(false);
    const [isFreeTrial, setIsFreeTrial] = useState<boolean>(true);

    const getData = async () => {
        setIsLoadingOffers(() => true);

        let upgradeOffers = await getUpgradeOffers();
        let recruiterPerks = await getRecruiterPerks();

        setUpgradeOffers(() => upgradeOffers);
        let filterOffers = upgradeOffers.filter(f => f.disable);
        setSelectedOffer(() => filterOffers[filterOffers.length-1]);
        setRecruiterPerks(() => recruiterPerks);

        setIsLoadingOffers(() => false);
    }

    const getStripePK = async () => {
        setIsLoadingOffers(() => true);
        let publishKey = await getStripePublishKey();
        const stripePromise = await loadStripe(publishKey);
        setStripePromise(() => stripePromise);
        setIsLoadingOffers(() => false);
    }

    useEffect(
        () => {
            getStripePK();
            getData();
        },
        []
    )

    useEffect(
        () => {
            getData()
        },
        [loadingTransaction]
    )

    const onSelectOffer = async (offer: UpgradeOffersDto) => {
        if(offer.disable) return;
    
        if(offer.planTypeId == PlanTypeEnum.Personal)
          setSelectProduct(() => ProductsEnum.PersonalPlan) 
        else if(offer.planTypeId == PlanTypeEnum.Professional)
          setSelectProduct(() => ProductsEnum.ProfessionalPlan)
    
        setSelectedOffer(() => offer);
    }

    const upgradeSubscription =  async () => {

        setPaymentIsIntenting(() => true);

        let body = {
            price: selectedOffer?.payPlan.planPrice,
            currency: globalStore.translationsText[selectedOffer?.payPlan.planTypeCurrency ?? ""]
        }

        try {
            
            let response = await createPaymentIntent(body);
            setPaymentClientSecret(response)
            setPaymentIsIntenting(() => true);
        } catch (error: any) {
            toast.error(error)
            setPaymentIsIntenting(() => true);
        }
                
      }

    return (
        <UpgradeOffersComponentStyled>
            {
                loadingTransaction ? 
                    <LinearProgress />
                    :
                    <>
                        {
                            !loadingOffers ? 
                                <div className="offers">

                                    {
                                        isEmpty(paymentClientSecret) ?

                                        upgradeOffers.map((offer, index) => (
                                            <div key={`${index}`}>
                                                <div 
                                                    className="offer-card"
                                                    style={{
                                                        border: selectedOffer?.subscriptionPlanId === offer.subscriptionPlanId ? '3px solid #09B59D' : '1px solid #D8D8D8',
                                                        opacity: offer.disable ? '0.5' : ''
                                                    }}
                                                    onClick={() => onSelectOffer(offer)}
                                                >
                                                    <div className="offer-card-header">
                                                        <div className="plan-type"> { globalStore.translationsText[offer.planType] }</div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <div className="plan-price">
                                                                {
                                                                    offer.payPlan.planPrice ?
                                                                        <div>
                                                                            { offer.payPlan.planPrice }
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                <div
                                                                    style={{
                                                                        marginLeft: "0.25rem"
                                                                    }}
                                                                >
                                                                    { globalStore.translationsText[offer.payPlan.planTypeCurrency] }
                                                                </div>
                                                            </div>
                                                            <div className="free-trial">{ globalStore.translationsText['general.plantype.free-trial'] }</div>
                                                        </div>
                                                    </div>
                                                    <div className="candidate-number">
                                                        <div className="candidate-number-text">
                                                            <div className="icon-style">
                                                                <div className="checked">
                                                                    <img src={checkmarkSvg} alt="checked" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {
                                                                    offer.jobsNumber && offer.jobsNumber > 1 ?
                                                                        <span>{ offer.jobsNumber }</span>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    offer.jobsNumber && offer.jobsNumber === 1 ?
                                                                        <span>{ globalStore.translationsText['recruiter.general.job-upgrade.jobs-number.one'] }</span>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    offer.jobsNumber && offer.jobsNumber > 1 ?
                                                                        <span
                                                                            style={{
                                                                                marginLeft: "0.25rem"
                                                                            }}
                                                                        >
                                                                            { globalStore.translationsText['recruiter.general.job-upgrade.jobs-number.more'] }
                                                                        </span>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    offer.jobsNumber === null ?
                                                                        <span>{ globalStore.translationsText['recruiter.general.job-upgrade.jobs-number.unlimited'] }</span>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="candidate-number">
                                                        {
                                                            offer.candidatesNumber ?
                                                                <div className="candidate-number-text">
                                                                    <div className="icon-style">
                                                                        <div className="checked">
                                                                            <img src={checkmarkSvg} alt="checked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.canidates-number1']}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                marginLeft: "0.25rem",
                                                                                marginRight: "0.25rem"
                                                                            }}
                                                                        >
                                                                            { offer.candidatesNumber }
                                                                        </span>
                                                                        <span>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.canidates-number2']}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            !offer.candidatesNumber ?
                                                                <div className="candidate-number-text">
                                                                    <div className="icon-style">
                                                                        <div className="checked">
                                                                            <img src={checkmarkSvg} alt="checked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.canidates-number.unlimited1']}
                                                                        </div>
                                                                        <div>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.canidates-number.unlimited2']}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    <div className="search-candidates">
                                                        {
                                                            offer.searchFunctionalityActive ?
                                                                <div className="search-candidates-text">
                                                                    <div className="icon-style">
                                                                        <div className="checked">
                                                                            <img src={checkmarkSvg} alt="checked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {globalStore.translationsText['recruiter.general.job-upgrade.search-candidates.available']}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>           
                                                        }
                                                        {
                                                            !offer.searchFunctionalityActive ?
                                                                <div className="search-candidates-text">
                                                                    <div className="icon-style">
                                                                        <div className="marked">
                                                                            <img src={closeSvg} alt="marked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {globalStore.translationsText['recruiter.general.job-upgrade.search-candidates.unavailable']}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    <div className="free-reviews">
                                                        {
                                                            offer.freeReviewsNumber === 0 ?
                                                                <div className="free-reviews-text">
                                                                    <div className="icon-style">
                                                                        <div className="marked">
                                                                            <img src={closeSvg}  alt="marked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {globalStore.translationsText['recruiter.general.job-upgrade.free-reviews.unavailable']}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            offer.freeReviewsNumber > 0 ?
                                                                <div className="free-reviews-text">
                                                                    <div className="icon-style">
                                                                        <div className="checked">
                                                                            <img src={checkmarkSvg} alt="checked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            { offer.freeReviewsNumber }
                                                                        </span>
                                                                        <span>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.free-reviews.available']}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    <div className="interview-storage-days">
                                                        {
                                                            offer.interviewStorageDays === 7 ?
                                                                <div className="interview-storage-days-text">
                                                                    <div className="icon-style">
                                                                        <div className="marked">
                                                                            <img src={closeSvg}  alt="marked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.interviews-storage1']}
                                                                        </span>
                                                                        <span>
                                                                            { offer.interviewStorageDays }
                                                                        </span>
                                                                        <span>
                                                                            {globalStore.translationsText['recruiter.general.job-upgrade.interviews-storage2']}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            offer.interviewStorageDays > 7 ?
                                                                <div className="interview-storage-days-text">
                                                                    <div className="icon-style">
                                                                        <div className="checked">
                                                                            <img src={checkmarkSvg} alt="checked" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            { globalStore.translationsText['recruiter.general.job-upgrade.interviews-storage1'] }
                                                                        </span>
                                                                        <span>
                                                                            { offer.interviewStorageDays }
                                                                        </span>
                                                                        <span>
                                                                            { globalStore.translationsText['recruiter.general.job-upgrade.interviews-storage2'] }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <></>
                                    }

                                    {
                                        recruiterPerks?.eligibleCountry && recruiterPerks?.planType !== PlanTypeEnum.Professional &&  isEmpty(paymentClientSecret)  ?
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end"
                                                    }}
                                                >
                                                    {/* uncomment when stripe should be active */}
                                                    
                                                    {/* <ButtonComponent 
                                                        onClick={() => upgradeSubscription()}
                                                    > 
                                                        Upgrade
                                                    </ButtonComponent> */}


                                                    {/* delete when stripe should be active */}
                                                    
                                                    <CheckoutForm 
                                                        setIsLoading={setLoadingTransaction}
                                                        selectedOffer={selectedOffer!}
                                                        resetPaymentSecret={setPaymentClientSecret}
                                                    /> 
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    {/* uncomment when stripe should be active */}

                                    {/* {
                                        !isEmpty(paymentClientSecret) && !isEmpty(stripePromise) ? 
                                            <Elements stripe={stripePromise} options={paymentClientSecret}> 
                                                <CheckoutForm 
                                                    setIsLoading={setLoadingTransaction}
                                                    selectedOffer={selectedOffer!}
                                                    resetPaymentSecret={setPaymentClientSecret}
                                                /> 
                                            </Elements>  
                                            :
                                            <></>
                                    } */} 

                                </div>
                                :
                                <></>
                        }
                    </> 
            }
        </UpgradeOffersComponentStyled>
    )
})

export default UprageOffersComponent;