import { observer } from "mobx-react-lite";
import { JobCandidatesPanelStyled } from "./job-candidates-panel.component.styled";
import { useEffect, useMemo, useState } from "react";
import { getJobsCandidates } from "../../utils/requests";
import toast from "react-hot-toast";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import PageTemplateComponent from "../page-template/page-template.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { UserInfoType } from "../../modules/main/pages/recruiters/recruiters.page";
import { PanelType } from "../../enums/panel-type.enum";
import { UserTypeEnum } from "../../enums/user-type.enum";
import UserDetailsPanelComponent from "../user-details-panel/user-details-panel.component";
import { useStore } from "../../hooks/store.hook";
import { formatDate } from "../../utils/utils";

type JobCandidatesPanelComponentPropsType = {
    jobId: number;
}

type UserInfoDto = {
    userInfoId: number;
    userName: string;
    memberSince: Date
}

type GetJobCandidatesDto = {
    numberOfCandidates: number,
    candidates: UserInfoDto[],
    numberOfPages: number
}   

const JobCandidatesPanelComponent = observer(({
    jobId
}: JobCandidatesPanelComponentPropsType) => {

    const [candidatesLoading, setCandidatesLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 20,
        selectFilterValue: null,
        secondSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });
    const [getJobCandidates, setGetJobCandidates] = useState<GetJobCandidatesDto>();
    const uiStore = useStore('uiStore');
    const globalStore = useStore("globalStore");

    useEffect(
        () => {
            setCandidatesLoading(() => true)
            getJobsCandidates({
                jobId: jobId,
                pageIndex: filtersTable.pageIndex,
                pageSize: filtersTable.pageSize
            })
            .then(data => {
                setGetJobCandidates(() => data);
                setCandidatesLoading(() => false);
            })
            .catch((error) => {
                toast.error(globalStore.translationsText[error.message]);
                setCandidatesLoading(() => false);
            })
        },
        [jobId, filtersTable.pageIndex, filtersTable.pageSize]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!getJobCandidates) return {
                data: [],
                headers: []
            };

            return {
                data: getJobCandidates.candidates,
                headers: [
                   {
                       id: 'userInfoId',
                       label: 'Candidate Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'userName',
                        label: 'Candidate Name',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'memberSince',
                        label: 'Member since',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: getJobCandidates.numberOfCandidates
            }
        },
        [getJobCandidates]
    )

    const customTableCellAction = {
        userInfoId: (row: UserInfoType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.userInfoId}
                    userType={UserTypeEnum.Candidate}
                />,
                panelWidth: '700px',
                title: `${row.userName} Details`,
                onDismiss: () => {
                }
            });
        },
        userName: (row: UserInfoType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.userInfoId}
                    userType={UserTypeEnum.Candidate}
                />,
                panelWidth: '700px',
                title: `${row.userName} Details`,
                onDismiss: () => {
                }
            });
        }
    }

    const customRenderer: CustomRendererPropsType = {
        memberSince: (row: UserInfoDto) => {
            return (
                <>
                    {formatDate(row.memberSince)}
                </>
            )
        }
    }

    return (
        <JobCandidatesPanelStyled>
             {/* <PageTemplateComponent title="Candidates"> */}
            <TableComponent
                tableKey={TableTypeEnum.Candidates} 
                viewType="panel"
                tableData={tableData}
                withoutSearchBar
                withoutSelect
                isLoading={candidatesLoading}
                tableState={filtersTable}
                setTableState={setFilters}
                customTableCellAction={customTableCellAction}
                customRenderer={customRenderer}
            />  
                {/* </PageTemplateComponent> */}
        </JobCandidatesPanelStyled>
    )
})

export default JobCandidatesPanelComponent;