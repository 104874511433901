import { observer } from "mobx-react-lite";
import { ReadonlyInputStyled } from "./readonly-input.component.styled";
import { QuestionTypeEnum } from "../../enums/question-type-enum";
import { useStore } from "../../hooks/store.hook";
import checkmark from "../../assets/checkmark.svg";

type ReadonlyInputComponentPropsType = {
    labelname?: string;
    type?: QuestionTypeEnum;
    values?: any[];
}

const ReadonlyInputComponent = observer(({
    labelname,
    type,
    values
}: ReadonlyInputComponentPropsType) => {

    const globalStore = useStore('globalStore');
    
    return (
        <ReadonlyInputStyled>
            <div className="readonlyinput-wrapper">
                <div className="labelname">
                    {
                        labelname ?
                            globalStore.translationsText[labelname]
                            :
                            ""
                    }
                </div>

                <div className="value-wrapper">
                    <div 
                        style={{
                            width: "100%"
                        }}
                    >
                        {
                            values?.map(value => {
                                if(type == QuestionTypeEnum.Radio || type == QuestionTypeEnum.Checkbox) {
                                    return (
                                        <div className="check-input">
                                            <span className="check"><img src={checkmark} /></span>
                                            <span className="check-value">
                                            {
                                                value ?
                                                    globalStore.translationsText[value] ?? value
                                                    :
                                                    ""
                                            }
                                            </span>
                                        </div>
                                    )
                                }

                                if(type == QuestionTypeEnum.Text || type == QuestionTypeEnum.Date){
                                    return (
                                        <div className='check-text-default'>
                                            {
                                                value ?
                                                    globalStore.translationsText[value] ?? value
                                                    :
                                                    ""
                                            }
                                        </div>
                                    )
                                }

                                if(type == QuestionTypeEnum.Range || type == QuestionTypeEnum.InputRange){
                                    return (
                                        <div className='check-text-default'>
                                            <span className="check"><img src={checkmark} /></span>
                                            <span>
                                                <span>
                                                    {
                                                        value.lower ?
                                                            globalStore.translationsText[value.lower]
                                                            :
                                                            ""
                                                    }
                                                </span>
                                                <span>-</span>
                                                <span>
                                                    {
                                                        value.upper ?
                                                            globalStore.translationsText[value.upper]
                                                            :
                                                            ""
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </ReadonlyInputStyled>
    )
});

export default ReadonlyInputComponent;