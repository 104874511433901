import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const JobCandidateAnswerPanelStyled = styled.div`

    .page-title {
        width: 100%;
        margin-top: 1.5rem;
        font-weight: ${fontWeights.fontWeightBold};
        text-align: left;
        font-size: ${fontSizes.fontSize5};
        line-height: 1.2rem;
        color: ${fontColors.colorMain};
        text-align: center;
        margin-bottom: 2rem;
    }

    .reviewer-style {
        font-weight: ${fontWeights.fontWeightBold};

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

`