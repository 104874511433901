import styled from "styled-components";
import { fontColors, fontSizes, fontWeights, themeVariables } from "../../theme/variables";
import { TextareaAutosize } from "@mui/material";

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

export const FormTextInputStyled = styled.div`
    display: flex;
    flex-direction: column;

    margin-left: 1rem;
    margin-bottom: 0.5rem;

    .question-tag {
        font-weight: ${fontWeights.fontWeightBold};
        margin-bottom: 0.5rem;
    }
`

export const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `

    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${grey[900]};
    background: ${'#fff'};
    border: 1px solid ${grey[200]};
    box-shadow: 0px 2px 2px ${grey[50]};
  
    &:hover {
      border-color: ${themeVariables.colors.primary};
    }
  
    &:focus {
        color: ${() => themeVariables.colors.primary};
        border-color: ${themeVariables.colors.primary};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  )