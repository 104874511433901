import { observer } from "mobx-react-lite";
import { ResetPasswordPanelStyled } from "./reset-password-panel.component.styled";
import FormTextInputComponent from "../form-text-input/form-text-input.component";
import { useState } from "react";
import { isEmpty } from "lodash";
import ButtonComponent from "../button/button.component";
import toast from "react-hot-toast";
import { resetPassword } from "../../utils/requests";
import { useStore } from "../../hooks/store.hook";

type ResetPasswordPanelPropsType = {
    email: string,
    userInfoId: number
}

const ResetPasswordPanel = observer(({
    email,
    userInfoId
}: ResetPasswordPanelPropsType) => {

    const uiStore = useStore('uiStore');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<any>({
        fields: {
            newPassword: {
                value: "",
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    })
    const globalStore = useStore("globalStore");

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const resetPassowrdAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            toast.error("The details are invalid!")
            return;
        };

        let body = {
            userInfoId: userInfoId,
            email: email,
            newPassword: formGroup.fields.newPassword?.value
        }

        try {
            await resetPassword(body);
            setIsSaving(() => false);
            toast.success("The details were saved!")
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message])
        }
    } 

    return (
        <ResetPasswordPanelStyled>
            <FormTextInputComponent
                questionTag="recruiter.login.label.email"    
                onChangeValue={(value: any) => {}}
                value={email} 
                isRequired    
                questionPlaceholder="recruiter.login.label.email"
                disabled
            />
            <FormTextInputComponent
                questionTag="recruiter.register.input.newPassword"    
                onChangeValue={(value: any) => updateState('newPassword', value)}
                value={formGroup.fields.newPassword?.value} 
                isRequired={formGroup.fields.newPassword?.noValidation === false}     
                questionPlaceholder="recruiter.register.input.newPassword"     
                error={formGroup?.shouldDisplayError && !formGroup.fields.newPassword?.isValid} 
                type={"password"}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <ButtonComponent 
                    onClick={resetPassowrdAction} 
                    isLoading={isSaving}
                    sx={{
                        width: "5rem"
                    }}
                >
                    Save
                </ButtonComponent>
            </div>
        </ResetPasswordPanelStyled>
    )
})

export default ResetPasswordPanel;