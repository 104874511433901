import { observer } from "mobx-react-lite";
import { CheckboxMultiSelectStyled } from "./checkbox-multiselect.component.styled";
import { useStore } from "../../hooks/store.hook";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

export type CheckboxMultiSelectComponentPropsType = {
    answers: any[],
    questionTag: string,
    selectedValues: any[],
    onChangeSelectedValues: (value: any) => void,
    isRequired?: boolean,
    noSelectedAll?: boolean
}

const CheckboxMultiSelectComponent = observer(({
    answers,
    onChangeSelectedValues,
    questionTag,
    selectedValues,
    isRequired,
    noSelectedAll
}: CheckboxMultiSelectComponentPropsType) => {

    const globalStore = useStore("globalStore");

    const [isSelectedAll, setIsSelected] = useState<boolean>(false);

    useEffect(
        () => {
            if(selectedValues.length === answers.length)
                setIsSelected(() => true);
            else setIsSelected(() => false);
        },
        [selectedValues]
    )

    const handleChangeValues = (answer: any) => {
        if(!!selectedValues.find(f => f.tag === answer.tag)){
            onChangeSelectedValues([
                ...selectedValues.filter(f => f.tag !== answer.tag)
            ])
        } else {
            onChangeSelectedValues([
                ...selectedValues,
                answer
            ])
        }
    }

    const selectAllAction = () => {
        if(isSelectedAll)
            onChangeSelectedValues([])
        else 
            onChangeSelectedValues([
                ...answers
            ])
    }

    return (
        <CheckboxMultiSelectStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]} {isRequired ? "*" : ""}
            </div>
            {
                !noSelectedAll ?
                    <FormControlLabel
                        control={<Checkbox
                            checked={isSelectedAll}
                            onChange={selectAllAction}
                        />}
                        label={
                            isSelectedAll ? 
                                globalStore.translationsText["general.checkbox.deselect-all"]
                                :
                                globalStore.translationsText["general.checkbox.select-all"]
                        }
                    />
                    :
                    <></>
            }
            {
                answers.map(answer => (
                    <FormControlLabel
                        control={<Checkbox
                            checked={!!selectedValues.find(f => f.tag === answer.tag)}
                            onChange={e => handleChangeValues(answer)}
                        />}
                        label={isEmpty(answer.languageTag) ? globalStore.translationsText[answer.tag] : globalStore.translationsText[answer.languageTag]}
                    />
                ))
            }
        </CheckboxMultiSelectStyled>
    )
})

export default CheckboxMultiSelectComponent;