import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useStore } from "../../../../hooks/store.hook";
import JobsPageStyled from "./jobs.page.styled";
import { extendJob, getAllJobs, getCounties, getCountries } from "../../../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import moment from "moment";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { LinearProgress } from "@mui/material";
import { GetJobType, GetJobsType } from "../dashboard/dashboard.page";
import JobDetailsPanel from "../../../../components/job-details-panel/job-details-panel.component";
import { PanelType } from "../../../../enums/panel-type.enum";
import UserDetailsPanelComponent from "../../../../components/user-details-panel/user-details-panel.component";
import { UserTypeEnum } from "../../../../enums/user-type.enum";
import JobCandidatesPanelComponent from "../../../../components/job-candidates-panel/job-candidates-panel.component";
import { useDownloadExcel } from 'react-export-table-to-excel';
import ButtonComponent from "../../../../components/button/button.component";
import { PlanTypeEnum } from "../../../../enums/plan-type.enum";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import toast from "react-hot-toast";

const JobsPage = () => {

    // Jobs
    const [jobs, setJobs] = useState<GetJobType>();
    const [jobsLoading, setJobsLoading] = useState<boolean>(true);
    const [initialCountry, setInitialCountry] = useState<any>();
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const [countries, setCountries] = useState<any>();
    const [counties, setCounties] = useState<any>();
    const uiStore = useStore('uiStore');
    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Jobs table',
        sheet: 'Jobs'
    })
    const [jobIsExtended, setJobIsExtended] = useState<any>(false);

    useEffect(
        () => {
            async function getData() {
                setJobsLoading(() => true);
                try {
                    
                    let countries = await getCountries();
                    setCountries(() => countries);

                    // for raports
                    let initialCountry = countries.find(f => f.tag === "api.general.country3")
                    setInitialCountry(() => initialCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    let counties = await getCounties(initialCountry?.id);
                    setCounties(() => counties);

                    setJobsLoading(() => false);
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            getData();
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsLoading(() => true)
                    let fetchJobs = await getAllJobs({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        country:filtersTable.selectFilterValue?.value,
                        county:filtersTable.secondSelectFilterValue?.value,
                        search:filtersTable.searchText
                    });
                    setJobs(() => fetchJobs)
                    setJobsLoading(() => false)
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            if(jobIsExtended){
                setJobIsExtended(() => false)
            }

            if(!filtersTable) return;
            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) return;
            if(!filtersTable?.selectFilterValue?.value){
                setJobsLoading(() => false);
                return;
            }
            getData();
            
        },
        [filtersTable, initialCountry, jobIsExtended]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsLoading(() => true)
                    let newCountry = countries.find((f: { tag: string; }) => f.tag === filtersTable.selectFilterValue?.value)
                    setInitialCountry(() => newCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCounties(() => counties);
                    setJobsLoading(() => false)
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) 
                getData()
        },
        [filtersTable, initialCountry]
    )

    const tableDataJobs = useMemo(
        (): TableDataPropsType => {
            if(!jobs) return {
                data: [],
                headers: []
            }

            return {
                data: jobs.jobs,
                headers: [
                   {
                       id: 'jobId',
                       label: 'Job Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'jobTitle',
                        label: 'Job Title',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },  
                    {
                        id: 'pricePlan',
                        label: 'Price Plan',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },  
                    {
                        id: 'candidates',
                        label: 'Candidates',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },  
                   {
                        id: 'deadline',
                        label: 'Deadline',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: "extendJob",
                        label: "Extend Job",
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: jobs.numberOfJobs
            }
        },
        [jobs]
    )

    const extendJobDeadling = async (row: any) => {

        const response = await uiStore.openDialog({
            title: `You are extending the deadline`,
            message: `Are you sure you want to extend the deadline for this job?`,
        })    
        if(response.value === DialogResponseTypeEnum.Confirm) {
            
            try {
                let params = {
                    jobId: row.jobId,
                    recruiterId: row.recruiter.recruiterId
                }
        
                setJobsLoading(() => true);
                setJobIsExtended(() => true);
                extendJob(params)
                .then(() => setJobsLoading(() => false))
                toast.success('The job was extended!');
            }
            catch (error: any) {
                toast.error(`${error.message}`)
            }
        }

        
    }

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                recruiter: (row: any) => {
                    return (
                        <>
                            <div>{row.recruiter.recruiterName}</div>
                        </>
                    )
                },
                candidates: (row: any) => {
                    return (
                        <>
                            <div>{row.jobCandidates.length}</div>
                        </>
                    )
                },
                deadline: (row: any) => {
                    let remaining = moment.duration(moment(new Date(row.candidateDeadline)).diff(moment(new Date)));
        
                    if(remaining.asMinutes() <= 0){
                        return (
                          <div>The pending session has closed.</div>
                        )
                    }
        
                    if (remaining.asDays() < 1) {
                        if (remaining.asHours() < 1) {
                          return (
                            <div>{remaining.minutes().toString()} minutes</div>
                          )
                        } else {
                        return (
                            <div>{remaining.hours().toString()} hours and {remaining.minutes().toString()} minutes</div>
                          )
                        }
                      } else {
                        return (
                            <div>{Math.floor(remaining.asDays()).toString()} days and {remaining.hours().toString()} hours</div>
                          )
                      }
                },
                pricePlan: (row: any) => {
                    return (
                        <>
                            <div>{row.pricePlan}</div>
                        </>
                    )
                },
                extendJob: (row: any) => {
                    return (
                        <>
                        {
                            row.hasExpired && row.pricePlan !== "Free" ?
                                // <div>The job has expired</div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <ButtonComponent
                                        onClick={() => extendJobDeadling(row)}
                                    >
                                        Extend job
                                    </ButtonComponent>
                                </div>
                                :
                                <div>This option is not available for this job!</div>
                        }
                        </>
                    )
                }
            }
        },
        [jobs]
    )

    //open job details panel
    const openJobDetails = useCallback(
        (jobId: number, jobTitle: string) => {
            uiStore.openPanel({
                key: PanelType.JobDetails,
                component: <JobDetailsPanel 
                    jobId={jobId}
                />,
                panelWidth: '700px',
                title: `Job Details - ${jobTitle}`,
                onDismiss: () => {
                }
            });
        },
        []
    )

    const customTableCellAction = {
        jobId: (row: GetJobsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        jobTitle: (row: GetJobsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        recruiter: (row: GetJobsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.recruiter.recruiterId}
                    userType={UserTypeEnum.Recruiter}
                />,
                panelWidth: '700px',
                title: `${row.recruiter.recruiterName} Details`,
                onDismiss: () => {
                }
            });
        },
        candidates: (row: GetJobsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <JobCandidatesPanelComponent 
                    jobId={row.jobId}
                />,
                panelWidth: '700px',
                title: `${row.jobTitle} Candidates`,
                onDismiss: () => {
                }
            });
        }
    }

    /** define the state bellow */
    return (
        <JobsPageStyled>
            <PageTemplateComponent 
                title="Jobs"
                buttons={[
                    {
                        fOnClick: () => {
                            onDownload()
                        },
                        text: "Export"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllJobs} 
                    viewType="normal"
                    tableData={tableDataJobs}
                    searchBarPlaceholder="Search by job title"
                    dropdownPlaceholder="Country"
                    secondDropdownPlaceholder="County" 
                    withoutSelect
                    dropdownOptions={countries?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    secondDropdownOptions={counties?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    customRenderer={customRenderer}
                    isLoading={jobsLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    customTableCellAction={customTableCellAction}
                    tableRef={tableRef}
                />  
            </PageTemplateComponent>
        </JobsPageStyled>
    )

}

export default JobsPage;