import React, { useCallback, useEffect, useState } from "react";
import LoginPageStyled from "./login.page.styled";
import ButtonComponent from "../../../../components/button/button.component";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import { useNavigate } from "react-router-dom";
import FormElemWrapperComponent from "../../../../components/form-elem-wrapper/form-elem-wrapper.component";
import { postRequest } from "../../../../utils/http-requests";
import { StateFieldType } from "../../../../types/form.types";
import SuccessMarkContainerComponent from "../../../../components/success-mark-container/success-mark-container.component";
import { StorageEnum } from "../../../../enums/storage.enum";
import { useStore } from "../../../../hooks/store.hook";
import toast from "react-hot-toast";
import { UserType } from "../../../../types/user.type";

type StateType = {
    fields: {
        email: StateFieldType<string>;
        password: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

const LoginPage = () => {

    const globalStore = useStore("globalStore");
    /** inject the user store into the component */
    const userStore = useStore('userStore');

    /** inject navigator to navigate to other routes */
    const navigate = useNavigate();

    const [state, setState] = useState<StateType>({
        fields: {
            email: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o adresa de e-mail valida'
            },
            password: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o parola'
            }
        },
        shouldDisplayError: false
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(
        () => {

            var accountDisabledError = localStorage.getItem(StorageEnum.AccountDisabledMessage);
            if (accountDisabledError !== null)
                toast.error(() => accountDisabledError ?? '');
            localStorage.removeItem(StorageEnum.AccountDisabledMessage);
            
        },
        []
    )

    const onForgotPasswordClick = useCallback(
        () => {
            navigate('/auth/forgot-password');
        },
        [navigate]
    )

    const onLoginClick = useCallback(
        async () => {
            setState(() => ({
                ...state,
                shouldDisplayError: true
            }));

            if (!state.fields.email.isValid || !state.fields.password.isValid) return;

            try {
                setIsLoading(() => true);
                var {data} = await postRequest({ url: '/admin/login', body: {
                        email: state.fields.email.value,
                        password: state.fields.password.value
                    }
                });

                localStorage.setItem(StorageEnum.Token, data.access_token);
                
                userStore.setUser({
                    id: data.id,
                    email: data.name,
                    fullName: data.name,
                    roles: data.permissions
                });

                setIsSuccess(() => true);

            } catch(error: any) {
                toast.error(globalStore.translationsText[error.message]);
            } finally {
                setIsLoading(() => false);
            }
        },
        [state, userStore]
    )

    const updateState = useCallback(
        (field: 'email' | 'password', newValue: string) => {
            setState(() => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: {
                        ...state.fields[field],
                        isValid: state.fields[field].validator ? (state.fields[field].validator as any)(newValue) : !!newValue,
                        value: newValue
                    }
                }
            }))
        },
        [state]
    )

    const onAnimationEnd = useCallback(
        () => {
            navigate('/app/dashboard')
        },
        [navigate]
    );

    const loginOnEnter = (e: any) => {
        if(e.key === 'Enter')
            onLoginClick()
    }

    /** define the return statement bellow */
    return (
        <LoginPageStyled>
            {
                isSuccess ? (

                    <>
                        <SuccessMarkContainerComponent
                            onAnimationEnd={onAnimationEnd}
                        />
                        
                    </>
                ) :
                <>
                    <div className="page-header">
                        <div className="title">
                            <span>Salut!</span>
                        </div>
                        <div className="subtitle">Autentifica-te in platforma</div>
                    </div>

                    <div className="input-elements">
                        <FormElemWrapperComponent>
                            <TextFieldComponent 
                                label="Adresa de email" 
                                variant="outlined" 
                                fullWidth={true}
                                value={state.fields.email.value}
                                error={state.shouldDisplayError && !state.fields.email.isValid}
                                helperText={state.shouldDisplayError && !state.fields.email.isValid && state.fields.email.errorMessage}
                                onTextChange={e => updateState('email', e)}
                                onKeyDown={(e) => loginOnEnter(e)}
                            />
                        </FormElemWrapperComponent>

                        <FormElemWrapperComponent>
                            <TextFieldComponent
                                type='password' 
                                fullWidth={true}
                                label="Parola" 
                                value={state.fields.password.value}
                                error={state.shouldDisplayError && !state.fields.password.isValid}
                                helperText={state.shouldDisplayError && !state.fields.password.isValid && state.fields.password.errorMessage}
                                variant="outlined"
                                onTextChange={e => updateState('password', e)}
                                onKeyDown={(e) => loginOnEnter(e)}
                            />
                        </FormElemWrapperComponent>

                        {/* <FormElemWrapperComponent>
                            <ButtonComponent variant="text" onClick={onForgotPasswordClick}>
                                Ai uitat parola?
                            </ButtonComponent>
                        </FormElemWrapperComponent> */}
                    </div>

                    <div className="bottom-area">
                        <FormElemWrapperComponent>
                            <ButtonComponent fullWidth={true} onClick={onLoginClick}  isLoading={isLoading}>
                                Autentificare
                            </ButtonComponent>
                        </FormElemWrapperComponent>
                    </div>
                </>
            }

            
            
        </LoginPageStyled>
    )

}

export default LoginPage;