import { observer } from "mobx-react-lite";
import { JobDetailsPanelStyled } from "./job-details-panel.component.styled";
import FormSectionComponent from "../form-section/form-section.component";
import { useEffect, useState } from "react";
import { DictionaryDto, getJobSummary, getStepQuestions } from "../../utils/requests";
import { constructQuestionStepDto } from "../../utils/questions.provider";
import { AnswerDto, CandidateAnswerDto, StepDto } from "../../types/question";
import { CreateJobDto, JobQuestionDto } from "../../types/job";
import { QuestionTypeEnum } from "../../enums/question-type-enum";
import { formatDate, formatDateString } from "../../utils/utils";
import { useStore } from "../../hooks/store.hook";
import toast from "react-hot-toast";
import ReadonlyInputComponent from "../readonly-input/readonly-input.component";
import defaultProfileImage from "../../assets/profile/default-profile.jpg";
import { LinearProgress } from "@mui/material";
import { isEmpty } from "lodash";

export type JobDetailsPanelPropsType = {
    jobId: number;
}

const JobDetailsPanel = observer(({
    jobId
}: JobDetailsPanelPropsType) => {

    const globalStore = useStore('globalStore');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [jobDetails, setJobDetails] = useState<CreateJobDto | null>(null)
    const [answeredQuestions, setAnsweredQuestions] = useState<{
        questionsId: number;
        answers: CandidateAnswerDto[];
      }[]>([])
    const [questionSteps, setQuestionSteps] = useState<StepDto[]>([])

    const constructData = async () => {
    
        try {
            setIsLoading(() => true);

            let response = await getJobSummary(jobId);
            let answeredQuestions = await constructQuestionStepDto(response.jobAnswers);
            let questionSteps = await getStepQuestions(5);

            setJobDetails(() => response);
            setAnsweredQuestions(() => answeredQuestions);
            setQuestionSteps(() => questionSteps);
            setIsLoading(() => false);
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message])
        }
        
    }

    useEffect(
        () => {
            constructData()
        },
        []
    )

    const getInputValues = (questionId: number, questionType?: any) => {
        //take completed answered 
        let question = answeredQuestions.find(f => f.questionsId == questionId)
        
        if(!question) return []
    
        let answersIds = question.answers.map(m => m.answerId);
        
        let answersQuestions = answeredQuestions.find(f => f.questionsId == questionId)
    
        // take answers from question uncompleteed
        let answersFromQuestionSteps = questionSteps
                                          .map(m => m.questions
                                          .find(f => f.id == answersQuestions?.questionsId))
                                          .find(f => f)
        //filter answers form answersFromQuestionSteps and the we completed with answers from answers
        let filterAnswers = answersFromQuestionSteps?.answers.filter(f => answersIds.indexOf(f.id) >= 0)
        //complete answers
        let answers: AnswerDto[] | undefined = filterAnswers?.map( m => {
          
          let answer = answersQuestions?.answers.find(f => f.answerId == m.id)
      
          return {
            id: m.id,
            order: m.order,
            max: answer ? answer.max : null,
            min: answer ? answer.min : null,
            placeholder: m.placeholder,
            placeholderTag: m.placeholderTag,
            tag: m.tag && m.tag?.length > 0 ? m.tag : m.placeholderTag,
            text: answer ? answer.text : ''
          }
        })
    
        if (!answers) return [];
    
        if (Array.isArray(answers)) {
          return answers.map(obj => {
            if(questionType == QuestionTypeEnum.Date)
              return formatDate(new Date(obj.text ?? ""));
            if(questionType == QuestionTypeEnum.Text) {
              return obj.text;
            }
            if(questionType == QuestionTypeEnum.Range){
              return `${obj.min} - ${obj.max}`
            }
            if(questionType == QuestionTypeEnum.InputRange){
              return `${obj.min} - ${obj.max}`
            }
    
            return globalStore.translationsText[(obj.tag as string)];
          })
        }
      }
    
      const getTagListFromDictionary = (list: DictionaryDto[]): string[] => {
        return list?.map(obj => { return obj.name });
      }
    
      const getQuestions = (list: JobQuestionDto[]): string[] => {
        return list.map(m => m.question)
      }
    
      const getDate = (date?: Date) => {
        return formatDate(date ?? new Date());
      }

    return (
        <JobDetailsPanelStyled>
            {
                isLoading ?
                    <LinearProgress style={{borderRadius: '10px'}} />
                    :
                    <div className="profile-summary-container">
                        <div className="inner-content">
                            <div className="summary-content">
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step12.title"]}`}
                                    closedByDefault
                                >
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.summary.job-title.label"
                                        values={[jobDetails?.jobTitle]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.summary.brief-description.label"
                                        values={[jobDetails?.briefDescription]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.app.components.create-job.care-for.label.title"]}`}
                                    closedByDefault
                                >
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.caregiver-details.label.careFor"
                                        values={
                                            jobDetails?.careFor?.id == 2 ? 
                                                [globalStore.translationsText['recruiter.app.question.needcare.option2']] 
                                                : 
                                                [globalStore.translationsText['recruiter.app.question.needcare.option1']]
                                        }
                                        type={QuestionTypeEnum.Radio}
                                    />
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.app.components.create-job.step3.label.title"]}`}
                                    closedByDefault
                                >
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.label.firstName"
                                        values={[jobDetails?.firstName]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.label.lastName"
                                        values={[jobDetails?.lastName]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.label.gender"
                                        values={[jobDetails?.gender.name]}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.label.birthday"
                                        values={[getDate(jobDetails?.birthDate)]}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.language.label.motherLanguage"
                                        values={[jobDetails?.motherLanguage?.name]}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.language.label.additional"
                                        values={getTagListFromDictionary(jobDetails?.foreignLanguages ?? [])}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.app.components.create-job.photo.label.title"]}`}
                                    closedByDefault
                                >
                                    <div className="video-wrapper">
                                        <div className="video-thumbnail">
                                            <div className="video-thumbnail-inner" >
                                                <img 
                                                    src={isEmpty((jobDetails?.beneficiaryPicture as string)) ? defaultProfileImage : (jobDetails?.beneficiaryPicture as string)} 
                                                    alt="thumbnail"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.app.components.create-job.about-person.title"]}`}
                                    closedByDefault
                                >
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.about-person.personality.label"
                                        values={[jobDetails?.personalityDescription]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.about-person.pets.label"
                                        values={getTagListFromDictionary(jobDetails?.pets ?? [])}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.about-person.hobbies.label"
                                        values={getTagListFromDictionary(jobDetails?.hobbies ?? [])}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                </FormSectionComponent>

                                {/* dynamic questions */}

                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step1.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[0].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step2.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[1].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step3.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[2].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step4.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[3].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step5.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[4].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step6.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[5].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step7.title"]}`}
                                    closedByDefault
                                >
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.work-location.label.country"
                                        values={[jobDetails?.country?.name]}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.work-location.label.county"
                                        values={[jobDetails?.county?.name]}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.label.address"
                                        values={[jobDetails?.address]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                    <ReadonlyInputComponent 
                                        labelname="recruiter.app.components.create-job.label.postal-code"
                                        values={[jobDetails?.postalCode]}
                                        type={QuestionTypeEnum.Text}
                                    />
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step8.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[7].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step9.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[8].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(question.type as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step10.title"]}`}
                                    closedByDefault
                                >
                                    {
                                        questionSteps[9].questions.map(question => {
                                                if(question.questionTag && (getInputValues(question.id)?.length as number) > 0){
                                                    return (
                                                        <ReadonlyInputComponent 
                                                            labelname={question.questionTag}
                                                            values={getInputValues(question.id, question.type)}
                                                            type={(([4, 6].includes(question.type) ? 1 : question.type) as QuestionTypeEnum )}
                                                        />
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </FormSectionComponent>
                                <FormSectionComponent 
                                    variant="panel" 
                                    title={`${globalStore.translationsText["recruiter.step11.title"]}`}
                                    closedByDefault
                                >
                                    <ReadonlyInputComponent 
                                        labelname={`${jobDetails?.jobQuestions.length + globalStore.translationsText['recruiter.choose-question.counter-questions.title']}`}
                                        values={getQuestions(jobDetails?.jobQuestions ?? [])}
                                        type={QuestionTypeEnum.Radio}
                                    />
                                </FormSectionComponent>
                            </div>
                        </div>
                    </div>
            }

        </JobDetailsPanelStyled>
    )
})

export default JobDetailsPanel;