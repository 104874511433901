import styled from "styled-components";
import { SIDE_NAVIGATION_EXPANDED_WIDTH, SIDE_NAVIGATION_RETRACTED_WITH, TOP_NAVBAR_HEIGHT } from "../../utils/constants";

export type MainModuleStyledPropsType = {
    isExpanded: boolean
}

const MainModuleStyled = styled.div<MainModuleStyledPropsType>`
    margin: 0;
    padding: 0;
    overflow: hidden;

    .pages-wrapper {
        width: calc(100% - ${props => props.isExpanded ? SIDE_NAVIGATION_EXPANDED_WIDTH : SIDE_NAVIGATION_RETRACTED_WITH}rem);
        margin-left: ${props => props.isExpanded ? SIDE_NAVIGATION_EXPANDED_WIDTH : SIDE_NAVIGATION_RETRACTED_WITH}rem;
        transition: margin-left 250ms;
        padding-top: calc(${TOP_NAVBAR_HEIGHT}rem + 1rem);
        padding-bottom: 5rem;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
    }
    
`;

export default MainModuleStyled;