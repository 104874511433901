import { Close, FilePresent } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ContentTypeEnum } from "../../enums/content-type.enum";
import DropzoneUploadComponentStyled from "./dropzone-upload.component.styled";
import toast from 'react-hot-toast';
import { useStore } from "../../hooks/store.hook";
import { blobToBase64, saveProfilePicture } from "../../utils/requests";
import { getRequest } from "../../utils/http-requests";
import LoaderComponent from "../loader/loader.component";

export type DropzoneUploadComponentPropsType = {
    onFileChange: (file: any[]) => void,
    value: any[];
    getFile?: string,
    deleteFile: string;
    tag: string;
}

const DropzoneUploadComponent = ({
    onFileChange,
    value,
    deleteFile,
    getFile,
    tag
}: DropzoneUploadComponentPropsType) => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const globalStore = useStore("globalStore");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const inputRef = useRef(null);

    const _isExtensionValid = (file: File): boolean => {
        return [ContentTypeEnum.PNG, ContentTypeEnum.JPEG].includes(file.type as any);
    }

    const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return;
        const file = event.target.files[0];
        if (!_isExtensionValid(file)) {
            return;
        }

        setSelectedFile(() => file);

        try {
            setIsLoading(() => true);
            var reader: any = await blobToBase64(new Blob([file]))
            let varPhoto = await fetch(reader);
            let blobPhoto = await varPhoto.blob();

            const formData = new FormData();
            formData.append("image", blobPhoto);

            let resp =  await saveProfilePicture(formData);
            onFileChange([resp])
            toast.success("The file was sucessfully uploaded!")
            setIsLoading(() => false);
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message])
            setIsLoading(() => false);
        }
        
    }

    const onWrapperClick = useCallback(
        () => {
            if (!inputRef.current) return;
            (inputRef.current as any).click?.();
        },
        []
    )

    // const onRemoveSelectedFile = useCallback(
    //     () => {

    //         /** we do this so we can reupload the same file (might not work in all the major browsers. Only tested in Microsoft Edge) */
    //         if (inputRef.current) {
    //             (inputRef.current as any).value = ''
    //         }

    //         setSelectedFile(() => null);
    //         onFileChange([]);
    //     },
    //     [onFileChange]
    // )

    const fileSize = useMemo(
        (): string => {
            if (!selectedFile) return '';

            if (selectedFile.size > 1000 * 1024)
                return `${(selectedFile.size/(1000*1024)).toFixed(2)} MB`;
            else if (selectedFile.size > 1024)
                return `${(selectedFile.size/1024).toFixed(2)} KB`;
            
            return `${(selectedFile.size).toFixed(2)} bytes`;
        },
        [selectedFile]
    )

    // async function getProfileImage(): Promise<any> {
    //     const { data } = await getRequest({
    //         url: getFile
    //     })

    //     onFileChange(data)
    // }

    async function deleteProfilePicture(id: number): Promise<any> {
        try {
            setIsLoading(() => true);

            await getRequest({
                url: deleteFile,
                queryParams:{
                    id: id
                }
            })

            /** we do this so we can reupload the same file (might not work in all the major browsers. Only tested in Microsoft Edge) */
            if (inputRef.current) {
                (inputRef.current as any).value = ''
            }

            setSelectedFile(() => null);
            onFileChange([]);
            toast.success("The file was sucessfully deleted!");
            setIsLoading(() => false);
        } catch(error: any) {
            toast.error(globalStore.translationsText[error.message]);
            setIsLoading(() => false);
        }
    }

    /** define the return statement bellow */
    return (
        <DropzoneUploadComponentStyled>
            <div className="dropzone-inner" onClick={onWrapperClick}>
                {
                    isLoading ?
                        <LoaderComponent loaderSize={17} />
                        :
                        <>
                            {
                                !value.length ?
                                <>
                                    <input 
                                        type="file" 
                                        className="image-upload-input" 
                                        ref={inputRef} 
                                        onChange={onInputChange} 
                                        accept={`${ContentTypeEnum.PNG}, ${ContentTypeEnum.JPEG}`} 
                                    />

                                    <FilePresent className="message-icon" />
                                    <div className="message-title">{globalStore.translationsText[tag]}</div>
                                </>
                                :
                                <img
                                    src={value[0]?.fullPath}
                                    alt="thumbnail"
                                    width="fit-container"
                                    height="200px"
                                />
                            }
                        </>
                }
            </div>

            {
                !!value.length ?
                    <div className="file-selected-wrapper">
                        <FilePresent />
                        <div className="file-selected-content">
                            <div className="file-selected-content-title">
                                {value[0]?.fileName}
                            </div>
                            <div className="file-selected-content-subtitle">
                                {fileSize}
                            </div>
                        </div>
                        <div className="file-selected-action">
                            <IconButton onClick={() => deleteProfilePicture(value[0]?.id)}>
                                <Close />
                            </IconButton>
                        </div>
                    </div> :
                    ''
            }

        </DropzoneUploadComponentStyled>
    )

}

export default DropzoneUploadComponent;