import { observer } from "mobx-react-lite";
import { FormInputRangeStyled } from "./form-input-range.component.styled";
import { useEffect, useState } from "react";
import FormTextInputComponent from "../form-text-input/form-text-input.component";
import { useStore } from "../../hooks/store.hook";

type FormInputRangeComponentPropsType = {
    questionTag: string,
    value: {
        lower: number | null,
        upper: number | null
    };
    onChangeValue: (value: any) => void;
}

const FormInputRangeComponent = observer(({
    onChangeValue,
    value,
    questionTag
}: FormInputRangeComponentPropsType) => {

    const globalStore = useStore("globalStore");

    return (
        <FormInputRangeStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]}
            </div>
            <div className="align-inputs">
                <FormTextInputComponent
                    questionTag=""    
                    onChangeValue={(v: any) => {
                        onChangeValue({
                            ...value,
                            lower: v
                        })
                    }}
                    value={value.lower ?? 0}  
                    questionPlaceholder='recruiter.input-range.min'  
                    type="number" 
                />
                <div style={{marginLeft: "1rem"}}>-</div>
                <FormTextInputComponent
                    questionTag=""    
                    onChangeValue={(v: any) => {
                        onChangeValue({
                            ...value,
                            upper: v
                        })
                    }}
                    value={value.upper ?? 0}     
                    questionPlaceholder='recruiter.input-range.max' 
                    type="number" 
                />
            </div>
        </FormInputRangeStyled>
    )
})

export default FormInputRangeComponent;