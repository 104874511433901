export enum TableTypeEnum {
    HomeJobsReadyToExpire = "home-jobs-ready-to-expire",
    HomeReviewersWaiting = "home-reviewers-waiting",
    HomeNoCandidates = "home-no-candidates",
    AllJobs = "all-jobs",
    RequestedReview = "requested-review",
    CompletedReviews = "completed-reviews",
    Recruitrs = "recruiters",
    Candidates = "candidates",
    Reviewers = "reviewers",
    Transactions = "transactions",
    PricePlans = "price-plans",
    ReviewerPayments = "reviewer-payments"
}