import { observer } from "mobx-react-lite";
import { ChooseCandidateQuestionsComponentStyled } from "./choose-candidate-questions.component.styled";
import { useMemo, useState } from "react";
import { QuestionsTypeEnum } from "../../enums/questions-type.enum";
import { useStore } from "../../hooks/store.hook";
import AddQuestionModal from "../add-question/add-question.modal";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Close } from "@mui/icons-material";
import { isEmpty } from "lodash";

type ChooseCandidateQuestionsComponentPropsType = {
    questions: any[],
    recruiterId: number;
    setQuestions: (value: any[]) => void;
    appLanguageId: number
}

const ChooseCandidateQuestionsComponent = observer(({
    questions,
    setQuestions,
    recruiterId,
    appLanguageId
}: ChooseCandidateQuestionsComponentPropsType) => {

    const globalStore = useStore("globalStore");
    const modalStore = useStore("uiStore");

    const addQuestionsModal = () => {
        modalStore.openModal({
            component: (
                <AddQuestionModal
                    recruiterId={recruiterId}
                    questions={questions}
                    setQuestions={setQuestions}
                    appLanguageId={appLanguageId}
                 />
            ),
            title: "Add Questions"
        })
    }

    const removeQuestion = (question: any) => {
        setQuestions(questions.filter(f => f.tag !== question.tag))
    }

    return(
        <ChooseCandidateQuestionsComponentStyled>
            <div className="page-counter-questions">
                <span>{questions.length}</span>
                <span>{globalStore.translationsText['recruiter.choose-question.counter-questions.title']} </span>
                <span>{'*'}</span>
            </div>
            <div>
                {
                    questions.length > 0 ?
                        <div className="questions-wrapper">
                            {
                                questions.map((question, i) => (
                                    <div className="question">
                                        <div className="question-icon">
                                            Q{i+1}
                                        </div>
                                        <div className="question-text">
                                            {question.question}
                                        </div>
                                        <div 
                                            className="remove-button"
                                            onClick={() => removeQuestion(question)}
                                        >
                                            <Close />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <></>
                }
            </div>
            {
                questions.length < 3 ?
                    <div 
                        className="add-question-button"
                        onClick={addQuestionsModal} 
                    >
                        <AddIcon />
                        <span>{globalStore.translationsText['recruiter.pending-jobs.components.activate-job.button.addQuestion']}</span>
                    </div>
                    :
                    <></>
            }
        </ChooseCandidateQuestionsComponentStyled>
    )
})

export default ChooseCandidateQuestionsComponent;