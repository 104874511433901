import { observer } from "mobx-react-lite";
import { EditUserInfoDetailsPanelStyled } from "./edit-user-info-details-panel.component.styled";
import { DictionaryDto, PhoneNumberDto, getCounties, getCountries, getGenders, getLanguages, getUserInfoDetails, updateUserInfo } from "../../utils/requests";
import { useCallback, useEffect, useState } from "react";
import { StateFieldType } from "../../types/form.types";
import { UserTypeEnum } from "../../enums/user-type.enum";
import TextFieldComponent from "../text-field/text-field.component";
import { Grid, LinearProgress } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import toast from "react-hot-toast";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { useStore } from "../../hooks/store.hook";
import { formatDate, prefixes } from "../../utils/utils";
import ButtonComponent from "../button/button.component";
import { isEmpty } from "lodash";

export type EditUserDetailsDto = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    languageId: number;
    phoneNumber: PhoneNumberDto;
    motherLanguage: DictionaryDto;
    country: DictionaryDto;
    county: DictionaryDto;
    postalCode: string;
    streetName: string;
    birthDate: Date;
    gender: DictionaryDto;
};

type EditUserInfoDetailsPanelComponentPropsType = {
    userInfoId: number;
    userType: UserTypeEnum;
}

export type FormStateType = {
    fields: {
        email: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        phoneNumber: StateFieldType<PhoneNumberDto>;
        motherLanguage: StateFieldType<DictionaryDto>;
        country: StateFieldType<DictionaryDto>;
        county: StateFieldType<DictionaryDto>;
        postalCode: StateFieldType<string>;
        streetName: StateFieldType<string>;
        birthDate: StateFieldType<Date>;
        gender: StateFieldType<DictionaryDto>;

    };
    shouldDisplayError: boolean;
}


const EditUserInfoDetailsPanelComponent = observer(({
    userInfoId,
    userType
}: EditUserInfoDetailsPanelComponentPropsType) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formState, setFormState] = useState<FormStateType>()

    const [countries, setCountries] = useState<any>();
    const [counties, setCounties] = useState<any>();
    const [languages, setLanguages] = useState<any>();
    const [genders, setGenders] = useState<any>();
    const globalStore = useStore('globalStore');
    const uiStore = useStore('uiStore');

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormState((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(() => {
        getDropdownData()
    }, [userInfoId, userType]);

    const getDropdownData = async () => {
        try {
            setIsLoading(true)
            let countries = await getCountries();
            setCountries(() => countries);

            let genders = await getGenders();
            setGenders(() => genders);

            let languages = await getLanguages();
            setLanguages(() => languages)

            let response = await  getUserInfoDetails(userInfoId);
            let gender: DictionaryDto;

            if(userType === UserTypeEnum.Candidate){
                gender = response.candidateDetails.gender
            } else if(userType === UserTypeEnum.Recruiter) {
                gender = response.recruiterDetails.gender
            } else if(userType === UserTypeEnum.Reviewer) {
                gender = response.reviewerDetails.gender
            }

            setFormState(
                () => ({
                    fields: {
                        email: {
                            value: response?.userDetails?.email,
                            isValid: true,
                            noValidation: false
                        },
                        firstName: {
                            value: response?.userDetails?.firstName,
                            isValid: true,
                            noValidation: false
                        },
                        lastName: {
                            value: response?.userDetails?.lastName,
                            isValid: true,
                            noValidation: false
                        },
                        phoneNumber: {
                            value: response?.userDetails?.phoneNumber,
                            isValid: true
                        },
                        birthDate: {
                            value: response?.userDetails?.birthDate,
                            isValid: true,
                            noValidation: false
                        },
                        streetName: {
                            value: response?.userDetails?.streetName,
                            isValid: true
                        },
                        postalCode: {
                            value: response?.userDetails?.postalCode,
                            isValid: true
                        },
                        motherLanguage: {
                            value: response?.userDetails?.motherLanguage,
                            isValid: true,
                            noValidation: false
                        },
                        country: {
                            value: response?.userDetails?.country,
                            isValid: true,
                            noValidation: false
                        },
                        county: {
                            value: response?.userDetails?.county,
                            isValid: true,
                            noValidation: false
                        },
                        gender: {
                            value: gender,
                            isValid: true,
                            noValidation: false
                        }
                    },
                    shouldDisplayError: false
                })
            )

            setIsLoading(false)
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message]);
            setIsLoading(false)
        }
    }

    const getFilteredCounties = async () => {

        let response = await getCounties(formState?.fields.country.value.id);
        setCounties(() => response);
    }

    useEffect(
        () => {
            if(!formState?.fields.country.value?.id) return;

            getFilteredCounties()
        },
        [formState?.fields.country.value?.id]
    )

    const saveUserDetails = async () => {
        setIsSaving(() => true);
        if(!formState) return;

        const isNotValid = Object.values(formState.fields).filter(f => f.noValidation === false).some(field => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormState((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            toast.error("The details are invalid!")
            return;
        };

        let body = {
            userInfoId: userInfoId,
            email: formState.fields.email.value,
            firstName: formState.fields.firstName.value,
            lastName: formState.fields.lastName.value,
            phoneNumber: formState.fields.phoneNumber.value,
            motherLanguage: formState.fields.motherLanguage.value,
            country: formState.fields.country.value,
            county: formState.fields.county.value,
            postalCode: formState.fields.postalCode.value,
            streetName: formState.fields.streetName.value,
            birthDate: formState.fields.birthDate.value,
            gender: formState.fields.gender.value
        }

        try {
            await updateUserInfo(body);
            setIsSaving(() => false);
            toast.success("The details were saved!")
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message])
        }
    }

    return (
        <EditUserInfoDetailsPanelStyled>
            {
                isLoading ?
                    <LinearProgress style={{ borderRadius: "10px" }} />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Email address"
                                variant="outlined"
                                fullWidth={true}
                                value={formState?.fields.email.value}
                                error={formState?.shouldDisplayError && !formState.fields.email.isValid}
                                helperText={formState?.shouldDisplayError && !formState.fields.email.isValid && formState.fields.email.errorMessage}
                                onTextChange={e => updateState('email', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="First name"
                                variant="outlined"
                                fullWidth={true}
                                value={formState?.fields.firstName.value}
                                error={formState?.shouldDisplayError && !formState.fields.firstName.isValid}
                                helperText={formState?.shouldDisplayError && !formState.fields.firstName.isValid && formState.fields.firstName.errorMessage}
                                onTextChange={e => updateState('firstName', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Last name"
                                variant="outlined"
                                fullWidth={true}
                                value={formState?.fields.lastName.value}
                                error={formState?.shouldDisplayError && !formState.fields.lastName.isValid}
                                helperText={formState?.shouldDisplayError && !formState.fields.lastName.isValid && formState.fields.lastName.errorMessage}
                                onTextChange={e => updateState('lastName', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Street name"
                                variant="outlined"
                                fullWidth={true}
                                value={formState?.fields.streetName.value}
                                error={formState?.shouldDisplayError && !formState.fields.streetName.isValid}
                                helperText={formState?.shouldDisplayError && !formState.fields.streetName.isValid && formState.fields.streetName.errorMessage}
                                onTextChange={e => updateState('streetName', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Postal Code"
                                variant="outlined"
                                fullWidth={true}
                                value={formState?.fields.postalCode.value}
                                error={formState?.shouldDisplayError && !formState.fields.postalCode.isValid}
                                helperText={formState?.shouldDisplayError && !formState.fields.postalCode.isValid && formState.fields.postalCode.errorMessage}
                                onTextChange={e => updateState('postalCode', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                value={formState?.fields.birthDate.value}
                                renderInput={(params) => 
                                    <TextFieldComponent 
                                        {...params} 
                                        fullWidth={true}
                                        variant="outlined" 
                                        label="Birth Date"
                                        error={formState?.shouldDisplayError && !formState.fields.birthDate.isValid} 
                                    />}
                                onChange={newValue => updateState('birthDate', formatDate(newValue ?? new Date()))}
                                mask="__.__.____"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BasicSelect
                                placeholder={"Country"}
                                options={countries?.map((status: any) => ({ 
                                    label: status?.name, 
                                    value: status?.tag ,
                                    name: status?.name,
                                    tag: status?.tag,
                                    id: status?.id 
                                }))}
                                selectFilterValue={
                                    formState?.fields.country.value?.id ?
                                    {
                                        label: formState?.fields.country.value.name,
                                        value: formState?.fields.country.value.tag,
                                        name: formState?.fields.country.value.name,
                                        tag: formState?.fields.country.value.tag,
                                        id:  formState?.fields.country.value.id
                                    } : null
                                }
                                onValueChange={e => {
                                    updateState('country', e);
                                    updateState('county', null);
                                    setCounties(() => [])
                                }}
                                error={formState?.shouldDisplayError && !formState.fields.country.isValid}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BasicSelect
                                placeholder={"County"}
                                options={counties?.map((status: any) => ({ 
                                    label: status?.name, 
                                    value: status?.tag ,
                                    name: status?.name,
                                    tag: status?.tag,
                                    id: status?.id  
                                }))}
                                selectFilterValue={
                                    formState?.fields.county.value ?
                                    {
                                        label: formState?.fields.county.value?.name,
                                        value: formState?.fields.county.value?.tag,
                                        name: formState?.fields.county.value?.name,
                                        tag: formState?.fields.county.value?.tag,
                                        id:  formState?.fields.county.value?.id 
                                    } : null
                                }
                                onValueChange={e => updateState('county', e)}
                                error={formState?.shouldDisplayError && !formState.fields.county.isValid}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BasicSelect
                                placeholder={"Gender"}
                                options={genders?.map((status: any) => ({ 
                                    label: globalStore.translationsText[status.tag], 
                                    value: status.tag ,
                                    name: status.name,
                                    tag: status.tag,
                                    id: status.id 
                                }))}
                                selectFilterValue={
                                    formState?.fields.gender.value ?
                                    {
                                        label: globalStore.translationsText[formState?.fields.gender.value.tag ?? ""],
                                        value: formState?.fields.gender.value.tag,
                                        name: formState?.fields.gender.value.name,
                                        tag: formState?.fields.gender.value.tag,
                                        id:  formState?.fields.gender.value.id
                                    } : null
                                }
                                onValueChange={e => updateState('gender', e)}
                                error={formState?.shouldDisplayError && !formState.fields.gender.isValid}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BasicSelect
                                placeholder={"Language"}
                                options={languages?.map((status: any) => ({ 
                                    label: status.name, 
                                    value: status.tag ,
                                    name: status.name,
                                    tag: status.tag,
                                    id: status.id 
                                }))}
                                selectFilterValue={
                                    formState?.fields.motherLanguage.value ?
                                    {
                                        label: formState?.fields.motherLanguage.value.name,
                                        value: formState?.fields.motherLanguage.value.tag,
                                        name: formState?.fields.motherLanguage.value.name,
                                        tag: formState?.fields.motherLanguage.value.tag,
                                        id:  formState?.fields.motherLanguage.value.id
                                    } : null
                                }
                                onValueChange={e => updateState('motherLanguage', e)}
                                error={formState?.shouldDisplayError && !formState.fields.motherLanguage.isValid}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex"
                                }}
                            >
                                <BasicSelect
                                    placeholder={"Prefix"}
                                    options={prefixes?.map((status: any) => ({ 
                                        label: `(${(status.code as string).toLocaleUpperCase()}) ${status.prefixCode}`, 
                                        value: status.id ,
                                        prefixCode: status.prefixCode,
                                        prefixId: status.id 
                                    }))}
                                    selectFilterValue={
                                        formState?.fields.phoneNumber.value ?
                                        {
                                            label: `(${prefixes.find(f => f.prefixCode === formState?.fields.phoneNumber.value.prefixCode)?.code.toLocaleUpperCase()}) ${formState?.fields.phoneNumber.value.prefixCode}`,
                                            value: prefixes.find(f => f.prefixCode === formState?.fields.phoneNumber.value.prefixCode)?.id,
                                            prefixCode: formState?.fields.phoneNumber.value.prefixCode,
                                            prefixId:  formState?.fields.phoneNumber.value.prefixId
                                        } : null
                                    }
                                    onValueChange={(e: any) => updateState('phoneNumber', {
                                        ...formState?.fields.phoneNumber.value,
                                        prefixCode: e.prefixCode,
                                        prefixId: e.prefixId
                                    })}
                                    autcompleteStyle={{
                                        width: '12rem'
                                    }}
                                />
                                <TextFieldComponent
                                    label="Phone number"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={formState?.fields.phoneNumber.value.content}
                                    error={formState?.shouldDisplayError && !formState.fields.phoneNumber.isValid}
                                    helperText={formState?.shouldDisplayError && !formState.fields.phoneNumber.isValid && formState.fields.phoneNumber.errorMessage}
                                    onTextChange={e => updateState('phoneNumber', {
                                        ...formState?.fields.phoneNumber.value,
                                        content: e
                                    })}
                                />
                            </div>
                        </Grid>
                        
                        <Grid 
                            display="flex"
                            justifyContent="flex-end"
                            item xs={12}
                        >
                            <ButtonComponent 
                                onClick={saveUserDetails} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                            >
                                Save
                            </ButtonComponent>
                        </Grid>

                    </Grid>
            }
            
        </EditUserInfoDetailsPanelStyled>
    )
})

export default EditUserInfoDetailsPanelComponent;