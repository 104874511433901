import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/store.hook";
import { FormSingleSelectStyled } from "./form-single-select.component.styled";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";

export type FormSingleSelectComponentPropsType = {
    options: any[]
    questionTag: string,
    questionPlaceholder: string;
    value: any,
    onChangeValue: (value: any) => void,
    isRequired?: boolean,
    error?: boolean,
    loading?: boolean;
    disabled?: boolean;
}

const FormSingleSelectComponent = observer(({
    onChangeValue,
    questionPlaceholder,
    questionTag,
    value,
    isRequired,
    error,
    options,
    disabled,
    loading
}: FormSingleSelectComponentPropsType) => {

    const globalStore = useStore("globalStore");

    return (
        <FormSingleSelectStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]} {isRequired ? "*" : ""}
            </div>
            <BasicSelect
                placeholder={globalStore.translationsText[questionPlaceholder]}
                options={options}
                selectFilterValue={value}
                onValueChange={e => onChangeValue(e)}
                error={error}
                loading={loading}
                disabled={disabled}
            />
            
        </FormSingleSelectStyled>
    )
})

export default FormSingleSelectComponent;