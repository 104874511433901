import { useRoutes, Navigate } from 'react-router';
import PrivateRoute from './components/auth-guard/auth-guard.component';
import AuthModule from './modules/auth/auth.module';
import MainModule from './modules/main/main.module';
import NotFoundPage from './modules/not-found/not-found.page';

const AppRouter = () => {

    let element = useRoutes([
        {
            path: 'auth/*',
            element: <AuthModule />
        },
        {
            path: 'app/*',
            element: <PrivateRoute element={<MainModule />}/>
        },
        {
            path: '/',
            element: <Navigate to="/app" />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]);

    return element;

}

export default AppRouter;