import { Paper } from '@mui/material'
import { SecondTextStyled, StatStyled, TextStyled, TitleStyled } from './card-numeric-raport.component.styled';
import { CardNumericTypeEnum } from '../../enums/card-numeric-type.enum';
import { formatNumber } from '../../utils/utils';
import { CurrencyEnum } from '../../enums/currency.enum';
import { fontColors, themeVariables } from '../../theme/variables';

export type ShowDataCardPropsType = {
    label: string,
    value: any
}

export type CardNumericRaportPropsType = {
    type?: CardNumericTypeEnum,
    details: ShowDataCardPropsType,
    style?: any
    secondDetails?: ShowDataCardPropsType,
    price?: boolean,
    onClick?: () => void;
}

const CardNumericRaport = ({
    type,
    details,
    style,
    secondDetails,
    price,
    onClick
}: CardNumericRaportPropsType) => {
    
    return (
        <>
            {
                !secondDetails ?    
                    <Paper 
                        elevation={6} 
                        sx={{
                            display: 'flex',
                            width: '18rem', 
                            height: '6rem', 
                            padding: '2rem', 
                            borderRadius: '1rem',
                            margin: '0.5rem',
                            boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',

                            '&:hover': {
                                borderColor: onClick != undefined ? themeVariables.colors.primary : undefined,
                                borderWidth: onClick != undefined ? "2px" : undefined,
                                borderStyle: onClick != undefined ? "solid" : undefined,
                                cursor: onClick != undefined ? 'pointer' : undefined
                            }
                        }}
                        onClick={onClick}
                    >
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <TitleStyled>{details?.label}</TitleStyled>
                            <StatStyled>
                                {
                                    details?.value !== 0 ?
                                    <>
                                        {
                                            price ?
                                                `${formatNumber(details?.value)} ${ CurrencyEnum.RON }`
                                                :
                                                formatNumber(details?.value)
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            price ?
                                                `0 ${ CurrencyEnum.RON }`
                                                :
                                                `0`
                                        }
                                    </>
                                }
                            </StatStyled>
                        </div>
                    </Paper>
                :
                <Paper 
                    elevation={6} 
                    sx={{
                        display: 'flex',
                        width: '18rem', 
                        height: '6rem', 
                        padding: '2rem', 
                        borderRadius: '1rem',
                        margin: '0.5rem',
                        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                        justifyContent: 'center',

                        '&:hover': {
                            borderColor: onClick != undefined ? themeVariables.colors.primary : undefined,
                            borderWidth: onClick != undefined ? "2px" : undefined,
                            borderStyle: onClick != undefined ? "solid" : undefined,
                            cursor: onClick != undefined ? 'pointer' : undefined
                        }
                    }}
                    onClick={onClick}
                >
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <div style={{marginRight: '0.3rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                            <TextStyled>{details.label}</TextStyled>
                            <SecondTextStyled>{details.value}</SecondTextStyled>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextStyled>{secondDetails.label}</TextStyled>
                            <SecondTextStyled>
                                {
                                    secondDetails.value ?
                                        <>
                                            {
                                                price ?
                                                    `${formatNumber(secondDetails.value)} ${ CurrencyEnum.RON }`
                                                    :
                                                    formatNumber(secondDetails.value)
                                            }
                                        </>
                                        :
                                        <>
                                        {
                                            price ?
                                                `0 ${ CurrencyEnum.RON }`
                                                :
                                                `0`
                                        }
                                </>
                                        
                                }
                            </SecondTextStyled>
                        </div>
                    </div>
                </Paper>
            }
        </>
                    
    )
}

export default CardNumericRaport;