import { TextField, StandardTextFieldProps, OutlinedTextFieldProps } from "@mui/material";
import React, { useState } from "react";
import TextFieldComponentStyled from "./text-field.component.styled";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export type TextFieldComponentProps = (StandardTextFieldProps | OutlinedTextFieldProps) & {
    onTextChange?: (value: string) => void;
};

const TextFieldComponent = ({
    fullWidth,
    onTextChange,
    type,
    value,
    ...rest
}: TextFieldComponentProps) => {

    const [isNotPasswordVisible, setIsNotPasswordVisible] = useState<boolean>(false);
    const onChangeParse = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        if(onTextChange){
            let inputValue: string = e.target.value
            let minusPlace: number = -1
            let commaPlace: number = -1
            let commaString: string = ''
            let beforeCommaString: string = ''
            let separator: string[] = [',', '.']

            if(type === "number"){
                
                //check if the number is not '0,' then replace 0 with the next digit
                if(inputValue[0] === "0" && separator.indexOf(inputValue[1]) === -1)
                    inputValue = inputValue.slice(1,inputValue.length)
                
                //check if minus is the first place of the inputValue
                if(inputValue.indexOf('-') === 0)
                    minusPlace = 0

                //replace everything else but numbers, commas and dots
                inputValue = inputValue.replace(/[^0-9.,]/g, '')
                inputValue = inputValue.replace(/,/g, '.')

                //if the number starts with minus set the minus on value's first place
                if(minusPlace === 0)
                    inputValue = `-${inputValue}`
                
                //set comma's first appeareance index
                if(inputValue.indexOf('.'))
                    commaPlace = inputValue.indexOf('.')
                
                //split inputValue in two: first is a string with numbers and first comma, and the rest of inputValue 
                if(commaPlace > -1){
                    commaString = inputValue.slice(commaPlace+1, inputValue.length)
                    beforeCommaString = inputValue.slice(0, commaPlace+1)
                }
                //eliminate each comma which is extra
                commaString = commaString.replace('.', '')

                //if we don't have any comma then no comma is allowed
                if(commaPlace === -1)
                    inputValue = inputValue.replace('.', ',')

                //if we have comma then change the inputValue with the number with one comma
                if(commaPlace > -1)
                    inputValue = beforeCommaString + commaString  
            }
            onTextChange(inputValue)
        }
    }
    
    /** define the return statement bellow */
    return (
        <TextFieldComponentStyled fullWidth={fullWidth}>
            <TextField 
                fullWidth={fullWidth} 
                {...rest} 
                type={type === 'number' ? 
                    undefined 
                    : 
                    type ?
                        type === "password" && isNotPasswordVisible ?
                            "text"
                            :
                            "password"
                        :
                        type
                } 
                onChange={onChangeParse} value={type === "number" && value === "" ? '0' : value}
            />
            {
                type === "password" ?
                    <div className="password-icon-position">
                        {
                            isNotPasswordVisible ?
                                <div
                                    className="password-icon-center" 
                                    onClick={() => setIsNotPasswordVisible(() => !isNotPasswordVisible)}
                                >
                                    <VisibilityOffIcon />
                                </div>
                                :
                                <div 
                                    className="password-icon-center" 
                                    onClick={() => setIsNotPasswordVisible(() => !isNotPasswordVisible)}
                                >
                                    <RemoveRedEyeIcon />
                                </div>
                        }
                    </div>
                    :
                    <></>
            }
        </TextFieldComponentStyled>
    )

}

export default TextFieldComponent;