import styled from "styled-components";
import { themeVariables } from '../../theme/variables';

export const ImgStyled = styled.img`
  width: 4em; 
  height: 4rem;
`
export const ImagesStyled = styled.div`
    display: flex; 
    justify-content: space-between;
`

export const TitleStyled = styled.div `

    font-size: ${themeVariables.fontSizes.medium}; 
    font-weight: ${themeVariables.fontWeights.medium}; 
`

export const StatStyled = styled.div`

    font-size: ${themeVariables.fontSizes.xLarge};
    font-weight: ${themeVariables.fontWeights.bold}; 
`

export const TextStyled = styled.div`
    font-size: 1.2rem;
    text-align: center;
`

export const SecondTextStyled = styled.div`
    font-size: ${themeVariables.fontSizes.large};
    font-weight: ${themeVariables.fontWeights.bold}; 
`