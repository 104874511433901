import { Tooltip } from "@mui/material";
import { shortenString } from "../../utils/utils";

type TooltipComponentPropsType = {
    title?: string,
    description?: string,
    panelSubTitle?: string,
}

const TooltipComponent = ({
    title,
    description,
    panelSubTitle
}: TooltipComponentPropsType) => {

    return (
        <>
            {
                title ?
                    <div className="product-sku">
                        {shortenString(title, 50)}
                    </div>
                :
                ''
            }
            {
                description ?

                    <Tooltip title={description}>
                        <div className="product-name">
                            {shortenString(description, 10)}
                        </div>
                    </Tooltip>

                    :
                    ''
            }
            {
                panelSubTitle ?
                    <Tooltip title={panelSubTitle}>
                        <div className="product-name">
                            {shortenString(panelSubTitle, 40)}
                        </div>
                    </Tooltip>

                    :
                    ''
            }
        </>
    )
}

export default TooltipComponent;