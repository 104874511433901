import {Breakpoint, Container } from "@mui/material";
import React from "react";

import ButtonComponent from "../button/button.component";
import {PageTemplateComponentStyled, InlineFlexStyled, ButtonFlexStyled} from "./page-template.component.styled";

interface ButtonsPropsType {
    text: string,
    fOnClick: () => void,
}

export type PageTemplateComponentPropsType = {
    title: string;
    children?: React.ReactNode;
    buttons?: ButtonsPropsType[];
    contentMaxWidth?: Breakpoint; 
}

const PageTemplateComponent = ({
    children,
    title,
    buttons,
    contentMaxWidth
}: PageTemplateComponentPropsType) => {
    

    /** define the return statement bellow */
    return (
        <PageTemplateComponentStyled>
            
            <Container maxWidth={contentMaxWidth ?? 'lg'}>
                <InlineFlexStyled>
                    <h2>{title}</h2>
                    <ButtonFlexStyled>
                        {buttons?.map((button, index) => (
                            <div key={index} className="button-wrapper">
                                <ButtonComponent onClick={button.fOnClick}> {button.text} </ButtonComponent>
                            </div>
                        ))}
                    </ButtonFlexStyled>
                </InlineFlexStyled>
                <div className="page-template-body">
                    {children}
                </div>
                
            </Container>  
            
        </PageTemplateComponentStyled>
    )

}

export default PageTemplateComponent;