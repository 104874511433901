import { observer } from "mobx-react-lite";
import UpgradeAccountPageStyled from "./upgrade-account.page.styled";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import envVars from "../../../../config/env.config";
import CheckoutForm from "./components/checkout-form/checkout-form.component";
import { createPaymentIntent } from "../../../../utils/requests";
import UprageOffersComponent from "./components/upgrade-offers/upgrade-offers.component";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";

const UpgradeAccountPage = observer(() => {
 
    return (
        <UpgradeAccountPageStyled>
            <PageTemplateComponent
                title="Upgrade account"
            >   
                <UprageOffersComponent />
            </PageTemplateComponent>
        </UpgradeAccountPageStyled>
    )
})

export default UpgradeAccountPage;