import styled from "styled-components";
import { fontColors, fontSizes } from "../../theme/variables";

export const ReadonlyInputStyled = styled.div`
    .readonlyinput-wrapper {

        margin-left: 0.75rem;
        margin-top: 1rem;

        .labelname {
            font-size: ${fontSizes.fontSize3};
            margin-bottom: 0.8rem;
            line-height: 1.2rem;
            color: ${fontColors.colorMain};
            font-weight: bold;
        }

        .check-input {
            display: flex;
            align-items: center;
            width: 100%;
            

            .check-value {
                margin-left: 1rem;
                width:100%;
                font-size: ${fontSizes.fontSize2};
            }

            .check {
                width: 2rem;
                display: flex;
            }
        }

        .check-text-onboarding {
            margin-left: 0.5rem;
            padding: 0.5rem 1rem;
            font-weight: bold;
            margin-bottom: 0.8rem;
            line-height: 1.2rem;
            color: ${fontColors.colorPrimary};
            font-size: ${fontSizes.fontSize3};
            box-sizing: border-box;
        }

        .check-text-default {
            padding: 0.5rem 1rem;
            border-radius: 10px;
            border: 2px solid #f8f7fc;
            width: 100%;
            font-size: ${fontSizes.fontSize2};
            box-sizing: border-box;
        }
    }
`;