import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const AddQuestionModalStyled = styled.div`

    display: flex;
    flex-direction: column;
    padding-left: 1rem;

    .question-tag {
        font-weight: ${fontWeights.fontWeightBold}
    }

    .center-tabs {
        display: flex;
        justify-content: center;
    }

    .page-counter-questions {
        box-sizing: border-box;
        width: 100%;
        font-weight: ${fontWeights.fontWeightBold};
        text-align: left;
        font-size: ${fontSizes.fontSize3};
        line-height: 1.2rem;
        color: ${fontColors.colorMain};
        margin-bottom: 1rem;
        padding-left: 1rem;
    }
    
`