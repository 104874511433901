import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const RadioSelectComponentStyled = styled.div`

    display: flex;
    flex-direction: column;

    margin-left: 1rem;

    .question-tag {
        font-weight: ${fontWeights.fontWeightBold}
    }
    
`