import styled from "styled-components";
import { fontColors, fontSizes, fontWeights } from "../../theme/variables";

export const FormMultiSelectStyled = styled.div`
    display: flex;
    flex-direction: column;

    margin-left: 1rem;
    margin-bottom: 0.5rem;

    .question-tag {
        font-weight: ${fontWeights.fontWeightBold};
        margin-bottom: 0.5rem;
    }
`