import { observer } from "mobx-react-lite";
import { EditPricePlansPanelStyled } from "./edit-price-plans-panel.component.styled";
import { SubscriptionPlansDto } from "../../modules/main/pages/price-plans/price-plans.page";
import { useEffect, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { isEmpty } from "lodash";
import FormTextInputComponent from "../form-text-input/form-text-input.component";
import ButtonComponent from "../button/button.component";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import FormSingleSelectComponent from "../form-single-select/form-single-select.component";
import toast from "react-hot-toast";
import { updatePricePlans } from "../../utils/requests";

type EditPricePlansPanelPropsType = {
    row: SubscriptionPlansDto
}

const EditPricePlansPanel = observer(({
    row
}: EditPricePlansPanelPropsType) => {

    const globalStore = useStore("globalStore");
    const uiStore = useStore('uiStore');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<any>({
        fields: {
            candidatesNumber: {
                value: row.candidatesNumber === null ? "unlimited" : row.candidatesNumber?.toString(),
                isValid: true,
                noValidation: false
            },
            searchFunctionality: {
                value: row.searchFunctionality,
                isValid: true,
                noValidation: false
            },
            freeReviewsNumber: {
                value: row.freeReviewsNumber === null ? "unlimited" : row.freeReviewsNumber?.toString(),
                isValid: true,
                noValidation: false
            },
            jobsNumber: {
                value: row.jobsNumber === null ? "unlimited" : row.jobsNumber?.toString(),
                isValid: true,
                noValidation: false
            },
        },
        shouldDisplayError: false
    })

    const CandidatesNumberOptions: any[] = [
        {
            label: "Unlimited",
            value: "unlimited"
        },
        {
            label: "0",
            value: "0"
        },
        {
            label: "1",
            value: "1"
        },
        {
            label: "2",
            value: "2"
        },
        {
            label: "3",
            value: "3"
        },
        {
            label: "4",
            value: "4"
        },
        {
            label: "5",
            value: "5"
        },
        {
            label: "6",
            value: "6"
        },
        {
            label: "7",
            value: "7"
        },
        {
            label: "8",
            value: "8"
        },
        {
            label: "9",
            value: "9"
        },
        {
            label: "10",
            value: "10"
        },
        {
            label: "11",
            value: "11"
        },
        {
            label: "12",
            value: "12"
        },
        {
            label: "13",
            value: "13"
        },
        {
            label: "14",
            value: "14"
        },
        {
            label: "15",
            value: "15"
        },
        {
            label: "16",
            value: "16"
        },
        {
            label: "17",
            value: "17"
        },
        {
            label: "18",
            value: "18"
        },
        {
            label: "19",
            value: "19"
        },
        {
            label: "20",
            value: "20"
        }
    ]

    const SearchFunctionalityOptions: any[] = [
        {
            label: "False",
            value: false
        },
        {
            label: "True",
            value: true
        },
    ]

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const editPricePlanAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            toast.error("The details are invalid!")
            return;
        };

        
        let body = {
            pricePlanId: row.id,
            candidateNumber: formGroup.fields.candidatesNumber?.value === undefined ? "unlimited" : formGroup.fields.candidatesNumber?.value,
            freeReviewsNumber: formGroup.fields.freeReviewsNumber?.value === undefined ? "unlimited" : formGroup.fields.freeReviewsNumber?.value,
            jobsNumber: formGroup.fields.jobsNumber?.value === undefined ? "unlimited" : formGroup.fields.jobsNumber?.value,
            searchFunctionality: formGroup.fields.searchFunctionality?.value,
        }

        try {
            await updatePricePlans(body);
            setIsSaving(() => false);
            toast.success("The details were saved!")
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message])
        }
    }

    useEffect(
        () => {
        },
        [formGroup.fields]
    )

    return (
        <EditPricePlansPanelStyled>
            <FormSingleSelectComponent 
                 options={CandidatesNumberOptions}
                 value={{
                    label: CandidatesNumberOptions.find(f => f.value === formGroup.fields.candidatesNumber.value).label,
                    value: formGroup.fields.candidatesNumber.value
                }}
                onChangeValue={(value: any) => updateState("candidatesNumber", value.value)}
                questionPlaceholder="general.edit-price-plans.customersNumber"
                questionTag="general.edit-price-plans.customersNumber"
                error={formGroup?.shouldDisplayError && !formGroup.fields.candidatesNumber?.isValid} 
            />
            <FormSingleSelectComponent 
                 options={CandidatesNumberOptions}
                 value={{
                    label: CandidatesNumberOptions.find(f => f.value === formGroup.fields.jobsNumber.value).label,
                    value: formGroup.fields.jobsNumber.value
                }}
                onChangeValue={(value: any) => updateState("jobsNumber", value.value)}
                questionPlaceholder="general.edit-price-plans.jobsNumber"
                questionTag="general.edit-price-plans.jobsNumber"
                error={formGroup?.shouldDisplayError && !formGroup.fields.jobsNumber?.isValid} 
            />
            <FormSingleSelectComponent 
                 options={SearchFunctionalityOptions}
                 value={{
                    label: SearchFunctionalityOptions.find(f => f.value === formGroup.fields.searchFunctionality.value).label,
                    value: formGroup.fields.searchFunctionality.value
                }}
                onChangeValue={(value: any) => updateState("searchFunctionality", value.value)}
                questionPlaceholder="general.edit-price-plans.searchFunctionality"
                questionTag="general.edit-price-plans.searchFunctionality"
                error={formGroup?.shouldDisplayError && !formGroup.fields.searchFunctionality?.isValid} 
            />
            <FormSingleSelectComponent 
                 options={CandidatesNumberOptions.filter((f: any) => f.value != null)}
                 value={{
                    label: formGroup.fields.freeReviewsNumber.value,
                    value: formGroup.fields.freeReviewsNumber.value
                }}
                onChangeValue={(value: any) => updateState("freeReviewsNumber", value.value)}
                questionPlaceholder="general.edit-price-plans.freeReviews"
                questionTag="general.edit-price-plans.freeReviews"
                error={formGroup?.shouldDisplayError && !formGroup.fields.freeReviewsNumber?.isValid} 
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <ButtonComponent 
                    onClick={editPricePlanAction} 
                    isLoading={isSaving}
                    sx={{
                        width: "5rem"
                    }}
                >
                    Save
                </ButtonComponent>
            </div>
        </EditPricePlansPanelStyled>
    )
})

export default EditPricePlansPanel;