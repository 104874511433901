import { deleteRequest, formDataPostRequest, getRequest, postRequest, putRequest } from "./http-requests"
import moment from "moment";
import { ContentTypeEnum } from "../enums/content-type.enum";
import { DictionaryTypeEnum } from "../enums/dictionary-type-enum";
import { UserTypeEnum } from "../enums/user-type.enum";
import { StepDto } from "../types/question";
import toast from "react-hot-toast";
import axios from "axios";
import envVars from "../config/env.config";
import { StorageEnum } from "../enums/storage.enum";
import { CreateJobDto } from "../types/job";
import { PlanTypeEnum } from "../enums/plan-type.enum";

export type BaseDto<T> = {
    id: T;
};

export type DictionaryDto = {
    name: string;
    tag?: string;
    isActive?: boolean;
    isDefault?: boolean;
} & BaseDto<number>;

export type PhoneNumberDto = {
    prefixCode: string;
    content: string;
    prefixId: number;
}

export type TemplateQuestionDto = {
    question: string;
    tag: string;
    order: number;
    isStandard: boolean;
    saveTamplate?: boolean;
    languageTag?: string
} & BaseDto<number>;

export type AddJobQuestionDto = {
    jobId?: number;
    question: string;
    saveTemplate: boolean;
    tag?: string;
    languageTag?: string;
}

export type CountyDto = DictionaryDto & { countryId: number; }

export type UpgradeOffersDto = {
    subscriptionPlanId: number;
    planType: string,
    planTypeId: number,
    jobsNumber?: number;
    candidatesNumber?: number;
    searchFunctionalityActive: boolean;
    freeReviewsNumber: number;
    interviewStorageDays: number;
    validPlanDays: number;
    payPlan: PayPlanDto;
    disable: boolean
}

export type PayPlanDto = {
    planPrice: number;
    planTypeCurrency: string;
}

export type RecruiterPerksDto = { 
    recruiterId: number,
    remainingJobs: number | null,
    remainingNumberCandidates: number | null,
    searchFunctionalityActive: boolean,
    remainingFreeReviews: number,
    expireDatePlan: Date,
    planType: PlanTypeEnum,
    eligibleCountry: boolean
    countryTag: string
}

// ----------------------------- HEROES -----------------------------------------

export const getReports = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetReports`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getReadyToExpireJobs = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetJobs`,
        queryParams: {
            ...filters,
            readyToExpire: true
        }
    })

    return data;
}

export const getRequestedReviewsJobs = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetJobs`,
        queryParams: {
            ...filters,
            requestedReview: true
        }
    })

    return data;
}

export const getReviewersWaitingJobs = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetJobs`,
        queryParams: {
            ...filters,
            waitingForReview: true
        }
    })

    return data;
}

export const getNoCandidatesJobs = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetJobs`,
        queryParams: {
            ...filters,
            noCandidates: true
        }
    })

    return data;
}

export const getAllJobs = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetJobs`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getReviewedCandidates = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetReviewedCandidates`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getRecruiters = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetUsers`,
        queryParams: {
            ...filters,
            userType: UserTypeEnum.Recruiter
        }
    })

    return data;
}

export const getCandidates = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetUsers`,
        queryParams: {
            ...filters,
            userType: UserTypeEnum.Candidate
        }
    })

    return data;
}

export const getReviewers = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetUsers`,
        queryParams: {
            ...filters,
            userType: UserTypeEnum.Reviewer
        }
    })

    return data;
}

export const getTransactions = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetTransactions`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getSubscriptionPlans = async (filters: any) => {
    const { data } = await getRequest({
        url: `/admin/GetSubscriptionPlans`,
        queryParams: {
            ...filters
        }
    })

    return data; 
}

export const getJobSummary = async (jobId: number) => {
    const { data } = await getRequest({
        url: `/admin/GetJobSummary`,
        queryParams: {
            jobId: jobId
        }
    })

    return data; 
}

export const getStepQuestions = async (orderToSkip: number = 0, isNotRecruiter?: boolean): Promise<StepDto[]> => {
    let stepQuestions: StepDto[] = [];

    const { data } = await getRequest({
        url: `/Questions/GetQuestions`,
        queryParams: {
            customerTypeId: isNotRecruiter ? UserTypeEnum.Candidate : UserTypeEnum.Recruiter
        }
    })
    // debugger;
    try {
        stepQuestions = (data as StepDto[])
        .map(stepQuestion => {
            return {
                ...stepQuestion,
                order: (stepQuestion?.order ?? 0) + orderToSkip,
                questions: stepQuestion.questions
                    .map(question => {
                        return {
                            ...question,
                            answers: question.answers.sort((answer1, answer2) => answer1.order - answer2.order)
                        }
                    })
                    .sort((question1, question2) => question1.order - question2.order)
            }
        })
        .sort((stepQuestion1, stepQuestion2) => stepQuestion1.order - stepQuestion2.order)
        
    } catch(e) {
        stepQuestions = [];
    }

    return stepQuestions;
}

export const getUserInfoDetails = async (userInfoId: number) => {

    const { data } = await getRequest({
        url: `/admin/GetUserInfoDetails`,
        queryParams: {
            userInfoId: userInfoId
        }
    })

    return data;
}

export const getJobsCandidates = async (filters: any) => {

    const { data } = await getRequest({
        url: `/admin/GetJobsCandidates`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getJobCandidateAnswer = async (JobCandidateAnswerId: any) => {

    const { data } = await getRequest({
        url: `/admin/GetJobCandidateAnswer`,
        queryParams: {
            JobCandidateAnswerId: JobCandidateAnswerId
        }
    })

    return data;
}

export const getReviewerPayment = async (filters: any) => {

    const { data } = await getRequest({
        url: `/admin/GetReviewerPayment`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

// UpdateUserInfo

export const updateUserInfo = async (body: any): Promise<void> => {
    await putRequest({
        url: `/admin/UpdateUserInfo`,
        body: body,
    });
}

export const resetPassword = async (body: any): Promise<void> => {
    await postRequest({
        url: `/admin/ResetPassword`,
        body: body,
    });
}

export const updatePricePlans = async (body: any): Promise<void> => {
    await postRequest({
        url: `/admin/UpdatePricePlans`,
        body: body,
    });
}

export const updateReviewerPyment = async (body: any): Promise<void> => {
    await postRequest({
        url: `/admin/UpdateReviewerPyment`,
        body: body,
    });
}

export const verifyReviewerUpdate = async (body: any): Promise<void> => {
    await postRequest({
        url: `/admin/VerifyReviewer`,
        body: body,
    });
}

export const getGenders = async (): Promise<DictionaryDto[]> => {
    const data = await getEnumEntitiesFromDB<DictionaryDto>(DictionaryTypeEnum.Genders);

    return data;
}

export const getLanguages = async (): Promise<DictionaryDto[]> => {
    const data = await getEnumEntitiesFromDB<DictionaryDto>(DictionaryTypeEnum.Languages);
    
    return data;
}

export async function getCounties(countryId?: number): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<CountyDto>(DictionaryTypeEnum.Counties, { idConditional: countryId });
    
    return data;
}

export async function getCountries(): Promise<DictionaryDto[]> {
    const data  = await getDictionaryEntitiesFromDB<DictionaryDto>(DictionaryTypeEnum.Countries);
    
    return data;
}

export async function getPets(): Promise<DictionaryDto[]> {
    let pets = await getEnumEntitiesFromDB<DictionaryDto>(DictionaryTypeEnum.Pets);

    return pets;
}

export async function getHobbies(): Promise<DictionaryDto[]> {
    let hobbies = await getEnumEntitiesFromDB<DictionaryDto>(DictionaryTypeEnum.Hobbies);
    
    return hobbies;
}

export async function getTemplateJobQuestions(recruiterId: number): Promise<TemplateQuestionDto[]> {

    try {
        const { data } = await getRequest({
            url: `/admin/GetTemplateQuestions`,
            queryParams: {
                recruiterId: recruiterId,
            }
        })

        return data

    } catch (error: any) {
        return [];
    }
}

export async function  saveProfilePicture(formData: any): Promise<any> {
    const token = window.localStorage.getItem(StorageEnum.Token);

    try {
        const { data } =  await axios.post(
            `${envVars.apiBaseUrl}/File/Save`, 
            formData, 
            {
              headers: { 
                "Content-Type": ContentTypeEnum.FormData,
                "Authorization": `Bearer ${token}`
              }
            }
        );

        return data;
    } catch (error) {
        return []
    }
}

export async function createJob(createJobDto: CreateJobDto): Promise<any> {
    await postRequest({
        url: "/Admin/CreateJob",
        body: createJobDto
    })
}

export async function deleteAccount(userInfoId: number) {
    await getRequest({
        url: `/admin/DeleteAccount`,
        queryParams: {
            userInfoId: userInfoId,
        }
    })
}

export async function suspendAccount(body: any) {
    await postRequest({
        url: `/admin/SuspendAccount`,
        body: body
    })
}

export async function createPaymentIntent(body: any) {

    try {
        const { data } = await postRequest({
            url: `/Recruiter/CreatePaymentIntent`,
            body: body
        })
    
        return data;
    } catch (error) {
        return {}
    }
}

export async function getUpgradeOffers(): Promise<UpgradeOffersDto[]> {

    try {
        const { data } = await getRequest({
            url: `/Recruiter/GetJobUpgradeOffers`
        })

        return data

    } catch (error: any) {
        return [];
    }
}

export async function getRecruiterPerks() {

    try {
        const { data } = await getRequest({
            url: `/Recruiter/GetRecruitersPerks`
        })

        return data

    } catch (error: any) {
        return [];
    }
}

export async function extendJob(params: any) {

    try {
        await getRequest({
            url: `/Admin/ExtendJobDeadline`,
            queryParams: params
        })

    } catch (error: any) {
        return [];
    }
}

export async function getStripePublishKey() {

    try {
        const { data } = await getRequest({
            url: `/Recruiter/GetStripePublishKey`
        })

        return data

    } catch (error: any) {
        return [];
    }
}

export async function upgradeSubscription(body: any): Promise<any> {
    try {
        await putRequest({
            url: `/Recruiter/UpgradeSubscription`,
            body: body
        })
    
        return true;
    } catch (error) {
        return false
    }
}

/** make call to db to get dictionary entities specified in the params of the function */
export async function getDictionaryEntitiesFromDB<T>(id: DictionaryTypeEnum, otherParams: any = {}): Promise<T[]> {

    const { data } = await getRequest({
        url: `/Dictionary`,
        queryParams: {
            id: id, 
            ...otherParams
        }
    })

    return data
}

/** make call to db to get enum entities specified in the params of the function */
export async function getEnumEntitiesFromDB<T>(id: DictionaryTypeEnum, otherParams: any = {}): Promise<T[]> {
    const { data } = await getRequest({
        url: `/Dictionary/GetEnum`,
        queryParams: {
            id: id, 
            ...otherParams
        }
    })

    return data
}

export const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };