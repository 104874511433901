import { observer } from "mobx-react-lite";
import { JobCandidateAnswerPanelStyled } from "./job-candidate-answer.component.styled";
import { useEffect, useState } from "react";
import { getJobCandidateAnswer } from "../../utils/requests";
import toast from "react-hot-toast";
import { LinearProgress } from "@mui/material";
import { isEmpty } from "lodash";
import { fontWeights } from "../../theme/variables";
import FormSectionComponent from "../form-section/form-section.component";
import { useStore } from "../../hooks/store.hook";
import ReadonlyInputComponent from "../readonly-input/readonly-input.component";
import { QuestionTypeEnum } from "../../enums/question-type-enum";
import StarIcon from '@mui/icons-material/Star';
import { PanelType } from "../../enums/panel-type.enum";
import UserDetailsPanelComponent from "../user-details-panel/user-details-panel.component";
import { UserTypeEnum } from "../../enums/user-type.enum";

type JobCandidateAnswerPanelComponentPropsType = {
    jobCandidateAnswerId: number,
    reviewerUserInfoId?: number;

}

type CandidateAsnwerReviewesDto = {
    reviewerUserInfoId: number;
    reviewerUserName: string;
    feedback: string;
    rating: number;
}

type GetJobCandidateAnswerDto = {
    question: string;
    candidateAnswer: string;
    answerIsVideo: boolean;
    videoThumbnail: string;
    candidateAsnwerReviewes: CandidateAsnwerReviewesDto[]
}

const JobCandidateAnswerPanelComponent = observer(({
    jobCandidateAnswerId,
    reviewerUserInfoId
}: JobCandidateAnswerPanelComponentPropsType) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [jobCandidateAnswer, setJobCandidateAnswer] = useState<GetJobCandidateAnswerDto>();
    const globalStore = useStore("globalStore");
    const uiStore = useStore('uiStore');

    useEffect(
        () => {
            setIsLoading(() => true);
            getJobCandidateAnswer(jobCandidateAnswerId)
            .then(data => {
                setJobCandidateAnswer(() => data);
                setIsLoading(() => false);
            })
            .catch((error) => {
                toast.error(globalStore.translationsText[error.message]);
                setIsLoading(() => false);
            })
        },
        [jobCandidateAnswerId]
    )

    useEffect(
        () => {
            if(!reviewerUserInfoId) return;
            if(!jobCandidateAnswer) return;

            setJobCandidateAnswer(() => ({
                ...jobCandidateAnswer,
                candidateAsnwerReviewes: jobCandidateAnswer?.candidateAsnwerReviewes.filter(f => f.reviewerUserInfoId === reviewerUserInfoId)
            }))

        },
        [reviewerUserInfoId, jobCandidateAnswer]
    )

    const openReviewerProfile = (userInfoId: number, userName: string) => {
        uiStore.openPanel({
            key: PanelType.UserDetails,
            component: <UserDetailsPanelComponent 
                userInfoId={userInfoId}
                userType={UserTypeEnum.Reviewer}
            />,
            panelWidth: '700px',
            title: `${userName} Details`,
            onDismiss: () => {
            }
        });
    }

    return (
        <JobCandidateAnswerPanelStyled>
            {
                isLoading ?
                    <LinearProgress style={{ borderRadius: "10px" }} />
                    :
                    <div>
                        <div className="page-title">
                            {jobCandidateAnswer?.question}
                        </div>
                        <div>
                        {
                            jobCandidateAnswer?.answerIsVideo ?
                                    
                                <div className="answer-wrapper"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontWeight: fontWeights.fontWeightBold,
                                            marginBottom: "1rem"
                                        }}
                                    >
                                        Double click on video for full screen!
                                    </div>
                                    <div className="video-thumbnail">
                                        <div className="video-thumbnail-inner">
                                            <video
                                                src={jobCandidateAnswer?.candidateAnswer}
                                                controls
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}
                                >
                                    <audio 
                                        src={jobCandidateAnswer?.candidateAnswer}
                                        controls
                                    />
                                </div>
                        }
                        </div>
                        <div>
                            {
                                jobCandidateAnswer?.candidateAsnwerReviewes && jobCandidateAnswer?.candidateAsnwerReviewes.length > 0 ?
                                    <div>
                                        {
                                            jobCandidateAnswer?.candidateAsnwerReviewes
                                            .map((m, i) => (
                                                <FormSectionComponent
                                                    variant="panel"
                                                    title={`${globalStore.translationsText["recruiter.home.components.rate-answer.your-reviews.review.title"]} ${i+1}`}
                                                >
                                                    <div>
                                                        <ul>
                                                            <li>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        marginLeft: "0.75rem"
                                                                    }}
                                                                >
                                                                    <span style={{ marginRight: "0.5rem"}}>Reviewer:</span>
                                                                    <span 
                                                                        onClick={() => openReviewerProfile(m.reviewerUserInfoId, m.reviewerUserName)}
                                                                        className="reviewer-style"
                                                                    >
                                                                        {m.reviewerUserName}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        marginLeft: "0.75rem"
                                                                    }}
                                                                >
                                                                    <span style={{ marginRight: "0.5rem"}}>Rating:</span>
                                                                    <span>{m.rating}</span>
                                                                    <StarIcon />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ReadonlyInputComponent
                                                            labelname="recruiter.app.components.rate-answer.label.review"
                                                            values={[m.feedback]}
                                                            type={QuestionTypeEnum.Text}
                                                        />
                                                    </div>
                                                </FormSectionComponent>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>
            }
        </JobCandidateAnswerPanelStyled>
    )
})

export default JobCandidateAnswerPanelComponent;