import { Close, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { ClickAwayListener, Container, Divider, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useStore } from '../../hooks/store.hook';
import ModalComponentStyled from './modal.component.styled';

const ModalComponent = observer(() => {

    /** inject the mobx store into the component */
    const uiStore = useStore('uiStore');

    const closeButtonClick = useCallback(
        () => {
            if (uiStore.modalOpen) {
                uiStore.dismissModal();
            }
        },
        [uiStore]
    )

    const onClickAwayHandler = useCallback(
        () => {
            if (uiStore.modalOpen) {
                uiStore.dismissModal();
            }
        },
        [uiStore]
    )

    const exitFullScreen = useCallback(
        () => {
            uiStore.updateModal({
                fullScreen: false
            })
        },
        [uiStore]
    )

    const enterFullScreen = useCallback(
        () => {
            uiStore.updateModal({
                fullScreen: true
            })
        },
        [uiStore]
    )


    /** define the return statement bellow */
    return (
        <ModalComponentStyled isOpen={uiStore.modalOpen} fullScreen={uiStore.currentModal?.fullScreen}>
            { uiStore.modalOpen ?
                <ClickAwayListener onClickAway={onClickAwayHandler} mouseEvent='onMouseDown'>
                    <div className='inner-modal-contents'>
                        <div className='modal-header'>
                            <Container maxWidth='xl'>
                                <div className='modal-header-contents'>
                                    <div className='modal-title'>
                                        { uiStore.currentModal?.title ?? '' }
                                    </div>
                                    <div className='modal-buttons'>
                                        {
                                            uiStore.currentModal?.actionItems ?
                                                <div className="props-icons">
                                                    {
                                                        uiStore.currentModal?.actionItems.map((item, index) => (
                                                            <Tooltip title={item.tooltipText} key={index}>
                                                                <IconButton
                                                                    onClick={item.onClick}
                                                                    size="small"
                                                                >
                                                                    {item.icon}                                
                                                                </IconButton>
                                                            </Tooltip>
                                                        ))
                                                    }
                                                </div> :
                                                ''
                                        }

                                        {
                                            uiStore.currentModal?.fullScreen ?
                                                <Tooltip title="Minimizeaza">
                                                    <IconButton
                                                        onClick={exitFullScreen}
                                                        size="small"
                                                        style={{marginLeft: '1.5rem', marginRight: '0.5rem'}}
                                                    >
                                                        <FullscreenExit />                                
                                                    </IconButton>
                                                </Tooltip> :
                                                <Tooltip title="Maximizeaza">
                                                    <IconButton
                                                        onClick={enterFullScreen}
                                                        size="small"
                                                        style={{marginLeft: '1.5rem', marginRight: '0.5rem'}}
                                                    >
                                                        <Fullscreen />                                
                                                    </IconButton>
                                                </Tooltip>
                                                
                                        }
                                        

                                        <IconButton
                                            onClick={closeButtonClick}
                                            size="small"
                                        >
                                            <Close />                                
                                        </IconButton>
                                    </div> 
                                </div>         
                            </Container>
                            <Divider />
                        </div>
                        <div className='modal-body'>
                            { uiStore.currentModal?.component ?? '' }
                        </div>    
                    </div>
                </ClickAwayListener>
                :
                ''
            }
            
        </ModalComponentStyled>
    )

})

export default ModalComponent;