import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { themeVariables } from '../../theme/variables'

interface CenteredTabsPropsType {
  handleChange: (event: React.SyntheticEvent, newValue: number) => void,
  tabValue: number,
  tabOptions: any[],
}

export default function CenteredTabs({handleChange, tabValue, tabOptions} : CenteredTabsPropsType) {


  return (
    <Box sx={{ 
        width: '100%', bgcolor: themeVariables.colors.lightGray,
        borderRadius: '16px 16px 0 0'
        }}
        >

        <Tabs sx={{ borderRadius: "inherit" }} className='MuiTabs-indicator' value={tabValue} onChange={handleChange}>
        {tabOptions.map( (element, key) => {
          return <Tab key={key} className='MuiButtonBase-root-MuiTab-root' label={element.label}/>
        })}
        </Tabs>

    </Box>
  );
}