import { observer } from "mobx-react-lite";
import { ReviewerPaymentPageStyled } from "./reviewer-payment.page.styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { getReviewerPayment } from "../../../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import EditReviewerPaymentsPanel from "../../../../components/edit-reviewer-payments/edit-reviewer-payments-panel.component";

type GetReviewerPaymentType = {
    reviewerPayments: ReviewerPaymentDto[];
    numberOfPages: number;
    numberOfReviewerPayments: number
}

export type ReviewerPaymentDto = {
    id: number;
    value: number;
    planTypeCurrency: string;
    planTypeCountry: string;
}

const ReviewerPaymentPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const uiStore = useStore('uiStore');
    const globalStore = useStore('globalStore');
    const [reviewerPayments, setReviewerPayments] = useState<GetReviewerPaymentType>();
    const [reviewerPaymentsLoading, setReviewerPaymentsLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 20,
        selectFilterValue: null,
        secondSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });

    useEffect(
        () => {
            if(!refreshData) return;

            setReviewerPaymentsLoading(() => true)
            getReviewerPayment({
                pageIndex:filtersTable.pageIndex + 1,
                pageSize:filtersTable.pageSize,
                country:filtersTable.selectFilterValue?.value,
                county:filtersTable.secondSelectFilterValue?.value,
                search:filtersTable.searchText
            })
            .then(response => {
                setReviewerPayments(() => response);
                setReviewerPaymentsLoading(() => false);
                setRefreshData(() => false);
            });   
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!reviewerPayments) return {
                data: [],
                headers: []
            }

            return {
                data: reviewerPayments.reviewerPayments,
                headers: [
                    {
                        id: 'id',
                        label: 'Id',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'value',
                        label: 'Value',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'planTypeCurrency',
                        label: 'Plan type currency',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'planTypeCountry',
                        label: 'Plan type country',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: reviewerPayments.numberOfReviewerPayments
            }
        },
        [reviewerPayments]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                planTypeCurrency: (row: ReviewerPaymentDto) => {
                    return (
                        <>
                            {(globalStore?.translationsText[row.planTypeCurrency] as string)?.toLocaleUpperCase()}
                        </>
                    )
                },
                planTypeCountry: (row: ReviewerPaymentDto) => {
                    return (
                        <>
                            {(globalStore?.translationsText[row.planTypeCountry] as string)?.toLocaleUpperCase() ?? "NONE"}
                        </>
                    )
                }
            }
        },
        [globalStore.translationsText]
    )

    const customTableRowAction = useCallback(
        (row: ReviewerPaymentDto) => {
            uiStore.openPanel({
                key: PanelType.EditReviewerPayments,
                component: <EditReviewerPaymentsPanel row={row} />,
                panelWidth: '700px',
                title: `Reviewer payment - ${globalStore.translationsText[row.planTypeCountry]}`,
                onDismiss: (data: any) => {
                    if(!data) return;
                        
                        if(data?.refreshData)
                            setRefreshData(() => true);
                }
            });
        },
        []
    )

    return (
        <ReviewerPaymentPageStyled>
            <PageTemplateComponent title="Reviewer payments">
                <TableComponent
                    tableKey={TableTypeEnum.ReviewerPayments} 
                    viewType="normal"
                    tableData={tableData}
                    withoutSelect
                    isLoading={reviewerPaymentsLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    withoutSearchBar
                    customRenderer={customRenderer}
                    customTableRowAction={customTableRowAction}
                />
            </PageTemplateComponent>
        </ReviewerPaymentPageStyled>
    )
})

export default ReviewerPaymentPage;