import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/store.hook";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { FromDatePickerStyled } from "./form-date-picker.component.styled";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../text-field/text-field.component";

export type FormDatePickerComponentPropsType = {
    questionTag: string,
    questionPlaceholder: string;
    value: any,
    onChangeValue: (value: any) => void,
    isRequired?: boolean,
    error?: boolean
}

const FromDatePickerComponent = observer(({
    onChangeValue,
    questionPlaceholder,
    questionTag,
    value,
    isRequired,
    error
}: FormDatePickerComponentPropsType) => {

    const globalStore = useStore("globalStore");

    return (
        <FromDatePickerStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]} {isRequired ? "*" : ""}
            </div>
            <DatePicker
                value={value}
                renderInput={(params) => 
                    <TextFieldComponent 
                        {...params} 
                        fullWidth={true}
                        variant="outlined" 
                        label={globalStore.translationsText[questionPlaceholder]}
                        error={error} 
                    />}
                onChange={newValue => onChangeValue(newValue)}
                mask="__.__.____"
            />
            
        </FromDatePickerStyled>
    )
})

export default FromDatePickerComponent;