import { isEmpty } from "lodash"
import { QuestionTypeEnum } from "../enums/question-type-enum"
import { JobAnswerDto } from "../types/job"
import { CandidateAnswerDto, QuestionDto, RawJobAnswerDto, StepDto } from "../types/question"

export const constructQuestionStepDto = async (candidateAnswers: CandidateAnswerDto[]) => {

    var stepIds = Array.from(new Set(candidateAnswers.map(m => m.questionId)))

    var stepsAnswers = stepIds.map(id => {
      var asnwersQuestion = candidateAnswers.filter(m => m.questionId == id)

      return {
        questionsId: id,
        answers: asnwersQuestion
      }
    })
    return stepsAnswers
}

export function constructJobAnswers(answers: RawJobAnswerDto, stepQuestions: StepDto[]): JobAnswerDto[] {
  var jobAnswers: JobAnswerDto[] = [];

  /** flatten job questions */
  let questions: QuestionDto[] = stepQuestions.reduce((prev: QuestionDto[], curr: StepDto) => {
    return [...prev, ...curr.questions]
  }, []);

  /** go through each answer and check the ones that match a question id.
   * For those answers, construct the corresponding job answer object
   */
  // debugger;
  Object.keys(answers).forEach((key: string) => {
    let matchingQuestion = questions.find(f => f.id.toString() === key);
    if (matchingQuestion === undefined) return;

    switch(matchingQuestion.type) {
      case QuestionTypeEnum.Checkbox:
        if (isEmpty(answers[key].value)) return;
        answers[key].value.forEach((answer: {id: number}) => {
          jobAnswers.push({
            questionId: matchingQuestion?.id ?? 0,
            answerId: answer.id
          });
        });
        break;
      case QuestionTypeEnum.Radio:
        if (isEmpty(answers[key].value)) return;
        jobAnswers.push({
          questionId: matchingQuestion.id,
          answerId: answers[key].value.id
        });
        break;
      case QuestionTypeEnum.Text:
        if (isEmpty(answers[key].value)) return;
        jobAnswers.push({
          questionId: matchingQuestion.id,
          answerId: matchingQuestion.answers[0].id,
          text: answers[key].value ?? ''
        });
        break;
      case QuestionTypeEnum.Range:
        if (isEmpty(answers[key].value)) return;
        jobAnswers.push({
          questionId: matchingQuestion.id,
          answerId: matchingQuestion.answers[0].id,
          min: answers[key].value?.lower ?? 0,
          max: answers[key].value?.upper ?? 0
        });
        break;
      case QuestionTypeEnum.InputRange:
        if (isEmpty(answers[key].value)) return;
        jobAnswers.push({
          questionId: matchingQuestion.id,
          answerId: matchingQuestion.answers[0].id,
          min: answers[key].value?.lower ?? 0,
          max: answers[key].value?.upper ?? 0
        });
        break;
      case QuestionTypeEnum.Date:
        if (!answers[key].value) return;
        jobAnswers.push({
          questionId: matchingQuestion.id,
          answerId: matchingQuestion.answers[0].id,
          text: answers[key].value ?? ''
        });
        break;
    }
  });

  return jobAnswers;

}