import { observer } from "mobx-react-lite";
import JobsCompletedReviewsPageStyled from "./jobs-completed-reviews.styled";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetJobType, GetJobsType } from "../dashboard/dashboard.page";
import { getCounties, getCountries, getReviewedCandidates } from "../../../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { LinearProgress } from "@mui/material";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import JobDetailsPanel from "../../../../components/job-details-panel/job-details-panel.component";
import { useStore } from "../../../../hooks/store.hook";
import UserDetailsPanelComponent from "../../../../components/user-details-panel/user-details-panel.component";
import { UserTypeEnum } from "../../../../enums/user-type.enum";
import JobCandidateAnswerPanelComponent from "../../../../components/job-candidate-answer/job-candidate-answer.component";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import { useDownloadExcel } from 'react-export-table-to-excel';

type UserInfoDto = {
    id: number;
    name: string;
}

export type JobsCompletedReviewsItemsType = {
    candidate: UserInfoDto;
    feedback: string;
    jobId: number;
    jobTitle: string;
    question: {
        questiondId: number;
        questionText: string;
    };
    rating: number;
    recruiter: UserInfoDto;
    reviewer: UserInfoDto;
    jobCandidateAnswerId: number;
    jobCandidateAnswerReviewId: number;
}

type JobsCompletedReviewsType = {
    numberOfPages: number;
    numberOfReviewedCandidates: number;
    reviewedCandidates: JobsCompletedReviewsItemsType[]
}

const JobsCompletedReviews = observer(() => {

    const [jobs, setJobs] = useState<JobsCompletedReviewsType>();
    const [jobsLoading, setJobsLoading] = useState<boolean>(true);
    const [initialCountry, setInitialCountry] = useState<any>();
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 20,
          selectFilterValue: null,
          secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const [countries, setCountries] = useState<any>();
    const [counties, setCounties] = useState<any>();
    const uiStore = useStore('uiStore');
    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Completed Reviews Jobs table',
        sheet: 'Completed Reviews Jobs'
    })

    useEffect(
        () => {
            async function getData() {
                setJobsLoading(() => true);
                try {
                    
                    let countries = await getCountries();
                    setCountries(() => countries);

                    // for raports
                    let initialCountry = countries.find(f => f.tag === "api.general.country3")
                    setInitialCountry(() => initialCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        selectFilterValue: {
                            label: initialCountry?.name,
                            value: initialCountry?.tag
                        }
                    }))

                    let counties = await getCounties(initialCountry?.id);
                    setCounties(() => counties);

                    setJobsLoading(() => false);
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            getData();
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsLoading(() => true)
                    let fetchJobs = await getReviewedCandidates({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        country:filtersTable.selectFilterValue?.value,
                        county:filtersTable.secondSelectFilterValue?.value,
                        search:filtersTable.searchText
                    });
                    setJobs(() => fetchJobs)
                    setJobsLoading(() => false)
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            if(!filtersTable) return;
            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) return;
            if(!filtersTable?.selectFilterValue?.value){
                setJobsLoading(() => false);
                return;
            }
            
            getData();
            
        },
        [filtersTable, initialCountry]
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setJobsLoading(() => true)
                    let newCountry = countries.find((f: { tag: string; }) => f.tag === filtersTable.selectFilterValue?.value)
                    setInitialCountry(() => newCountry)
                    setFilters((prevState: any) => ({
                        ...prevState,
                        secondSelectFilterValue: null
                    }))

                    let counties = await getCounties(newCountry?.id);
                    setCounties(() => counties);
                    setJobsLoading(() => false)
                } catch (error) {
                    setJobsLoading(() => false)
                }
            }

            if(filtersTable.selectFilterValue?.value !== initialCountry?.tag) 
                getData()
        },
        [filtersTable, initialCountry]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!jobs) return {
                data: [],
                headers: []
            };

            return {
                data: jobs.reviewedCandidates.sort((a, b) => b.jobCandidateAnswerReviewId - a.jobCandidateAnswerReviewId),
                headers: [
                   {
                       id: 'jobId',
                       label: 'Job Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'jobTitle',
                        label: 'Job Title',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'recruiter',
                        label: 'Recruiter',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'candidate',
                        label: 'Candidate',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'reviewer',
                        label: 'Reviewer',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'question',
                        label: 'Question',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'rating',
                        label: 'Rating',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'feedback',
                        label: 'Feedback',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: jobs.numberOfReviewedCandidates
            }
        },
        [jobs]
    )

    const customRenderer: CustomRendererPropsType = {
        recruiter: (row: JobsCompletedReviewsItemsType) => {
            // console.log(row)
            return (
                <>
                    <div>{row.recruiter.name}</div>
                </>
            )
        },
        candidate: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    <div>{row.candidate.name}</div>
                </>
            )
        },
        reviewer: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    <div>{row.reviewer.name}</div>
                </>
            )
        },
        question: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    {row.question.questionText}
                </>
            )
        },
        feedback: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    <TooltipComponent 
                        description={row.feedback}
                    />
                </>
            )
        }
    }

    const customRendererDownload: CustomRendererPropsType = {
        recruiter: (row: JobsCompletedReviewsItemsType) => {
            // console.log(row)
            return (
                <>
                    <div>{row.recruiter.name}</div>
                </>
            )
        },
        candidate: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    <div>{row.candidate.name}</div>
                </>
            )
        },
        reviewer: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    <div>{row.reviewer.name}</div>
                </>
            )
        },
        question: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    {row.question.questionText}
                </>
            )
        },
        feedback: (row: JobsCompletedReviewsItemsType) => {
            return (
                <>
                    {row.feedback}
                </>
            )
        }
    }

    //open job details panel
    const openJobDetails = useCallback(
        (jobId: number, jobTitle: string) => {
            uiStore.openPanel({
                key: PanelType.JobDetails,
                component: <JobDetailsPanel 
                    jobId={jobId}
                />,
                panelWidth: '700px',
                title: `Job Details - ${jobTitle}`,
                onDismiss: () => {
                }
            });
        },
        []
    )

    const customTableCellAction = {
        jobId: (row: JobsCompletedReviewsItemsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        jobTitle: (row: JobsCompletedReviewsItemsType) => {
            openJobDetails(row.jobId, row.jobTitle)
        },
        recruiter: (row: JobsCompletedReviewsItemsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.recruiter.id}
                    userType={UserTypeEnum.Recruiter}
                />,
                panelWidth: '700px',
                title: `${row.recruiter.name} Details`,
                onDismiss: () => {
                }
            });
        },
        reviewer: (row: JobsCompletedReviewsItemsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.reviewer.id}
                    userType={UserTypeEnum.Reviewer}
                />,
                panelWidth: '700px',
                title: `${row.reviewer.name} Details`,
                onDismiss: () => {
                }
            });
        },
        candidate: (row: JobsCompletedReviewsItemsType) => {
            uiStore.openPanel({
                key: PanelType.UserDetails,
                component: <UserDetailsPanelComponent 
                    userInfoId={row.candidate.id}
                    userType={UserTypeEnum.Candidate}
                />,
                panelWidth: '700px',
                title: `${row.candidate.name} Details`,
                onDismiss: () => {
                }
            });
        },
        question: (row: JobsCompletedReviewsItemsType) => {
            uiStore.openPanel({
                key: PanelType.CandidateAnswer,
                component: <JobCandidateAnswerPanelComponent 
                    jobCandidateAnswerId={row.jobCandidateAnswerId}
                    reviewerUserInfoId={row.reviewer.id}
                />,
                panelWidth: '700px',
                title: `${row.candidate.name} Answer`,
                onDismiss: () => {
                }
            });
        },
        rating: (row: JobsCompletedReviewsItemsType) => {
            uiStore.openPanel({
                key: PanelType.CandidateAnswer,
                component: <JobCandidateAnswerPanelComponent 
                    jobCandidateAnswerId={row.jobCandidateAnswerId}
                    reviewerUserInfoId={row.reviewer.id}
                />,
                panelWidth: '700px',
                title: `${row.candidate.name} Answer`,
                onDismiss: () => {
                }
            });
        },
        feedback: (row: JobsCompletedReviewsItemsType) => {
            uiStore.openPanel({
                key: PanelType.CandidateAnswer,
                component: <JobCandidateAnswerPanelComponent 
                    jobCandidateAnswerId={row.jobCandidateAnswerId}
                    reviewerUserInfoId={row.reviewer.id}
                />,
                panelWidth: '700px',
                title: `${row.candidate.name} Answer`,
                onDismiss: () => {
                }
            });
        },
    }


    return (
        <JobsCompletedReviewsPageStyled>
            <PageTemplateComponent 
                title="Completed Reviews Jobs"
                buttons={[
                    {
                        fOnClick: () => {
                            onDownload()
                        },
                        text: "Export"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.RequestedReview} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Search by job title"
                    dropdownPlaceholder="Country"
                    secondDropdownPlaceholder="County" 
                    withoutSelect
                    dropdownOptions={countries?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    secondDropdownOptions={counties?.map((status: any) => ({ label: status.name, value: status.tag }))}
                    customRenderer={customRenderer}
                    isLoading={jobsLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    customTableCellAction={customTableCellAction}
                />  
                <div
                    style={{
                        display:"none"
                    }}
                >
                    <TableComponent
                        tableKey={TableTypeEnum.RequestedReview} 
                        viewType="normal"
                        tableData={tableData}
                        searchBarPlaceholder="Search by job title"
                        dropdownPlaceholder="Country"
                        secondDropdownPlaceholder="County" 
                        withoutSelect
                        dropdownOptions={countries?.map((status: any) => ({ label: status.name, value: status.tag }))}
                        secondDropdownOptions={counties?.map((status: any) => ({ label: status.name, value: status.tag }))}
                        customRenderer={customRendererDownload}
                        isLoading={jobsLoading}
                        tableState={filtersTable}
                        setTableState={setFilters}
                        customTableCellAction={customTableCellAction}
                        tableRef={tableRef}
                    />
                </div>
            </PageTemplateComponent>
        </JobsCompletedReviewsPageStyled>
    )
})

export default JobsCompletedReviews;