import styled from "styled-components";

export const PageTemplateComponentStyled = styled.div`

    .page-template-body {
        margin-top: 1rem;
    }

    .button-wrapper {
        margin-left: 1rem;
    }
`;

export const InlineFlexStyled = styled.div`
    display: flex;
    justify-content: space-between;
`

export const ButtonFlexStyled = styled.div`
    display: flex;
    align-items: center;
`