import { observer } from "mobx-react-lite";
import { AddQuestionModalStyled } from "./add-question.modal.styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionsTypeEnum } from "../../enums/questions-type.enum";
import TabComponent from "../tab/tab.component";
import { useStore } from "../../hooks/store.hook";
import { AddJobQuestionDto, TemplateQuestionDto, getTemplateJobQuestions } from "../../utils/requests";
import toast from "react-hot-toast";
import { Checkbox, FormControlLabel, LinearProgress } from "@mui/material";
import CheckboxMultiSelectComponent from "../checkbox-multiselect/checkbox-multiselect.component";
import ButtonComponent from "../button/button.component";
import FormTextInputComponent from "../form-text-input/form-text-input.component";
import { isEmpty } from "lodash";
import { QuestionsLanguageEnum } from "../../enums/questions-language.enum";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { languages } from "../../utils/utils";

type AddQuestionModalPropsType = {
    recruiterId: number;
    questions: any[],
    setQuestions: (value: any[]) => void;
    appLanguageId: number
}

const AddQuestionModal = observer(({
    recruiterId,
    questions,
    setQuestions,
    appLanguageId
}: AddQuestionModalPropsType) => {

    const globalStore = useStore("globalStore");
    const [saveAsTemplate, setSaveAsTemplate] = useState<boolean>(false);
    const [allQuestion, setAllQuestions] = useState<TemplateQuestionDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentTab, setCurrentTab] = useState<QuestionsTypeEnum>(QuestionsTypeEnum.Predefined);
    const [selectedQuestions, setSelectedQuestions] = useState<TemplateQuestionDto[]>([]);
    const modalStore = useStore("uiStore");
    const [addQuestion, setAddQuestion] = useState<string>("")
    const [questionLanguage, setQuestionLanguage] = useState<any>()

    const tabs = useMemo(
        () => {
            return [
                { id: QuestionsTypeEnum.Predefined, label: 'recruiter.pending-jobs.components.add-question.label.predefined' },
                { id: QuestionsTypeEnum.UserDefined, label: 'recruiter.pending-jobs.components.add-question.label.own' }
              ]
        },
        []
    )

    useEffect(
        () => {
            if(!!questionLanguage) return;

            if(appLanguageId === 1)
                setQuestionLanguage(() => ({
                    label: QuestionsLanguageEnum.EN,
                    value: QuestionsLanguageEnum.EN
                }))

            if(appLanguageId === 2)
                setQuestionLanguage(() => ({
                    label: QuestionsLanguageEnum.DE,
                    value: QuestionsLanguageEnum.DE
                }))

            if(appLanguageId === 3)
                setQuestionLanguage(() => ({
                    label: QuestionsLanguageEnum.RO,
                    value: QuestionsLanguageEnum.RO
                }))

            if(appLanguageId === 4)
                setQuestionLanguage(() => ({
                    label: QuestionsLanguageEnum.NO,
                    value: QuestionsLanguageEnum.NO
                }))
        },
        [appLanguageId, questionLanguage]
    )

    const onTabChangeHandler = useCallback(
        (newTab: number) => {
            setCurrentTab(newTab)
        },
        []
    )

    useEffect(
        () => {
            if(!questionLanguage) return;

            setAllQuestions(
                (prevState) => prevState.map(m => ({
                    ...m,
                    question: globalStore.translationsText[`${m.tag.split(":")[0]}:${questionLanguage.label}`],
                    languageTag: `${m.tag.split(":")[0]}:${questionLanguage.label}`
                }))
            )

            setSelectedQuestions(
                (prevState) => prevState.map(m => ({
                    ...m,
                    question: globalStore.translationsText[`${m.tag.split(":")[0]}:${questionLanguage.label}`],
                    languageTag: `${m.tag.split(":")[0]}:${questionLanguage.label}`
                })) 
            )
        },
        [questionLanguage]
    )

    useEffect(
        () => {
        },
        [allQuestion]
    )

    const getGeneratedQuestions = async () => {
        try {
            setIsLoading(() => true)
            let response = await getTemplateJobQuestions(recruiterId);
            setAllQuestions(() => response.map(m => ({
                ...m,
                question: globalStore.translationsText[`${m.tag}:${languages.find(f => f.id === appLanguageId)?.shortName}`],
                languageTag: `${m.tag}:${languages.find(f => f.id === appLanguageId)?.shortName}`
            })));
            setIsLoading(() => false);
        } catch (error: any) {
            toast.error(globalStore.translationsText[error.message]);
            setIsLoading(() => false);
        }
        
    }

    useEffect(
        () => {
            getGeneratedQuestions();
        },
        [appLanguageId]
    )

    useEffect(
        () => {
            setSelectedQuestions(() => [
                ...questions
            ])
        },
        [questions]
    )

    const saveButtonAction = () => {
        let addQuestions: AddJobQuestionDto[] = selectedQuestions.map(sq => ({
            ...sq,
            question: sq.question,
            saveTemplate: !!sq?.saveTamplate,
            tag: sq.tag
        }))
        setQuestions([
            ...addQuestions
        ])
        modalStore.dismissModal()
    }

    useEffect(
        () => {
            if(selectedQuestions.length === 3){
                let addQuestions: AddJobQuestionDto[] = selectedQuestions.map(sq => ({
                    ...sq,
                    question: globalStore.translationsText[sq.languageTag ?? ""],
                    saveTemplate: !!sq?.saveTamplate,
                    tag: sq.tag,
                    languageTag: sq.languageTag
                }))
                setQuestions([
                    ...addQuestions
                ])
                modalStore.dismissModal()
            }
        },
        [selectedQuestions]
    )

    const addNewQuestion = () => {
        let addQuestions: AddJobQuestionDto[] = selectedQuestions.map(sq => ({
            ...sq,
            question: globalStore.translationsText[sq.languageTag ?? ""],
            saveTemplate: !!sq?.saveTamplate,
            tag: sq.tag,
            languageTag: sq.languageTag
        }))
        setQuestions([
            ...addQuestions,
            {
                id: -1,
                isStandard: false,
                order: -1,
                question: addQuestion,
                tag: addQuestion,
                saveTamplate: saveAsTemplate
            }
        ])
        setAddQuestion(() => "");
        modalStore.dismissModal()
    }

    return(
        <AddQuestionModalStyled>
            {
                isLoading ? (
                    <LinearProgress style={{ borderRadius: "10px" }} />
                )
                :
                <div>
                    <div className="center-tabs">
                        <TabComponent 
                            tabs={[
                                {
                                    headerTab: {
                                        label: globalStore.translationsText['recruiter.pending-jobs.components.add-question.label.predefined']
                                    },
                                },
                                {
                                    headerTab: {
                                        label: globalStore.translationsText['recruiter.pending-jobs.components.add-question.label.own']
                                    },
                                }
                            ]} 
                            noPanels 
                            onTabChange={onTabChangeHandler}
                            tabIndex={currentTab} 
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div className="page-counter-questions">
                            <span>{selectedQuestions.length}</span>
                            <span>{globalStore.translationsText['recruiter.choose-question.counter-questions.title']} </span>
                            <span>{'*'}</span>
                        </div>
                        <div>
                            <BasicSelect
                                placeholder={""}
                                options={Object.values(QuestionsLanguageEnum).map(m => ({
                                    label: m,
                                    value: m
                                }))}
                                selectFilterValue={questionLanguage}
                                onValueChange={e => setQuestionLanguage(() => e as any)}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            currentTab === QuestionsTypeEnum.Predefined ?
                                <div>
                                    <CheckboxMultiSelectComponent 
                                        answers={allQuestion}
                                        questionTag={``}    
                                        onChangeSelectedValues={setSelectedQuestions}
                                        selectedValues={selectedQuestions}
                                        noSelectedAll    
                                    />
                                </div>
                                :
                                <></>
                        }
                        {
                            currentTab === QuestionsTypeEnum.UserDefined ?
                                <div
                                    style={{
                                        paddingRight: "1rem"
                                    }}
                                >
                                    <FormTextInputComponent
                                        questionTag="recruiter.pending-jobs.components.add-question.label.newQuestion"  
                                        onChangeValue={(value: any) => setAddQuestion(value)}
                                        value={addQuestion}    
                                        questionPlaceholder="recruiter.pending-jobs.components.add-question.input.newQuestion"
                                        minRows={7} 
                                    />
                                    {
                                        !isEmpty(addQuestion) ?
                                            <div
                                                style={{
                                                    paddingLeft: "1rem"
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={saveAsTemplate}
                                                        onChange={(e) => setSaveAsTemplate(e.target.checked)}
                                                    />}
                                                    label={globalStore.translationsText["recruiter.pending-jobs.components.add-question.label.saveNewQuestion"]}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginTop: "1rem"
                                                    }}
                                                >
                                                    <ButtonComponent 
                                                        onClick={addNewQuestion}
                                                        color="secondary"
                                                    >
                                                        Add question
                                                    </ButtonComponent>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                :
                                <></>
                        }
                        <div
                            style={{
                                position: "absolute",
                                bottom: "1rem",
                                right: "1rem"
                            }}          
                        >
                            <ButtonComponent 
                                onClick={saveButtonAction}
                                sx={{
                                    width: "5rem"
                                }}
                            >
                                Save
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            }
        </AddQuestionModalStyled>
    )
})

export default AddQuestionModal;