import { createGlobalStyle } from "styled-components";
import { themeVariables } from "./theme/variables";

const GlobalStyle = createGlobalStyle`

    body {
        margin: 0;
        font-family: 'Poppins', sans-serif;
        color: ${themeVariables.textColors.primary};
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }

    ::-webkit-scrollbar {
        width: 19px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }

    li.MuiMenuItem-root {
        font-family: 'Poppins', sans-serif;
        font-size: ${themeVariables.fontSizes.smaller};
        font-weight: ${themeVariables.fontWeights.medium};
    }

    .MuiChip-root {
        border-radius: 10px !important;
    
        &.MuiChip-colorSuccess {
            color: ${themeVariables.semanticColors.success};
        }

        &.MuiChip-filledSuccess {
            background: ${themeVariables.semanticColors.successBackground};
        }

        &.MuiChip-colorError {
            color: ${themeVariables.semanticColors.error};
        }

        &.MuiChip-filledError {
            background: ${themeVariables.semanticColors.errorBackground};
        }

    }

    .toastr-component {
        .go2072408551 {
            background: #fff;
            font-family: 'Poppins', sans-serif;
            font-size: ${themeVariables.fontSizes.smaller};
            font-weight: ${themeVariables.fontWeights.semibold};
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 0.5rem 1.5rem;

            .go685806154 {
                border-radius: 10px;
                padding: 0.5rem;
            }
        }

        .succes-toastr {
            .go685806154 {
                background: ${themeVariables.semanticColors.successBackground};
            }
        }

        .error-toastr {
            .go685806154 {
                background: ${themeVariables.semanticColors.errorBackground};
            }
        }
    }

`;

export default GlobalStyle;