import { observer } from "mobx-react-lite";
import { UserDetailsPanelStyled } from "./user-details-panel.component.styled";
import { useEffect, useState } from "react";
import {
  DictionaryDto,
  PhoneNumberDto,
  getStepQuestions,
  getUserInfoDetails,
  verifyReviewerUpdate,
} from "../../utils/requests";
import { LinearProgress } from "@mui/material";
import { UserTypeEnum } from "../../enums/user-type.enum";
import FormSectionComponent from "../form-section/form-section.component";
import { useStore } from "../../hooks/store.hook";
import ReadonlyInputComponent from "../readonly-input/readonly-input.component";
import { QuestionTypeEnum } from "../../enums/question-type-enum";
import { isEmpty } from "lodash";
import defaultProfileImage from "../../assets/profile/default-profile.jpg";
import { JobQuestionDto } from "../../types/job";
import { formatDate, formatDateString } from "../../utils/utils";
import { AnswerDto, CandidateAnswerDto, QuestionDto, StepDto } from "../../types/question";
import { constructQuestionStepDto } from "../../utils/questions.provider";
import toast from "react-hot-toast";
import { fontWeights } from "../../theme/variables";
import ButtonComponent from "../button/button.component";

type UserDetailsPanelComponentPropsType = {
  userInfoId: number;
  userType: UserTypeEnum;
  verifyReviewer?: boolean
};

type GetUserDetailsDto = {
  userDetails: UserDetailsDto;
  recruiterDetails: RecruiterDetailsDto;
  reviewerDetails: ReviewerDetailsDto;
  candidateDetails: CandidateDetailsDto;
};

type UserDetailsDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  memberSince: Date;
  profilePicture: string;
  languageId: number;
  phoneNumber: PhoneNumberDto;
  motherLanguage: DictionaryDto;
  country: DictionaryDto;
  county: DictionaryDto;
  postalCode: string;
  streetName: string;
  birthDate: Date;
};

type RecruiterDetailsDto = {
  hasProfileCompleted: boolean;
  gender: DictionaryDto;
  recruiterContactPreferences: DictionaryDto[];
  recruiterLanguages: DictionaryDto[];
};

type ReviewerDetailsDto = {
  hasProfileCompleted: boolean;
  gender: DictionaryDto;
  yearsOfExperience: string;
  reviewerLanguages: DictionaryDto[];
  reviewerGeneralWorkExperiences: DictionaryDto[];
  reviewerSpecificWorkExperiences: DictionaryDto[];
  reviewerEducations: DictionaryDto[];
  identificationCard: string;
  personalMessage: string;
};

type CandidateDetailsDto = {
  hasProfileCompleted: boolean;
  gender: DictionaryDto;
  descriptionVideo: string;
  descriptionVideoThumbnail: string;
  candidateLanguages: DictionaryDto[];
  candidateAnswers: CandidateAnswerDto[];
};

const UserDetailsPanelComponent = observer(
  ({ userInfoId, userType, verifyReviewer }: UserDetailsPanelComponentPropsType) => {
    const [userDetails, setUserDetails] = useState<GetUserDetailsDto>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const globalStore = useStore("globalStore");
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [
      candidateWorkExperienceQuestions,
      setCandidateWorkExperienceQuestions,
    ] = useState<QuestionDto[]>([]);
    const [
      candidateJobAspirationQuestions,
      setCandidateJobAspirationQuestions,
    ] = useState<QuestionDto[]>([]);
    const [answeredQuestions, setAnsweredQuestions] = useState<
      {
        questionsId: number;
        answers: CandidateAnswerDto[];
      }[]
    >([]);
    const [questionSteps, setQuestionSteps] = useState<StepDto[]>([]);
    const uiStore = useStore('uiStore');

    useEffect(() => {
      if (userType === UserTypeEnum.Candidate) return;

      setIsLoading(() => true);
      getUserInfoDetails(userInfoId).then((response) => {
        setUserDetails(() => response);
        setIsLoading(() => false);
      });
    }, [userInfoId, userType]);

    const constructCandidateDynamicQuestions = async () => {
      try {
        setIsLoading(() => true);

        let response = await getUserInfoDetails(userInfoId);
        let answeredQuestions = await constructQuestionStepDto(
          response.candidateDetails.candidateAnswers
        );
        let questionSteps = await getStepQuestions(5, true);
        
        setUserDetails(() => response);
        setCandidateWorkExperienceQuestions(() => [
          ...questionSteps[1].questions,
          ...questionSteps[2].questions,
        ]);
        setCandidateJobAspirationQuestions(() => [
          ...questionSteps[4].questions,
          ...questionSteps[5].questions,
        ]);
        setAnsweredQuestions(() => answeredQuestions);
        setQuestionSteps(() => questionSteps);
        setIsLoading(() => false);
      } catch (error: any) {
        toast.error(globalStore.translationsText[error.message]);
      }
    };

    useEffect(() => {
      if (userType !== UserTypeEnum.Candidate) return;

      constructCandidateDynamicQuestions();
    }, [userType]);

    const getTagListFromDictionary = (list: DictionaryDto[]): string[] => {
      return list?.map((obj) => {
        return obj.name;
      });
    };

    const getQuestions = (list: JobQuestionDto[]): string[] => {
      return list.map((m) => m.question);
    };

    const getDate = (date: Date) => {
      return formatDate(date);
    };

    const getInputValues = (questionId: number, questionType?: any) => {
        //take completed answered 
        let question = answeredQuestions.find(f => f.questionsId == questionId)
        
        if(!question) return []
    
        let answersIds = question.answers.map(m => m.answerId);
        
        let answersQuestions = answeredQuestions.find(f => f.questionsId == questionId)
    
        // take answers from question uncompleteed
        let answersFromQuestionSteps = questionSteps
                                          .map(m => m.questions
                                          .find(f => f.id == answersQuestions?.questionsId))
                                          .find(f => f)
        //filter answers form answersFromQuestionSteps and the we completed with answers from answers
        let filterAnswers = answersFromQuestionSteps?.answers.filter(f => answersIds.indexOf(f.id) >= 0)
        //complete answers
        let answers: AnswerDto[] | undefined = filterAnswers?.map( m => {
          
          let answer = answersQuestions?.answers.find(f => f.answerId == m.id)
      
          return {
            id: m.id,
            order: m.order,
            max: answer ? answer.max : null,
            min: answer ? answer.min : null,
            placeholder: m.placeholder,
            placeholderTag: m.placeholderTag,
            tag: m.tag && m.tag?.length > 0 ? m.tag : m.placeholderTag,
            text: answer ? answer.text : ''
          }
        })
    
        if (!answers) return [];
    
        if (Array.isArray(answers)) {
          return answers.map(obj => {
            if(questionType == QuestionTypeEnum.Date)
              return formatDate(new Date(obj.text ?? ""));
            if(questionType == QuestionTypeEnum.Text) {
              return obj.text;
            }
            if(questionType == QuestionTypeEnum.Range){
              return `${obj.min} - ${obj.max}`
            }
            if(questionType == QuestionTypeEnum.InputRange){
              return `${obj.min} - ${obj.max}`
            }
    
            return globalStore.translationsText[(obj.tag as string)];
          })
        }
    }

    const verifyReviewerAction = async () => {
      setIsSaving(() => true);
      let body = {
        reviewerInfoId: userInfoId
    }

    try {
        await verifyReviewerUpdate(body);
        setIsSaving(() => false);
        toast.success("The reviewer was verified!")
        uiStore.dismissPanel({
            data: {
                refreshData: true
            }
        });
    } catch (error: any) {
        toast.error(globalStore.translationsText[error.message])
    }
    }

    return (
      <UserDetailsPanelStyled>
        {isLoading ? (
          <LinearProgress style={{ borderRadius: "10px" }} />
        ) : (
          <div className="profile-summary-container">
            <div className="inner-content">
              <div className="summary-content">
                {/* Generic components */}
                <FormSectionComponent
                  variant="panel"
                  title={`${globalStore.translationsText["recruiter.main.components.identity.label"]}`}
                  closedByDefault={!verifyReviewer}
                >
                  <ReadonlyInputComponent
                    labelname="recruiter.app.components.general-info-modal.label.firstName"
                    values={[userDetails?.userDetails.firstName]}
                    type={QuestionTypeEnum.Text}
                  />
                  <ReadonlyInputComponent
                    labelname="recruiter.app.components.general-info-modal.label.lastName"
                    values={[userDetails?.userDetails.lastName]}
                    type={QuestionTypeEnum.Text}
                  />
                  <ReadonlyInputComponent
                    labelname="recruiter.app.components.general-info-modal.label.email"
                    values={[userDetails?.userDetails.email]}
                    type={QuestionTypeEnum.Text}
                  />
                </FormSectionComponent>
                <FormSectionComponent
                  variant="panel"
                  title={`${globalStore.translationsText["recruiter.user-onboarding.photo.label.title"]}`}
                  closedByDefault
                >
                  <div className="video-wrapper">
                    <div className="video-thumbnail">
                      <div className="video-thumbnail-inner">
                        <img
                          src={
                            isEmpty(
                              userDetails?.userDetails.profilePicture as string
                            )
                              ? defaultProfileImage
                              : (userDetails?.userDetails
                                  .profilePicture as string)
                          }
                          alt="thumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </FormSectionComponent>
                {
                    userType === UserTypeEnum.Candidate ?
                    <>
                        {
                            !isEmpty(userDetails?.candidateDetails?.descriptionVideo) ?
                                <FormSectionComponent
                                    variant="panel"
                                    title={`${globalStore.translationsText["candidate.summary.personalvideo"]}`}
                                    closedByDefault
                                >
                                <div className="video-wrapper"
                                    style={{
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontWeight: fontWeights.fontWeightBold,
                                            marginBottom: "1rem"
                                        }}
                                    >
                                        Double click on video for full screen!
                                    </div>
                                    <div className="video-thumbnail" style={{
                                        width: "20rem",
                                        height: "15rem"
                                    }}>
                                        <div className="video-thumbnail-inner">
                                            <video
                                                src={userDetails?.candidateDetails?.descriptionVideo}
                                                controls
                                            />
                                        </div>
                                    </div>
                                </div>
                                </FormSectionComponent>
                                :
                                <></>
                        }
                    </>
                    :
                    <></>
                }
                <FormSectionComponent
                  variant="panel"
                  title={`${globalStore.translationsText["recruiter.user-onboarding.step3.label.title"]}`}
                  closedByDefault
                >
                  {userType === UserTypeEnum.Recruiter ? (
                    <ReadonlyInputComponent
                      labelname="recruiter.app.components.create-job.label.gender"
                      values={[userDetails?.recruiterDetails?.gender.name]}
                      type={QuestionTypeEnum.Text}
                    />
                  ) : (
                    <></>
                  )}
                  {userType === UserTypeEnum.Reviewer ? (
                    <ReadonlyInputComponent
                      labelname="recruiter.app.components.create-job.label.gender"
                      values={[userDetails?.reviewerDetails?.gender.name]}
                      type={QuestionTypeEnum.Text}
                    />
                  ) : (
                    <></>
                  )}
                  {userType === UserTypeEnum.Candidate ? (
                    <ReadonlyInputComponent
                      labelname="recruiter.app.components.create-job.label.gender"
                      values={[userDetails?.candidateDetails?.gender.name]}
                      type={QuestionTypeEnum.Text}
                    />
                  ) : (
                    <></>
                  )}
                  <ReadonlyInputComponent
                    labelname="recruiter.app.components.create-job.label.birthday"
                    values={[
                      getDate(
                        userDetails?.userDetails?.birthDate ?? new Date()
                      ),
                    ]}
                    type={QuestionTypeEnum.Text}
                  />
                  <ReadonlyInputComponent
                    labelname="recruiter.app.components.create-job.language.label.motherLanguage"
                    values={[userDetails?.userDetails?.motherLanguage?.name]}
                    type={QuestionTypeEnum.Radio}
                  />
                  {userType === UserTypeEnum.Recruiter ? (
                    <ReadonlyInputComponent
                      labelname="recruiter.app.components.create-job.language.label.additional"
                      values={getTagListFromDictionary(
                        userDetails?.recruiterDetails.recruiterLanguages ?? []
                      )}
                      type={QuestionTypeEnum.Radio}
                    />
                  ) : (
                    <></>
                  )}
                  {userType === UserTypeEnum.Reviewer ? (
                    <ReadonlyInputComponent
                      labelname="recruiter.app.components.create-job.language.label.additional"
                      values={getTagListFromDictionary(
                        userDetails?.reviewerDetails.reviewerLanguages ?? []
                      )}
                      type={QuestionTypeEnum.Radio}
                    />
                  ) : (
                    <></>
                  )}
                </FormSectionComponent>
                <FormSectionComponent
                  variant="panel"
                  title={`${globalStore.translationsText["recruiter.user-onboarding.step4.label.title"]}`}
                  closedByDefault
                >
                  {userType === UserTypeEnum.Recruiter ? (
                    <ReadonlyInputComponent
                      labelname="recruiter.user-onboarding.contactpref.label"
                      values={getTagListFromDictionary(
                        userDetails?.recruiterDetails
                          .recruiterContactPreferences ?? []
                      )}
                      type={QuestionTypeEnum.Radio}
                    />
                  ) : (
                    <></>
                  )}
                  <ReadonlyInputComponent
                    labelname="recruiter.main.components.summary.info.label.phone"
                    values={[
                      `${userDetails?.userDetails?.phoneNumber.prefixCode} ${userDetails?.userDetails?.phoneNumber.content}`,
                    ]}
                    type={QuestionTypeEnum.Text}
                  />
                </FormSectionComponent>
                <FormSectionComponent
                  variant="panel"
                  title={`${globalStore.translationsText["candidate.user-onboarding.location.label.title"]}`}
                  closedByDefault
                >
                  <ReadonlyInputComponent
                    labelname="candidate.user-onboarding.location.label.country"
                    values={[userDetails?.userDetails.country.tag]}
                    type={QuestionTypeEnum.Text}
                  />
                  <ReadonlyInputComponent
                    labelname="candidate.user-onboarding.location.label.county"
                    values={[userDetails?.userDetails.county.tag]}
                    type={QuestionTypeEnum.Text}
                  />
                  <ReadonlyInputComponent
                    labelname="candidate.user-onboarding.location.label.streetName"
                    values={[userDetails?.userDetails.streetName]}
                    type={QuestionTypeEnum.Text}
                  />
                  <ReadonlyInputComponent
                    labelname="candidate.user-onboarding.location.label.postal"
                    values={[userDetails?.userDetails.postalCode]}
                    type={QuestionTypeEnum.Text}
                  />
                </FormSectionComponent>
                {userType === UserTypeEnum.Reviewer ? (
                  <>
                    <FormSectionComponent
                      variant="panel"
                      title={`${globalStore.translationsText["reviewer.user-onboarding.identity.label.title"]}`}
                      closedByDefault={!verifyReviewer}
                    >
                      <span className="check-value-id">
                        {
                          globalStore.translationsText[
                            "reviewer.user-onboarding.identity.title"
                          ]
                        }
                      </span>
                      <div className="video-wrapper">
                        <div className="video-thumbnail">
                          <div className="video-thumbnail-inner">
                            <img
                              src={
                                isEmpty(
                                  userDetails?.reviewerDetails
                                    .identificationCard as string
                                )
                                  ? defaultProfileImage
                                  : (userDetails?.reviewerDetails
                                      .identificationCard as string)
                              }
                              alt="thumbnail"
                            />
                          </div>
                        </div>
                      </div>
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${globalStore.translationsText["reviewer.summary.generalWorkExperience"]}`}
                      closedByDefault
                    >
                      <ReadonlyInputComponent
                        labelname="reviewer.user-onboarding.generalWorkExperience.label"
                        values={getTagListFromDictionary(
                          userDetails?.reviewerDetails
                            .reviewerGeneralWorkExperiences ?? []
                        )}
                        type={QuestionTypeEnum.Radio}
                      />
                      <ReadonlyInputComponent
                        labelname="reviewer.user-onboarding.yearsOfExperience.label"
                        values={[
                          userDetails?.reviewerDetails.yearsOfExperience,
                        ]}
                        type={QuestionTypeEnum.Radio}
                      />
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${globalStore.translationsText["reviewer.summary.specificWorkExperience"]}`}
                      closedByDefault
                    >
                      <ReadonlyInputComponent
                        labelname="candidate.question4-2"
                        values={getTagListFromDictionary(
                          userDetails?.reviewerDetails
                            .reviewerSpecificWorkExperiences ?? []
                        )}
                        type={QuestionTypeEnum.Radio}
                      />
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${globalStore.translationsText["reviewer.summary.education"]}`}
                      closedByDefault
                    >
                      <ReadonlyInputComponent
                        labelname="reviewer.user-onboarding.education.label"
                        values={getTagListFromDictionary(
                          userDetails?.reviewerDetails.reviewerEducations ?? []
                        )}
                        type={QuestionTypeEnum.Radio}
                      />
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${globalStore.translationsText["reviewer.summary.personalMessage"]}`}
                      closedByDefault
                    >
                      <ReadonlyInputComponent
                        labelname="reviewer.summary.personalMessage"
                        values={[userDetails?.reviewerDetails.personalMessage]}
                        type={QuestionTypeEnum.Text}
                      />
                    </FormSectionComponent>
                  </>
                ) : (
                  <></>
                )}
                {userType === UserTypeEnum.Candidate ? (
                  <>
                    <FormSectionComponent
                      variant="panel"
                      title={`${
                        globalStore.translationsText[questionSteps[0]?.tag ?? ""]
                      }`}
                      closedByDefault
                    >
                        {
                            questionSteps[0].questions.map(question => {
                                if(question.questionTag &&  (getInputValues(question?.id)?.length ?? 0) > 0){
                                    return (
                                        <ReadonlyInputComponent
                                            labelname={question.questionTag}
                                            type={question.type}
                                            values={getInputValues(question.id, question.type)}
                                        />
                                    )
                                }
                            })
                        }
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${ globalStore.translationsText["candidate.summary.workexperience"]}`}
                      closedByDefault
                    >
                        {
                           candidateWorkExperienceQuestions.map(question => {
                            if(question.questionTag &&  (getInputValues(question?.id)?.length ?? 0) > 0){
                                return (
                                    <ReadonlyInputComponent
                                        labelname={question.questionTag}
                                        type={question.type}
                                        values={getInputValues(question.id, question.type)}
                                    />
                                )
                            }
                           })
                        }
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${ globalStore.translationsText[questionSteps[3].tag ?? ""]}`}
                      closedByDefault
                    >
                        {
                           questionSteps[3].questions.map(question => {
                            if(question.questionTag &&  (getInputValues(question?.id)?.length ?? 0) > 0){
                                return (
                                    <ReadonlyInputComponent
                                        labelname={question.questionTag}
                                        type={question.type}
                                        values={getInputValues(question.id, question.type)}
                                    />
                                )
                            }
                           })
                        }
                    </FormSectionComponent>
                    <FormSectionComponent
                      variant="panel"
                      title={`${ globalStore.translationsText["candidate.summary.jobaspirations"]}`}
                      closedByDefault
                    >
                        {
                           candidateJobAspirationQuestions.map(question => {
                            if(question.questionTag &&  (getInputValues(question?.id)?.length ?? 0) > 0){
                                return (
                                    <ReadonlyInputComponent
                                        labelname={question.questionTag}
                                        type={question.type}
                                        values={getInputValues(question.id, question.type)}
                                    />
                                )
                            }
                           })
                        }
                    </FormSectionComponent>
                  </>
                ) : (
                  <></>
                )}

                {
                  verifyReviewer ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "2rem"
                        }}
                    >
                        <ButtonComponent 
                            onClick={verifyReviewerAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Verify
                        </ButtonComponent>
                    </div>
                    :
                    <></>
                }
              </div>
            </div>
          </div>
        )}
      </UserDetailsPanelStyled>
    );
  }
);

export default UserDetailsPanelComponent;
