import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/store.hook";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { FormMultiSelectStyled } from "./form-multi-select.component.styled";
import { Autocomplete, TextField } from "@mui/material";

export type FormMultiSelectComponentPropsType = {
    options: any[]
    questionTag: string,
    questionPlaceholder: string;
    value: any,
    onChangeValue: (value: any[]) => void,
    isRequired: boolean,
    error?: boolean
}

const FromMultiSelectComponent = observer(({
    onChangeValue,
    questionPlaceholder,
    questionTag,
    value,
    isRequired,
    error,
    options
}: FormMultiSelectComponentPropsType) => {

    const globalStore = useStore("globalStore");

    return (
        <FormMultiSelectStyled>
            <div className="question-tag">
                {globalStore.translationsText[questionTag]} {isRequired ? "*" : ""}
            </div>
            <BasicSelect
                placeholder={globalStore.translationsText[questionPlaceholder]}
                options={options}
                selectFilterValue={value}
                onValueChange={(e: any) => onChangeValue(e)}
                error={error}
                multiple
            />
            
        </FormMultiSelectStyled>
    )
})

export default FromMultiSelectComponent;