import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Autocomplete, TextField } from '@mui/material';

interface FilterSelectPropsType {
  options: {
    label: string;
    value: any;
  }[],
  selectFilterValue: any,
  placeholder: string,
  onValueChange: (newValue: string) => void,
  customStyle?: {
    marginRight: string;
    marginBottom: string;
  },
  autcompleteStyle?: any,
  error?: boolean,
  multiple?: boolean,
  loading?: boolean,
  disabled?: boolean
}

const BasicSelect = ({
  options, 
  selectFilterValue, 
  onValueChange,
  placeholder,
  customStyle,
  autcompleteStyle,
  error,
  multiple,
  loading,
  disabled
}:FilterSelectPropsType) => {

  return (
    <Box  sx = {{
        display: "flex",
        flex: 1,
        marginRight: customStyle ? customStyle.marginRight : "0.625rem",
        marginBottom: customStyle ? customStyle.marginBottom : '0'
    }}>
      <FormControl 
        fullWidth
      >
        <Autocomplete 
            options={(options ?? []).map((option: any) => option)}
            renderInput={(params) => <TextField error={error} {...params} label={placeholder} />}
            getOptionLabel={(option) => option?.label}
            onChange={(_, value) => onValueChange(value)}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            value={selectFilterValue}
            sx={{fontFamily: 'Poppins, sans-serif !important', ...autcompleteStyle}}
            multiple={multiple}
            loading={loading}
            disabled={disabled}
        />

      </FormControl>
    </Box>
  );
}

export default BasicSelect;
