import { ChevronLeft, Close } from "@mui/icons-material";
import { Divider, IconButton, LinearProgress, Paper } from "@mui/material";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";
import { useStore } from "../../hooks/store.hook";
import { PanelActionEnum, UiStorePanelType } from "../../stores/ui.store";
import TabComponent from "../tab/tab.component";
import TooltipComponent from "../tooltip/tooltip.component";
import PanelComponentStyled from "./panel.component.styled";

export type PanelComponentPropsType = {
    dismissPanel: () => void;
}

const PanelComponent = observer(() => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');

    useEffect(
        () => {
            const titleElement = document.getElementById('panel-start');
            if (!titleElement) return;
            titleElement.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
        },
        [uiStore.openedPanels]
    )

    // const onClickAwayHandler = useCallback(
    //     () => {
    //         if (uiStore.panelExtended && !uiStore.modalOpen && !uiStore.dialogActive) {
    //             uiStore.dismissPanel({dismissAll: true});
    //         }
    //     },
    //     [uiStore]
    // )

    const closeButtonClick = useCallback(
        async() => {
            if(uiStore.orderData){
                const response = await uiStore.openDialog({
                    title: "Esti sigur ca vrei sa inchizi panelul?",
                    message: "",
                })    
                if(response.value === DialogResponseTypeEnum.Confirm){
                    uiStore.resetOrderData()
                    if (uiStore.panelExtended) {
                        uiStore.dismissPanel({dismissAll: true});
                    }
                }
            }
            else if (uiStore.panelExtended) {
                uiStore.dismissPanel({dismissAll: true});
            }
        },
        [uiStore]
    )

    const currentPanel = useMemo(
        (): UiStorePanelType | null => {
            if (isEmpty(uiStore.openedPanels)) return null;
            return uiStore.openedPanels[uiStore.openedPanels.length-1];
        },
        [uiStore.openedPanels]
    )

    const getPanelSubtitle = useCallback(
        (): string => {
            if (uiStore.openedPanels.length <= 1) return '';
            return uiStore.openedPanels.slice(0, uiStore.openedPanels.length-1).map(m => m.title).join(' / ');
        },
        [uiStore.openedPanels]
    )

    const backToPreviousPanel = useCallback(
        () => {
            uiStore.dismissPanel();
        },
        [uiStore]
    )

    const getPanelClassName = useCallback(
        (panelIndex: number) => {
            if (panelIndex < uiStore.openedPanels.length-1)
                return 'non-visible-panel';
            return uiStore.lastPanelAction === PanelActionEnum.Push ? 'pushed-panel' : 'poped-panel';
        },
        [uiStore.lastPanelAction, uiStore.openedPanels]
    )
 
    /** define the return statement bellow */
    return (
        
        <PanelComponentStyled isOpen={uiStore.panelExtended} panelWidth={currentPanel?.panelWidth} tabs={!isEmpty(currentPanel?.tabs)} subtitle={!!currentPanel?.subtitleComponent}>
            {/* <ClickAwayListener onClickAway={onClickAwayHandler} mouseEvent='onMouseDown'> */}
                <Paper className="panel-paper" elevation={2}>
                    <div className={`inner-content ${uiStore.panelExtended ? 'show-content' : 'hide-content'}`} id="panel-start">
                        <div className="panel-title">
                            <div className="panel-title-cluster">
                                {
                                    getPanelSubtitle() ?
                                        <IconButton
                                            className="prev-button"
                                            onClick={backToPreviousPanel}
                                            size="small"
                                        >
                                            <ChevronLeft />                                
                                        </IconButton> :
                                        ''
                                }

                                <div className="panel-title-inner">
                                    <div className={`subtitle ${getPanelSubtitle() ? 'with-subtitle' : 'without-subtitle'}`}>
                                        <TooltipComponent panelSubTitle={getPanelSubtitle()} />
                                    </div>
                                    <div className={`title ${getPanelSubtitle() ? 'with-subtitle' : 'without-subtitle'}`} style={{marginTop: '0.3rem'}} >
                                        <span className="title-text">{currentPanel?.title ?? ''}</span>
                                        { currentPanel?.additionalTitle ?
                                            <span className="additional-subtitle">
                                                {currentPanel?.additionalTitle}
                                            </span> :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="icons-section">
                                {
                                    currentPanel?.actionItems ?
                                        <div className="props-icons">
                                            {
                                                currentPanel.actionItems.map((item, index) => (
                                                    <div className="props-icons-item" key={index}>
                                                        {item.component}
                                                    </div>
                                                ))
                                            }
                                        </div> :
                                        ''
                                }
                                
                                <IconButton
                                    onClick={closeButtonClick}
                                    size="small"
                                >
                                    <Close />                                
                                </IconButton>
                            </div>
                            
                        </div>

                        {
                            !isEmpty(currentPanel?.tabs) ?
                                <div className="tabs-wrapper">
                                    <TabComponent
                                        noPanels
                                        onTabChange={currentPanel?.onTabChange}
                                        tabs={(currentPanel?.tabs ?? []).map(tab => ({
                                            headerTab: tab
                                        }))}
                                        tabIndex={currentPanel?.tabIndex}
                                    />
                                </div> :
                                ''
                        }
                        <Divider />
                        { (currentPanel?.isLoading && !currentPanel?.subtitleComponent) ? <LinearProgress /> : '' } 
                        {
                            currentPanel?.subtitleComponent ?
                            (
                                <>
                                    <div className="panel-subtitle">
                                        {currentPanel?.subtitleComponent?.()}
                                    </div>
                                    <Divider />
                                    { currentPanel?.isLoading ? <LinearProgress /> : '' } 
                                </>
                            ) :
                            ''
                        }
                        <div className="panel-body" id="panel-body">
                            {
                                uiStore.openedPanels.map((panel, index) => (
                                    <div key={index} className={`inner-panel ${getPanelClassName(index)}`}>
                                        {panel.component}
                                    </div>
                                ))
                            }
                        </div>
                    </div>  
                </Paper>
            {/* </ClickAwayListener>    */}
        </PanelComponentStyled>

    )

})

export default PanelComponent;