import React, { useCallback } from 'react';
import { RoleEnum } from '../../enums/role.enum';
import { getUser } from '../../utils/get-user.util';

export type AuthorizationGuardComponentPropsType = {
    children: React.ReactNode;
    roles?: RoleEnum[]
}

const AuthorizationGuardComponent = ({
    children,
    roles
}: AuthorizationGuardComponentPropsType) => {

    const hasPermissions = useCallback(
        (): boolean => {
            if (!roles) return true;

            const user = getUser();
            if (!user) return false;

            return roles.every((requiredRole) => user?.roles?.includes(requiredRole));
        },
        [roles]
    )

    /** define the render logic bellow */
    return (
        <>
            {
                hasPermissions() ?
                children :
                ''
            }
        </>
    );

}

export default AuthorizationGuardComponent;