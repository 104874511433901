/* eslint-disable no-restricted-globals */
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import ActionToastComponent from "../../components/action-toast/action-toast.component";
import AlertDialog from "../../components/dialog/dialog-component";
import ModalComponent from "../../components/modal/modal.component";
import PanelComponent from "../../components/panel/panel.component";
import SideNavigationComponent from "../../components/side-navigation/side-navigation.component";
import TopNavigationComponent from "../../components/top-navigation/top-navigation.component";
import { useStore } from "../../hooks/store.hook";
import MainModuleStyled from "./main.module.styled";
import MainRouter from "./main.router";
import { getUser } from "../../utils/get-user.util";
import { UserType } from "../../types/user.type";
import { RoleEnum } from "../../enums/role.enum";



const MainModule = observer(() => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    const [user, setUser] = useState<UserType | null>(null);
    
    const [showExitPrompt, setShowExitPrompt] = useState(false);

    const initBeforeUnLoad = useCallback(
        (showExitPrompt: any) => {
            window.onbeforeunload = (event) => {
              // Show prompt based on state
              if (showExitPrompt) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                  e.returnValue = ''
                }
                return '';
              }
            };
          },
          []
    )

    // Initialize the beforeunload event listener after the resources are loaded

    const onLoad = useCallback(
        () => {
            return initBeforeUnLoad(showExitPrompt);
        },
        [initBeforeUnLoad, showExitPrompt]
    )

    window.addEventListener("onload", onLoad) 

    useEffect( 
        () => {
            if(tableStore.updatedData?.length || uiStore.orderData)
                setShowExitPrompt(true)
            else
                setShowExitPrompt(false)
        },
        [tableStore.updatedData, uiStore.orderData]
    )

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
      }, [initBeforeUnLoad, showExitPrompt]);

    useEffect(
        () => {
            let user = getUser();
            setUser(() => user);
        },
        []
    )

    /** define the return statement bellow */
    return (
        <MainModuleStyled isExpanded={uiStore.sideNavbarExtended}>
            {
                user?.roles?.includes(RoleEnum.Admin) ?
                    <SideNavigationComponent />
                    :
                    <></>
            }
            <ModalComponent />
            <PanelComponent />
            <ActionToastComponent />
            <AlertDialog />
            
            <div className="pages-wrapper">
                <TopNavigationComponent isExpanded={uiStore.sideNavbarExtended} />
                <MainRouter />
            </div>
        </MainModuleStyled>
    )

})

export default MainModule;