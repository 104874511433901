import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ActionItemTextStyled } from './table-action-menu.component.styled';
import { Tooltip } from '@mui/material';
import { ActionItemPropsType, TableActionsPropsType } from '../table/table.component';
interface TableActionMenuPropsType {
    actionItems: ActionItemPropsType[],
    row?: any,
    tableActions: TableActionsPropsType,
}

export default function TableActionMenu({
  actionItems, 
  row, 
  tableActions, 
}: TableActionMenuPropsType) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const parsedActionItems = React.useMemo(
    (): ActionItemPropsType[] => {
      if (!actionItems) return [];
      return actionItems.filter(item => !item.canDisplay || item.canDisplay(row))
    },
    [actionItems, row]
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  return (
    <div>
      {
        parsedActionItems.length > 1 ?
        (
          <>
            <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={
              (e) => {
                handleClick(e)
              }
            }
          >
            <MoreVertIcon />
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={
                () => {
                  handleClose()
                } 
              }
              PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      right: -5,
                      top: 18,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              {parsedActionItems.map( (actionItem, index) => (
              <MenuItem 
              key={index} 
              sx={{ color: actionItem.color }} 
              onClick=
              {
                () => {
                  handleClose()
                  actionItem.fOnClick(row, tableActions)
                }
              }
              >
                  {actionItem.icon}
                  <ActionItemTextStyled> {actionItem.text} </ActionItemTextStyled>
              </MenuItem>
              ))}
            </Menu>
          </>
        ) :
        (
          <Tooltip title={parsedActionItems[0].text}>
            <IconButton
              onClick={
                () => {
                  parsedActionItems[0].fOnClick(row, tableActions)
                }
              }
            >
              { parsedActionItems[0].icon  }
            </IconButton>
          </Tooltip>  
        )
      }
     
    </div>
  );
}

