import styled from "styled-components";
import { fontSizes, fontWeights, themeVariables } from "../../../../../../theme/variables";

const UpgradeOffersComponentStyled = styled.div`

    .offer-card {
        border: 1px solid #D8D8D8;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: ${fontSizes.fontSize2};
        font-weight: ${fontWeights.fontWeightSemiBold};
        box-sizing: border-box;
        cursor: pointer;

        .offer-card-header {

            display: flex;
            justify-content: space-between;

            .plan-type {
                font-weight: ${fontWeights.fontWeightBold};
                font-size: ${fontSizes.fontSize4};
            }
        
            .plan-price {
                text-decoration: line-through;
                display: flex;
                font-weight: ${fontWeights.fontWeightBold};
                font-size: ${fontSizes.fontSize3};
                color: gray;
            }

            .free-trial {
                font-weight: ${fontWeights.fontWeightBold};
                font-size: ${fontSizes.fontSize3};
            }
        }

        .candidate-number {
    
            display: flex;
            align-items: center;

            .candidate-number-text {
                display: flex;
                line-height: 1.2;
                align-items: center;
            }
        }

        .search-candidates {

            display: flex;
            align-items: center;

            .search-candidates-text {
                display: flex;
                line-height: 1.2;
                align-items: center;
            }
        }

        .free-reviews {

            display: flex;
            align-items: center;

            .free-reviews-text {
                display: flex;
                line-height: 1.2;
                align-items: center;
            }
        }

        .interview-storage-days {

            display: flex;
            align-items: center;

            .interview-storage-days-text {
                display: flex;
                line-height: 1.2;
                align-items: center;
            }
        }

    }

    .offer-card-selected {
        border: 10px solid #09B59D;
    }

    .offers {
        width: 100%;
        margin-top: 1rem;
    }

    .icon-style {
        margin-right: 0.25rem;

        display: flex;
        align-items: center;

        .checked {
            display: flex;
            align-items: center;

            img {
                width: 2.5rem;
                height: 1.5rem;
                filter: invert(62%) sepia(75%) saturate(226%) hue-rotate(91deg) brightness(123%) contrast(93%);
            }
        }

        .marked {
            display: flex;
            align-items: center;
            img {
                width: 2.5rem;
                height: 1.5rem;
            }
        }
    }

`;

export default UpgradeOffersComponentStyled;