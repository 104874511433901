import { AxiosRequestConfig, AxiosResponse } from "axios";
import httpStatus from "http-status";
import { StorageEnum } from "../enums/storage.enum";

const responseSuccess = (response: AxiosResponse<any, any>): AxiosResponse<any, any> => {
    if (!response) throw new Error('recruiter.httpconfig.interceptor.label.answer-missing');
    if (!response.status)
        throw new Error('recruiter.httpconfig.interceptor.label.status-missing');
    
    if (response.status === httpStatus.UNAUTHORIZED) {
        window.localStorage.clear();

        window.location.href = '/auth/login';
        throw new Error('recruiter.httpconfig.interceptor.label.unauthorized');
    }

    return response;
}

const responseError = async (error: any) => {
    if (!error?.response?.data) throw new Error("recruiter.httpconfig.interceptor.label.error");

    const { data } = error.response;
    
    if (data.status === httpStatus.FORBIDDEN || data.status === httpStatus.UNAUTHORIZED) {
        window.localStorage.clear();

        if (data.status === httpStatus.FORBIDDEN && data.description) {
            window.localStorage.setItem(StorageEnum.AccountDisabledMessage, data.description);
        }
        window.location.href = '/auth/login';
    }

    const thrownError: any = new Error(data.description);
    thrownError.errorCode = data.errorCode;
    thrownError.additionalData = data.additionalData;
    throw thrownError;

}

const requestInterceptor = (request: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
    request.maxBodyLength = Infinity;
    request.maxContentLength = Infinity;

    const token = window.localStorage.getItem(StorageEnum.Token);
    if (token && request.headers) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }

    return request;
}

export const requestInterceptors = [requestInterceptor];
export const responseInterceptors = [responseSuccess, responseError];