export enum DictionaryTypeEnum {
    CustomerType = 1,
    Countries = 2,
    Counties = 3,
    Genders = 4,
    Languages = 5,
    PaymentFrequencyTypes = 6,
    DistanceTypes = 7, 
    ExperienceTypes = 8,
    JobStatusType = 9,
    ContactPreferences = 12,
    Hobbies = 16,
    Pets = 17,
    HelpWith = 18,
    ChooseCandidateStatus = 19
}